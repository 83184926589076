/* eslint-disable react-native/no-inline-styles */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, useWindowDimensions, View } from "react-native";
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { AppText, AvatarUser, CopilotStepCustom, IconCustom, TouchableOpacityCustom } from "components";
import { CONSTANTS } from "constants/constants";
import { useAuth } from "contexts/AuthContext";
import { navigate } from "navigation/RootNavigation";
import SCREEN_NAME from "navigation/ScreenName";
import { Colors, Fonts, FontTypes } from "theme";
import { isUserMod } from "utils";
import { EVENT } from "constants/Tracking";
import { BizziBotSticker01, BizziBotSticker02, BizziBotSticker03 } from "assets/images";

interface HeaderPageProps {
  translationY: Animated.SharedValue<number>;
  notificationCount?: number;
}
const HeaderPage: FC<HeaderPageProps> = ({ translationY, notificationCount }) => {
  const { user } = useAuth();
  const { t } = useTranslation("app/screens/Home/HomeScreen");
  const { top } = useSafeAreaInsets();
  const { width } = useWindowDimensions();
  const animatedStyle = useAnimatedStyle(() => {
    const opacity = interpolate(translationY.value, [0, 100, 120], [0, 0, 1], Extrapolation.CLAMP);
    const translateY = interpolate(translationY.value, [0, 60], [60, 0], Extrapolation.CLAMP);
    return {
      opacity: opacity,
      transform: [{ translateY: translateY }],
    };
  });

  const handleClickNotification = () => {
    navigate(SCREEN_NAME.NotificationScreen, { notificationCount });
  };

  const handleNavigateToProfile = () => {
    navigate(SCREEN_NAME.ProfileScreen);
  };

  const lastname = user?.fullname?.trim().split(" ").pop() ?? "";
  const backgroundStyle = useAnimatedStyle(() => {
    const opacity = interpolate(translationY.value, [0, 80, 100], [0, 0, 1], Extrapolation.CLAMP);
    return {
      backgroundColor: `rgba(6, 45, 119, ${opacity})`,
    };
  });
  const copilotPropsProfileScreen = {
    title: "walkthrough_category_title_step1",
    text: "walkthrough_category_description_step1",
    order: CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.Step1,
    image: BizziBotSticker01,
    activeStep: 1,
    totalStep: 3,
    onConfirm: () => {
      navigate(SCREEN_NAME.ProfileScreen, {
        shouldShowWalkthrough: true,
      });
    },
    confirmText: "continue",
    borderRadius: 20,
  };
  return (
    <>
      <Animated.View style={[styles.container, backgroundStyle]}>
        {/* -- this view background: when scroll pull to refresh for ios not show white background -- */}
        {/* --- end --- */}
        <View style={[styles.body, { paddingTop: top + 10, width: Platform.OS === "web" ? "auto" : width }]}>
          <View style={styles.avatarContainer}>
            <CopilotStepCustom
              {...copilotPropsProfileScreen}
              walkthroughName={CONSTANTS.WALK_THROUGH.CATEGORY_POLICY.NAME}
            >
              <CopilotStepCustom
                {...copilotPropsProfileScreen}
                walkthroughName={CONSTANTS.WALK_THROUGH.TRAVEL_POLICY.NAME}
              >
                <TouchableOpacityCustom eventName={EVENT.PROFILE.TAP_VIEW} onPress={handleNavigateToProfile}>
                  <AvatarUser borderWidth={2} size={40} url={user?.avatar} />
                </TouchableOpacityCustom>
              </CopilotStepCustom>
            </CopilotStepCustom>
            <Animated.View style={[styles.fullnameContainer, animatedStyle]}>
              <AppText style={Fonts.H200} color={Colors.white} numberOfLines={1}>
                {`${t("welcome")} ${lastname}`}
              </AppText>
              <AppText style={Fonts.BodyXSmall} color={Colors.white} numberOfLines={1}>
                {user?.company?.name}
              </AppText>
            </Animated.View>
          </View>
          <View style={styles.headerRightContainer}>
            {isUserMod(user) && (
              <CopilotStepCustom
                title={"walkthrough_overview_step2_title"}
                text={"walkthrough_overview_step2_description"}
                order={CONSTANTS.WALK_THROUGH.OVERVIEW.Step2}
                style={styles.buttonViewReportContainer}
                image={BizziBotSticker02}
                walkthroughName={CONSTANTS.WALK_THROUGH.OVERVIEW.NAME}
              >
                <TouchableOpacityCustom
                  eventName={EVENT.DASHBOARD.VIEW_ADMIN_DASHBOARD}
                  onPress={() => navigate(SCREEN_NAME.ExpenseOverviewScreen)}
                  style={styles.buttonNotification}
                >
                  <IconCustom name="finance" />
                </TouchableOpacityCustom>
              </CopilotStepCustom>
            )}
            <CopilotStepCustom
              title={"walkthrough_overview_step3_title"}
              text={"walkthrough_overview_step3_description"}
              order={CONSTANTS.WALK_THROUGH.OVERVIEW.Step3}
              image={BizziBotSticker03}
              walkthroughName={CONSTANTS.WALK_THROUGH.OVERVIEW.NAME}
            >
              <TouchableOpacityCustom
                eventName={EVENT.NOTIFICATION.TAP_VIEW_NOTIFICATION_LIST}
                style={styles.buttonNotification}
                onPress={handleClickNotification}
              >
                <IconCustom name="notifications-outline" />
                {!!notificationCount && (
                  <View style={[styles.countNotification, notificationCount < 10 && { paddingHorizontal: 5 }]}>
                    <AppText
                      style={[styles.notificationCountText, { fontSize: notificationCount > 99 ? 8 : 9 }]}
                      color={Colors.white}
                    >
                      {notificationCount > 99 ? "99+" : notificationCount}
                    </AppText>
                  </View>
                )}
              </TouchableOpacityCustom>
            </CopilotStepCustom>
          </View>
        </View>
      </Animated.View>
    </>
  );
};
export default memo(HeaderPage);
const styles = StyleSheet.create({
  container: { position: "absolute", zIndex: 99, width: "100%" },
  body: {
    paddingBottom: 10,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatarContainer: { flexDirection: "row", alignItems: "center", flex: 1 },
  fullnameContainer: {
    marginLeft: 10,
    flex: 1,
    paddingRight: 10,
  },
  buttonNotification: { flexDirection: "row", padding: 10 },
  countNotification: {
    position: "absolute",
    right: 7,
    top: 2,
    zIndex: 9,
    minWidth: 17,
    height: 17,
    paddingHorizontal: 3,
    elevation: 1,
    backgroundColor: Colors.alert50,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  notificationCountText: {
    fontFamily: FontTypes.medium,
    fontSize: 10,
  },
  buttonViewReportContainer: { borderRadius: 0, padding: 5 },
  headerRightContainer: { flexDirection: "row", alignItems: "center" },
  newFeatureTag: {
    position: "absolute",
    right: 0,
    top: 0,
  },
});
