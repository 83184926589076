import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MobileExpCompanyConfigurationsQuery, MobileExpCompanyConfigurationsQueryVariables } from "types";
import { useAuth } from "contexts/AuthContext";

const QUERY = gql`
  query MobileExpCompanyConfigurations($input: ExpCompanyConfigurationsInput!) {
    expCompanyConfigurations(input: $input) {
      groupId
      companyId
      setting
      type
    }
  }
`;
const useGetCompanyConfigurations = () => {
  const {
    groupId,
    company: { company_id: companyId },
  } = useAuth();

  const { data, ...rest } = useQuery<MobileExpCompanyConfigurationsQuery, MobileExpCompanyConfigurationsQueryVariables>(
    QUERY,
    {
      variables: {
        input: {
          groupId,
          companyId,
          types: ["expense:expense_form", "expense:batch_approval", "general:foreign_currency"],
        },
      },
      skip: !companyId,
      fetchPolicy: "cache-and-network",
    }
  );

  return {
    data: data
      ? data.expCompanyConfigurations.reduce((acc, config) => {
          acc[config.type] = config.setting;
          return acc;
        }, {})
      : {},
    rest,
  };
};

export default useGetCompanyConfigurations;
