import { AppText, IconCustom, SwitchButton, TextInputCustom, useSetRelativePosition } from "components";
import { Colors, Fonts } from "theme";
import { StyleSheet, View } from "react-native";
import React, { forwardRef, RefObject, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Control, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { ALLOWANCE_TYPE, BOOKING_TYPE, CONSTANTS } from "constants/constants";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import { countNumberOfDays, startLayoutAnimation } from "utils";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { ExpenseRequestFormValues } from "screens/ExpenseRequest/types";
import LocationInput from "screens/ExpenseRequest/components/ExpenseRequestForm/LocationInput";
import { Keys } from "constants/Keys";
import { v4 } from "uuid";
import useExpensePoliciesBookingByLocation from "screens/ExpenseRequest/hooks/useExpensePoliciesBookingByLocation";
import { useAuth } from "contexts/AuthContext";
import CalendarDatePicker from "components/InputCustom/CalendarDatePicker";
import { DatePickerMode } from "constants/DateSelectConstant";
import { useMasterData } from "contexts/MasterDataContext";

interface HotelSectionProps {
  name?: string;
  control?: Control<ExpenseRequestFormValues>;
  rules?: RegisterOptions<ExpenseRequestFormValues, "paymentInfo">;
  errors?: FieldErrors<ExpenseRequestFormValues>;
  setValue?: UseFormSetValue<ExpenseRequestFormValues>;
  getValues?: UseFormGetValues<ExpenseRequestFormValues>;
  watch?: UseFormWatch<ExpenseRequestFormValues>;
  totalAmount?: number;
  setPosition?: (key: string) => (y: number) => void;
  containerRef?: RefObject<any>;
}
const HotelSection = forwardRef<any, HotelSectionProps>((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleLoadAllowances: handleLoadPoliciesPerdiem,
  }));
  const { setValue, control, errors, watch, setPosition, getValues, containerRef } = props;
  const childRef = useRef(null);
  useSetRelativePosition({
    containerRef,
    childRef,
    setPosition: (y) => {
      setPosition("hotelBooking")(y);
      setPosition("hotelBookingDate")(y);
    },
  });

  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const hotelBooking = watch("hotelBooking");
  const {
    user: { employee_id },
  } = useAuth();
  const {
    setting: { allowPastTimeOnExpenseRequestCreation },
  } = useMasterData();

  const [onFetchPoliciesBooking] = useExpensePoliciesBookingByLocation();

  const handleLoadPoliciesPerdiem = async () => {
    const allowances = getValues("allowances")?.filter((item) => item?.type !== ALLOWANCE_TYPE.BOOKING_HOTEL);
    if (!hotelBooking?.location?.metadata?.code) {
      setValue("allowances", allowances);
      return;
    }
    const hotelBookingDate = getValues("hotelBookingDate");
    const numOfNights = hotelBookingDate?.fromDate
      ? countNumberOfDays(hotelBookingDate?.fromDate, hotelBookingDate?.toDate) - 1
      : 0;
    const rs = await onFetchPoliciesBooking({
      variables: {
        input: {
          employeeId: employee_id,
          locationCode: hotelBooking?.location?.metadata?.code,
          bookingType: BOOKING_TYPE.HOTEL,
        },
      },
    });
    // in the case hotel booking not setup perdiem --> no auto generate allowances
    if (!rs?.data?.expExpensePoliciesBookingByLocation?.expenseCategory || !getValues("hotelBooking.isOn")) {
      startLayoutAnimation();
      setValue("allowances", allowances);
      setValue("hotelBooking.location", null);
      return;
    }
    const policy = rs?.data?.expExpensePoliciesBookingByLocation;
    const allowanceItem = {
      id: v4(),
      amount: numOfNights > 0 ? policy?.amount * numOfNights : 0,
      expenseCategoryId: policy?.expenseCategory?.expenseCategoryId,
      type: ALLOWANCE_TYPE.BOOKING_HOTEL,
      expenseCategory: {
        title: policy?.expenseCategory?.title,
        titleEn: policy?.expenseCategory?.titleEn,
      },
    };
    const travelPerdiemAllowances = allowances.filter((i) => i?.type === ALLOWANCE_TYPE.PERDIEM);
    const flightBooking = allowances.find((i) => i?.type === ALLOWANCE_TYPE.BOOKING_FLIGHT);
    const transporterBooking = allowances.find((i) => i?.type === ALLOWANCE_TYPE.BOOKING_BUS);
    let insertToIndex = travelPerdiemAllowances?.length || 0;
    if (flightBooking) {
      insertToIndex += 1;
    }
    if (transporterBooking) {
      insertToIndex += 1;
    }
    // insert hotel booking perdiem after flight booking, transporter booking and travel perdiem allowances for each day if exist
    allowances.splice(insertToIndex, 0, allowanceItem);
    startLayoutAnimation();
    setValue("allowances", allowances);
  };
  const onConfirmDate = () => {
    handleLoadPoliciesPerdiem();
  };
  const onToggleHotelBooking = () => {
    const allowances = getValues("allowances");
    const isEnable = !hotelBooking?.isOn;
    startLayoutAnimation();
    if (!isEnable) {
      setValue(
        "allowances",
        allowances?.filter((item) => item?.type !== ALLOWANCE_TYPE.BOOKING_HOTEL)
      );
    } else if (!allowances.find((i) => i?.type === ALLOWANCE_TYPE.BOOKING_HOTEL)) {
      handleLoadPoliciesPerdiem();
    }
    setValue("hotelBooking.isOn", !hotelBooking?.isOn);
  };
  const handleSetLocation = (key, value, config) => {
    setValue(key, value, config);
    handleLoadPoliciesPerdiem();
  };
  return (
    <View style={styles.container} ref={childRef}>
      <View style={styles.titleContainer}>
        <AppText style={Fonts.SentenceSubtileXLarge}>{t("hotel")}</AppText>
        <SwitchButton onValueChange={onToggleHotelBooking} value={hotelBooking?.isOn} />
      </View>
      {hotelBooking?.isOn && (
        <>
          <View style={styles.contentContainer}>
            <LocationInput
              name={"hotelBooking.location"}
              control={control}
              label={t("location")}
              style={styles.textInput}
              setValue={handleSetLocation}
              error={errors?.hotelBooking?.location}
              rules={{ required: t("required") }}
              leftIcon={<IconCustom name="hotel" />}
              value={hotelBooking?.location}
              getValues={getValues}
              recentLocationKey={`${employee_id}${Keys.RECENT_HOTEL_LOCATION}`}
              searchDescription={t("search_location_from_description")}
              bookingType={BOOKING_TYPE.HOTEL}
              notFoundDescription={t("search_airline_not_found_description")}
            />
            <CalendarDatePicker
              format="ddd, DD/MM/YYYY"
              datePickerModalProps={{
                validRange: allowPastTimeOnExpenseRequestCreation ? undefined : { startDate: new Date() },
              }}
              style={{ marginTop: 10, marginBottom: 8 }}
              mode={DatePickerMode.RANGE}
              label={t("day_of_stay")}
              name="hotelBookingDate"
              control={control}
              rules={{ required: t("required") }}
              error={errors?.hotelBookingDate}
              onConfirm={onConfirmDate}
            />
            <TextInputCustom
              style={[styles.textInput, { marginTop: 2 }]}
              multiline
              autoHeight
              name="hotelBooking.note"
              control={control}
              label={t("bus_hotel_note")}
              placeholder={t("bus_hotel_note")}
            />
          </View>
        </>
      )}
    </View>
  );
});
export default HotelSection;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 16,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentContainer: { overflow: "hidden", marginTop: 10 },
  textInput: {
    marginVertical: 8,
  },
});
