import dayjs from "dayjs";
import { FC, memo } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import ExpenseReportItemIcon from "assets/images/svg/icons/ExpenseReportItemIcon";
import { AllocatedStatus, ApprovalStage, AppText, CurrencyText, IconCustom, TouchableOpacityCustom } from "components";
import { ALLOCATED_STATUS, CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
import ReportStatus from "./ReportStatus";
import { MobileExpenseReportQuery } from "types";
import { useTranslation } from "react-i18next";

interface ReportItemProps {
  item: {
    allocatedStatus?: ALLOCATED_STATUS;
    title: string;
    docSequence: string;
    status: number;
    expenseReportId: string;
    createdAt: string;
    expenses: Array<{ expenseId: string; totalAmountWithVat: number; allocatedStatus?: ALLOCATED_STATUS }>;
    companyEmployee?: {
      fullname: string;
    };
    expenseApprovalRules?: MobileExpenseReportQuery["expExpenseReports"]["reports"][0]["expenseApprovalRules"];
  };
  showCreator?: boolean;
  onPress?: (event: { target: "view-detail" | "container"; value: string }) => void;
  eventName?: string;
  showApprovalFlow?: boolean;
  isRejected?: boolean;
  mode?: ReportItemDisplayMode;
  selected?: boolean;
  isLast?: boolean;
}

export enum ReportItemDisplayMode {
  View = "view",
  Selectable = "selectable",
}

const ReportItem: FC<ReportItemProps> = ({
  isLast,
  eventName,
  item,
  showCreator,
  onPress,
  showApprovalFlow,
  isRejected,
  mode = ReportItemDisplayMode.View,
  selected = false,
}) => {
  const { i18n } = useTranslation();
  const { title, expenses, createdAt, status, allocatedStatus } = item;
  const totalAmountWithVat = expenses
    .map((v) => v.totalAmountWithVat)
    .reduce((total: number, value: number) => total + value, 0);
  const approvers = item?.expenseApprovalRules?.[0]?.expenseApprovalRuleEmployees?.map((item) => item?.employee?.user);

  const LeftIcon =
    mode === ReportItemDisplayMode.View ? (
      <ExpenseReportItemIcon />
    ) : (
      <View style={styles.leftIcon}>
        {selected ? <IconCustom name="selected-category" /> : <ExpenseReportItemIcon />}
      </View>
    );

  const Footer =
    mode === ReportItemDisplayMode.View ? (
      <View style={styles.rowItem}>
        <View style={styles.statusContainer}>
          <ReportStatus value={status} />
          <AllocatedStatus value={allocatedStatus} />
        </View>
        {showApprovalFlow && <ApprovalStage users={approvers} limit={3} type={isRejected ? "danger" : "primary"} />}
        {showCreator && (
          <AppText
            style={[Fonts.BodySmall, styles.allSpaceLeft, styles.textRight]}
            color={Colors.grayscale60}
            numberOfLines={1}
          >
            {item.companyEmployee?.fullname ?? ""}
          </AppText>
        )}
      </View>
    ) : (
      <View style={styles.rowItem}>
        <AppText style={[Fonts.BodySmall, styles.allSpaceLeft]} color={Colors.grayscale60} numberOfLines={1}>
          {item.companyEmployee?.fullname ?? ""}
        </AppText>
        <TouchableOpacity
          style={styles.gotoDetail}
          onPress={() => onPress({ target: "view-detail", value: item.expenseReportId })}
        >
          <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
            {i18n.t("common:view_detail").toString()}
          </AppText>
          <IconCustom name="arrow-right-alt" />
        </TouchableOpacity>
      </View>
    );

  return (
    <>
      <TouchableOpacityCustom
        eventName={eventName}
        style={styles.container}
        onPress={() => onPress({ target: "container", value: item.expenseReportId })}
      >
        <View style={styles.leftIcon}>{LeftIcon}</View>
        <View style={styles.contentContainer}>
          <View style={styles.titleContainer}>
            <AppText style={[Fonts.BodyMedium, styles.title]} numberOfLines={1}>
              {title}
            </AppText>
            <CurrencyText style={Fonts.NumericN200}>{totalAmountWithVat}</CurrencyText>
          </View>
          <View style={styles.titleContainer}>
            <AppText style={Fonts.BodySmall}>{item.docSequence}</AppText>
            <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>
              {dayjs(createdAt).format(CONSTANTS.FORMAT_DAY)}
            </AppText>
          </View>
          {Footer}
        </View>
      </TouchableOpacityCustom>
      {!isLast && <View style={styles.divider} />}
    </>
  );
};

export default memo(ReportItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
  },
  leftIcon: { marginRight: 16 },
  contentContainer: {
    flex: 1,
  },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale10,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING + 45,
    marginRight: -CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 2,
    gap: 6,
  },
  title: { flex: 1, paddingRight: 5 },
  allSpaceLeft: { flex: 1 },
  textRight: { textAlign: "right" },
  statusContainer: { flexDirection: "row", gap: 4, marginTop: 2 },
  rowItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 6,
  },
  gotoDetail: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
});
