import { StyleSheet } from "react-native";
import BottomSheet, { BottomSheetBackdrop } from "@gorhom/bottom-sheet";
import React, { FC, ForwardedRef, ReactElement as ReactNode, useCallback, useImperativeHandle, useRef } from "react";

import HeaderBottomSheet from "./HeaderBottomSheet";

export type BottomSheetCustomMethods = Pick<BottomSheet, "close" | "expand">;
interface BottomSheetCustomProps {
  ref: ForwardedRef<BottomSheetCustomMethods>;
  snapPoints: string[] | number[];
  title?: string;
  children?: ReactNode;
  handleClose?: () => void;
}

const BottomSheetCustom: FC<BottomSheetCustomProps> = React.forwardRef(
  ({ snapPoints, title, children, handleClose }, ref: ForwardedRef<BottomSheetCustomMethods>) => {
    useImperativeHandle(ref, () => ({
      expand: () => {
        bottomSheetRef?.current?.expand?.();
      },
      close: () => {
        bottomSheetRef?.current?.close?.();
      },
    }));

    const bottomSheetRef = useRef<BottomSheet>(null);

    const renderBackdrop = useCallback(
      (props) => (
        <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} opacity={0.3} pressBehavior="close" />
      ),
      []
    );

    const renderHeaderHandle = useCallback(
      (props) => {
        return (
          <HeaderBottomSheet
            title={title}
            onClose={() => {
              bottomSheetRef?.current?.close();
              handleClose?.();
            }}
            {...props}
          />
        );
      },
      [handleClose, title]
    );
    return (
      <BottomSheet
        handleIndicatorStyle={styles.noneDisplay}
        snapPoints={snapPoints}
        ref={bottomSheetRef}
        handleComponent={renderHeaderHandle}
        animateOnMount={true}
        index={-1}
        backdropComponent={renderBackdrop}
        style={styles.modal}
      >
        {children}
      </BottomSheet>
    );
  }
);
export default React.memo(BottomSheetCustom);

const styles = StyleSheet.create({
  noneDisplay: {
    display: "none",
  },
  modal: {
    borderWidth: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
});
