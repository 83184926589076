/* eslint-disable react-native/no-unused-styles */
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import { AppText, TouchableOpacityCustom } from "components/index";
import { ReactNode } from "react";
import { Colors, Fonts, FontTypes } from "theme";

type ButtonType = "primary" | "ghost" | "danger" | "secondary" | "dangerSecondary" | "white" | "success";
type ButtonSize = "normal" | "small";

type ButtonProps = {
  type?: ButtonType;
  size?: ButtonSize;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  onPress: (data: any) => void;
  icon?: (() => void) | ReactNode;
  children: string | JSX.Element;
  loading?: boolean;
  eventName?: string;
};

const Button = ({
  eventName,
  type = "primary",
  size = "normal",
  style,
  labelStyle,
  disabled = false,
  onPress,
  icon = null,
  children,
}: ButtonProps) => {
  return (
    <TouchableOpacityCustom
      eventName={eventName}
      onPress={onPress}
      activeOpacity={0.7}
      disabled={disabled}
      style={[
        styles.contained,
        size === "small" && styles.containedSmall,
        styles[`${type}Contained`],
        disabled && styles.disableContained,
        style as any,
      ]}
    >
      <View style={styles.textContainer}>
        {icon ? <View style={styles.icon}>{typeof icon === "function" ? icon() : icon}</View> : null}
        <AppText
          style={[
            Fonts.BodyLarge,
            size === "small" && Fonts.BodySmall,
            styles.buttonTitle,
            styles[`${type}Label`],
            labelStyle,
            disabled && styles.disableLabel,
          ]}
        >
          {children}
        </AppText>
      </View>
    </TouchableOpacityCustom>
  );

  /*return (
    <PaperButton
      {...props}
      disabled={disabled}
      mode="outlined"
      uppercase={false}
      style={StyleSheet.flatten([
        styles.contained,
        styles[`${type}Contained`],
        disabled && styles.disableContained,
        style as any,
      ])}
      labelStyle={StyleSheet.flatten([
        Fonts.BodyLarge,
        styles[`${type}Label`],
        labelStyle,
        disabled && styles.disableLabel,
      ])}
    />
  );*/
};

Button.defaultProps = {
  checked: false,
};

const styles = StyleSheet.create({
  contained: {
    height: 48,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  containedSmall: {
    height: 32,
  },
  disableContained: {
    borderColor: Colors.grayscale05,
    backgroundColor: Colors.grayscale05,
  },
  primaryContained: {
    borderColor: Colors.primary50,
    backgroundColor: Colors.primary50,
  },
  secondaryContained: {
    borderColor: Colors.primary0,
    backgroundColor: Colors.primary0,
  },
  ghostContained: {
    borderColor: Colors.grayscale05,
    backgroundColor: Colors.grayscale05,
  },
  dangerContained: {
    borderColor: Colors.alert50,
    backgroundColor: Colors.alert50,
  },
  dangerSecondaryContained: {
    borderColor: Colors.alert05,
    backgroundColor: Colors.alert05,
  },
  whiteContained: {
    borderColor: Colors.grayscale05,
    backgroundColor: Colors.grayscale05,
  },
  primaryLabel: {
    color: Colors.while,
  },
  secondaryLabel: {
    lineHeight: 28,
    color: Colors.primary50,
  },
  ghostLabel: {
    color: Colors.grayscale100,
  },
  dangerLabel: {
    color: Colors.white,
  },
  dangerSecondaryLabel: {
    color: Colors.alert50,
  },
  disableLabel: {
    color: Colors.grayscale40,
  },
  whiteLabel: {
    color: Colors.grayscale100,
  },
  successLabel: {
    color: Colors.white,
  },
  successContained: {
    backgroundColor: Colors.success50,
  },
  buttonTitle: { fontFamily: FontTypes.semiBold },
  textContainer: { flexDirection: "row", alignItems: "center" },
  icon: { marginRight: 8 },
});

export default Button;
