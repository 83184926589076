import { isEmpty } from "lodash";
import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import {
  AppText,
  BackHeader,
  Button,
  CollapsibleView,
  FloatFooter,
  IconCustom,
  ImageCustom,
  Line,
  LoadingView,
  Section,
  UploadFileView,
  useInteractionManager,
} from "components";
import TextView from "components/TextView";
import { InvoiceType, PaymentType } from "constants/ExpenseApproval";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import ReportStatus from "constants/ReportStatus";
import { useIsExpenseRequestEnabled, useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { useExpExpenseDetailsQuery } from "hooks/expense/useExpExpenseDetailsQuery";
import { default as SCREEN_NAME } from "navigation/ScreenName";
import { ExpenseDetailScreenRouteProp } from "navigation/type";
import { Colors, Fonts } from "theme";

import { COMPANIES } from "constants/Companies";
import { useAuth } from "contexts/AuthContext";
import InvoiceItem from "../components/ExpenseForm/InvoiceItem";
import { parseExpense } from "./helpers";
import TransactionSection from "./components/TransactionSection";
import { UploadFileItem } from "components/UploadFiles/types";
import { COMPANY_ATTACHMENT_TYPES, EXPENSE_REPORT_STATUS, EXPENSE_STATUS } from "constants/constants";
import CannotUpdateExpenseSection from "./components/CannotUpdateExpenseSection";
import LocationInfoSection from "./components/LocationInfoSection";
import AllocatedStatusInfo from "screens/Expense/ExpenseDetailsScreen/components/AllocatedStatusInfo";
import StatusExpense from "components/ExpenseItem/StatusExpense";
import SummaryMessage from "./components/SummaryMessage";
import StatusExpenseReport from "./components/StatusExpenseReport";
import { EVENT } from "constants/Tracking";
import { useMasterData } from "contexts/MasterDataContext";
import AllocatedFileSection from "screens/Expense/ExpenseDetailsScreen/components/AllocatedFileSection";
import { executeRouteFunction } from "utils/route";
import { isForeignCurrency } from "utils";

interface ExpenseDetailScreenProps {
  navigation: any;
  route: ExpenseDetailScreenRouteProp;
}

const VAT_RATES = {
  "-1": "no_vat",
  "-1000": "mixed_tariff",
  "0": "0%",
  "3.5": "3.5%",
  "5": "5%",
  "7": "7%",
  "8": "8%",
  "10": "10%",
};
const ExpenseDetailScreen: FC<ExpenseDetailScreenProps> = ({ navigation, route }) => {
  const { expenseId = "" } = route?.params ?? {};
  const isExpenseRequestEnabled = useIsExpenseRequestEnabled();
  const [footerHeight, setFooterHeight] = useState(0);
  const { setting } = useMasterData();

  const [
    FEATURE_TEAM_MGMT,
    FEATURE_EXPENSE_PAYMENT_INFO,
    FEATURE_OPTIONAL_CATEGORY,
    FEATURE_EXP_EXPENSE_ALLOCATION,
    FEATURE_EXP_EMPLOYEE_ALLOCATE_EXPENSE,
  ] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_TEAM_MGMT,
    FEATURE_FLAGS.FEATURE_EXPENSE_PAYMENT_INFO,
    FEATURE_FLAGS.FEATURE_OPTIONAL_CATEGORY,
    FEATURE_FLAGS.FEATURE_EXP_EXPENSE_ALLOCATION,
    FEATURE_FLAGS.FEATURE_EXP_EMPLOYEE_ALLOCATE_EXPENSE,
  ]);
  const {
    user: { employee_id: employeeId },
    company: { company_id: companyId },
  } = useAuth();

  const isTLNExpenseCompany = useMemo(
    () =>
      [
        COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
        COMPANIES.TLN_EXPENSE_COMPANY_DEV,
        COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
      ].includes(companyId),
    [companyId]
  );

  const { i18n, t } = useTranslation("app/screens/Expense/ExpenseDetailsScreen");
  const { loading, expenseData, refetch } = useExpExpenseDetailsQuery({
    variables: { expenseId },
  });
  const isOwnerExpense = expenseData?.employeeId === employeeId;

  const status: ReportStatus | undefined = expenseData?.expenseReport?.status;

  useEffect(() => {
    /*--fetch data when focus screen--*/
    const unsubscribe = navigation.addListener("focus", () => {
      refetch?.();
    });
    return unsubscribe;
  }, []);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("expense_details_header")} />,
    } as any);
  }, [navigation, status, t]);
  const isReady = useInteractionManager();

  const supportingDocuments = useMemo(() => {
    if (!expenseData?.companyAttachments) {
      return [];
    }
    return expenseData?.companyAttachments
      ?.filter((item) => item.attachmentType === COMPANY_ATTACHMENT_TYPES.SUPPORT_DOCUMENT)
      ?.map((att) => ({
        id: att.companyAttachmentId,
        uri: att.fileUrl,
        name: att.fileName,
        size: att.fileSize,
        fileType: att.mimeType,
        createdAt: att.createdAt,
      })) as unknown as UploadFileItem[];
  }, [expenseData]);

  const expenseDetailInfo = parseExpense(i18n, t, expenseData);
  const { invoiceType, invoice, invoiceImages, paymentInfo } = expenseDetailInfo;

  const handleNavigateCreateReport = () => {
    /*-- flow create report from advance request --> load all expense inside this advance request*/
    if (expenseData?.expenseRequest?.cashAdvances?.[0]) {
      navigation.navigate(SCREEN_NAME.NewReportScreen, {
        requestIds: [expenseData?.expenseRequest?.expenseRequestId],
        title: expenseDetailInfo?.note,
        companyTeam: expenseDetailInfo?.companyTeam,
        onRefreshData,
      });
      return;
    }
    navigation.navigate(SCREEN_NAME.NewReportScreen, {
      expenseIds: [expenseDetailInfo?.expenseId],
      title: expenseDetailInfo?.note,
      companyTeam: expenseDetailInfo?.companyTeam,
      onRefreshData,
    });
  };

  const onRefreshData = () => {
    executeRouteFunction(route, "onRefreshData");
  };
  const handleNavigateToReport = () => {
    if (expenseDetailInfo?.expenseReport?.status === EXPENSE_REPORT_STATUS.DRAFT) {
      navigation.navigate(SCREEN_NAME.EditReportScreen, {
        expenseReportId: expenseDetailInfo?.expenseReport?.expenseReportId,
        onRefreshData,
      });
      return;
    }
    navigation.navigate(SCREEN_NAME.ReportDetailScreen, {
      expenseReportId: expenseDetailInfo?.expenseReport?.expenseReportId,
      onRefreshData,
    });
  };
  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    setFooterHeight(height);
  };

  const renderFooterButton = () => {
    if (!isOwnerExpense) {
      return null;
    }
    /*-- flow TLN company --*/
    if (isTLNExpenseCompany) {
      const isCanEditExpense =
        [ReportStatus.CANCEL, ReportStatus.REJECTED, ReportStatus.DRAFT].includes(status) ||
        !expenseDetailInfo?.expenseReport;
      return isCanEditExpense ? (
        <FloatFooter onLayout={onLayoutFooter}>
          <Button
            eventName={EVENT.EXPENSE.TAP_UPDATE}
            type="primary"
            onPress={() => {
              navigation.navigate(SCREEN_NAME.EditExpenseScreen, { expenseId, onRefreshData });
            }}
          >
            {t("update_expense")}
          </Button>
        </FloatFooter>
      ) : null;
    }
    /*-- end --*/
    if (
      expenseDetailInfo?.expenseReport &&
      [ReportStatus.CANCEL, ReportStatus.REJECTED, ReportStatus.DRAFT].includes(status)
    ) {
      return (
        <FloatFooter style={styles.footerRow} onLayout={onLayoutFooter}>
          <Button
            eventName={EVENT.EXPENSE.TAP_UPDATE}
            style={styles.flex}
            type="secondary"
            onPress={() => {
              navigation.navigate(SCREEN_NAME.EditExpenseScreen, { expenseId, onRefreshData });
            }}
          >
            {t("update_expense")}
          </Button>
          <Button eventName={EVENT.REPORT.TAP_VIEW_DETAIL} style={styles.flex} onPress={handleNavigateToReport}>
            {t("view_report")}
          </Button>
        </FloatFooter>
      );
    } else if (!expenseDetailInfo?.expenseReport) {
      const isCanCreateReport = expenseData?.status === EXPENSE_STATUS.READY;
      return (
        <FloatFooter style={styles.footerRow} onLayout={onLayoutFooter}>
          <Button
            eventName={EVENT.EXPENSE.TAP_UPDATE}
            style={styles.flex}
            type={isCanCreateReport ? "secondary" : "primary"}
            onPress={() => {
              navigation.navigate(SCREEN_NAME.EditExpenseScreen, { expenseId, onRefreshData });
            }}
          >
            {t("edit")}
          </Button>
          {isCanCreateReport && (
            <Button eventName={EVENT.REPORT.TAP_CREATE} style={styles.flex} onPress={handleNavigateCreateReport}>
              {t("create_report")}
            </Button>
          )}
        </FloatFooter>
      );
    }
    return null;
  };
  const handleNavigateToRequest = () => {
    navigation.navigate(SCREEN_NAME.DetailRequestScreen, { expenseRequestId: expenseDetailInfo?.expenseRequestId });
  };

  const renderInvoiceSection = () => {
    const title = {
      [InvoiceType.NO_INVOICE]: t("invoice_info"),
      [InvoiceType.ELECTRONIC]: t("e_invoice_full"),
      [InvoiceType.PAPER]: `${t("paper_invoice")}${invoiceImages?.length ? ` (${invoiceImages.length})` : ""}`,
    }[invoiceType];

    const getContent = () => {
      switch (invoiceType) {
        case InvoiceType.ELECTRONIC:
          if (isEmpty(invoice)) {
            return (
              <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale70}>
                {`(${t("need_to_attach_invoice")})`}
              </AppText>
            );
          }
          return (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(SCREEN_NAME.InvoiceDetailScreen, {
                  invoiceId: invoice.invoiceId,
                  autofill: true,
                  forceHideButton: true,
                });
              }}
            >
              <InvoiceItem item={invoice as any} expenseView={true} />
            </TouchableOpacity>
          );
        case InvoiceType.PAPER:
          if (!invoiceImages?.length) {
            return (
              <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale70}>
                {`(${t("need_to_attach_receipt")})`}
              </AppText>
            );
          }
          return <UploadFileView title={title} maxFile={6} files={(invoiceImages ?? []) as UploadFileItem[]} />;
        case InvoiceType.NO_INVOICE:
          if (isTLNExpenseCompany) {
            return (
              <>
                <TextView value={expenseDetailInfo.customFields?.tax_code ?? ""} label={t("tax_code")} />
                <TextView value={expenseDetailInfo.customFields?.invoice_code ?? ""} label={t("invoice_code")} />
                <TextView
                  value={
                    VAT_RATES[expenseDetailInfo.customFields?.vat_rate]
                      ? t(VAT_RATES[expenseDetailInfo.customFields?.vat_rate])
                      : t("no_vat_declaration")
                  }
                  label={t("vat_rate")}
                />
              </>
            );
          }
          return (
            <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale70}>
              {t("no_value", { label: t("invoice") })}
            </AppText>
          );
      }
    };

    return (
      <View style={styles.section}>
        <AppText style={[Fonts.SentenceSubtitleLarge, styles.titleSection]}>{title}</AppText>
        {getContent()}
      </View>
    );
  };

  const renderPaymentSection = () => {
    const paymentType =
      {
        [PaymentType.COMPANY]: `(${t("enterprise_payment")})`,
        [PaymentType.PERSONAL]: `(${t("personal_payment")})`,
      }[paymentInfo?.type] ?? "";

    const getContent = () => {
      if (!paymentInfo?.type) {
        return (
          <AppText style={[Fonts.BodyMedium, styles.textCenter]} color={Colors.grayscale70}>
            {t("no_value", { label: (t("payment_info") ?? "").toLocaleLowerCase() })}
          </AppText>
        );
      }

      return (
        <>
          <TextView
            value={paymentInfo?.description}
            label={t("payment_description")}
            emptyText={t("no_value", { label: t("payment_description").toLowerCase() })}
          />
          <View style={styles.bankCard}>
            <ImageCustom style={styles.bankCardImage} contentFit="contain" source={{ uri: paymentInfo?.logoUrl }} />
            <View style={styles.bankCardContent}>
              <AppText numberOfLines={1} style={Fonts.NumericN200}>
                {paymentInfo?.accountHolderName?.toUpperCase()}
              </AppText>
              <AppText
                numberOfLines={1}
                ellipsizeMode="head"
                style={Fonts.SentenceCaptionLarge}
                color={Colors.grayscale80}
              >
                {paymentInfo?.bankName}
              </AppText>
              <AppText numberOfLines={1} style={Fonts.SentenceCaptionLarge} color={Colors.grayscale80}>
                {paymentInfo?.accountNumber}
              </AppText>
              <AppText
                numberOfLines={1}
                ellipsizeMode="head"
                style={Fonts.SentenceCaptionLarge}
                color={Colors.grayscale80}
              >
                {paymentInfo?.branchName || t("no_value", { label: t("branch_name").toLowerCase() })}
              </AppText>
            </View>
          </View>
        </>
      );
    };

    return (
      <View style={styles.section}>
        <AppText style={[Fonts.SentenceSubtitleLarge, styles.titleSection]}>
          {t("payment_info")} {paymentType}
        </AppText>
        {expenseDetailInfo?.expenseCardTransactions?.[0] ? (
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale70}>
            {t("payment_info_no_need")}
          </AppText>
        ) : (
          getContent()
        )}
      </View>
    );
  };

  const renderAttachmentsSection = () => {
    const attachments = expenseDetailInfo?.fileAttachments ?? [];
    const title = `${t("other_papers")}${attachments.length ? ` (${attachments.length})` : ""}`;

    return (
      <View style={styles.section}>
        <AppText style={[Fonts.SentenceSubtitleLarge, styles.titleSection]}>{title}</AppText>

        {attachments.length ? (
          <UploadFileView title={title} maxFile={6} files={(attachments ?? []) as UploadFileItem[]} />
        ) : (
          <AppText style={styles.emptyText} color={Colors.grayscale70}>
            {t("no_attachments")}{" "}
          </AppText>
        )}
      </View>
    );
  };

  if (loading || !isReady) {
    return <LoadingView />;
  }
  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={[styles.contentContainer, { paddingBottom: footerHeight + 8 }]}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.header}>
          {!isOwnerExpense && expenseData?.employeeId && <CannotUpdateExpenseSection />}
          <View style={styles.section}>
            <View style={styles.expenseStatusContainer}>
              {expenseDetailInfo.expenseReport?.expenseReportId ? (
                <StatusExpenseReport value={expenseDetailInfo.expenseReport.status} />
              ) : (
                <StatusExpense value={expenseDetailInfo.status} style={styles.statusBadge} />
              )}
            </View>
            <CollapsibleView
              rotate={-180}
              rightComponentStyle={styles.rightComponentStyle}
              rightComponent={
                <View style={styles.dropDownIcon}>
                  <IconCustom name="arrow-drop-down" fill={Colors.primary50} />
                </View>
              }
              divider={<Line backgroundColor={Colors.grayscale0} />}
              summaryComponent={
                <SummaryMessage expense={expenseDetailInfo} onReportDocSequenceClick={handleNavigateToReport} />
              }
              numberOfLineTitle={2}
              title={`[${expenseDetailInfo?.docSequence}] ${expenseDetailInfo?.note}`}
              titleStyle={Fonts.SentenceSubtitleXLarge}
            >
              <View style={{ gap: 6 }}>
                {Boolean(expenseDetailInfo?.expenseReport) && (
                  <TextView
                    onPress={handleNavigateToReport}
                    hyperlink
                    value={`${expenseDetailInfo?.expenseReport?.docSequence} - ${expenseDetailInfo?.expenseReport?.title}`}
                    label={t("expense_report")}
                  />
                )}

                {setting?.isSupportCurrency && (
                  <>
                    <View>
                      <TextView
                        value={isForeignCurrency(expenseData?.currency) ? expenseData?.currency : null}
                        label={t("currency")}
                      />
                      <TextView value={expenseData?.exchangeRate} isMoney currency="" label={t("exchange_rate")} />
                    </View>
                    <TextView
                      prefix="$"
                      value={expenseData?.foreignTotalAmountWithVat}
                      isMoney
                      label={t("total_amount_with_vat_currency")}
                    />
                  </>
                )}
                <TextView value={`${expenseDetailInfo.totalAmountWithVat} ₫`} label={t("total_amount_with_vat")} />

                <TextView
                  error={
                    !FEATURE_OPTIONAL_CATEGORY && !expenseDetailInfo.expenseCategoryTitle
                      ? t("missing_value", { name: t("category").toLowerCase() })
                      : ""
                  }
                  value={expenseDetailInfo.expenseCategoryTitle}
                  label={t("category")}
                  emptyText={t("no_value", { label: t("category").toLowerCase() })}
                />
                {Boolean(expenseDetailInfo?.subAccount?.title) && (
                  <TextView value={expenseDetailInfo.subAccount.title} label={t("sub_account_category")} />
                )}
                <TextView value={expenseDetailInfo.expenseDate} label={t("created_date")} />
                {FEATURE_TEAM_MGMT && !isTLNExpenseCompany && (
                  <TextView
                    value={expenseDetailInfo.companyTeamName}
                    label={t("unit")}
                    emptyText={t("no_value", { label: t("unit").toLowerCase() })}
                  />
                )}
                {isExpenseRequestEnabled && !isTLNExpenseCompany && (
                  <TextView
                    onPress={handleNavigateToRequest}
                    hyperlink={Boolean(expenseData?.expenseRequest)}
                    value={expenseDetailInfo.expenseRequestTitle}
                    label={t("expense_request")}
                    emptyText={t("no_value", { label: t("expense_request").toLowerCase() })}
                  />
                )}
                {FEATURE_EXP_EXPENSE_ALLOCATION && expenseDetailInfo?.parent && (
                  <AllocatedStatusInfo parent={expenseDetailInfo?.parent} />
                )}
              </View>
            </CollapsibleView>
          </View>
        </View>
        {FEATURE_EXP_EMPLOYEE_ALLOCATE_EXPENSE && (
          <AllocatedFileSection companyAttachments={expenseData?.companyAttachments} />
        )}
        {renderInvoiceSection()}
        {expenseDetailInfo?.expenseCardTransactions?.[0] || setting?.isCardEnabled ? (
          <View style={styles.section}>
            <AppText style={[Fonts.SentenceSubtitleLarge, styles.titleSection]}>{t("card_transaction")}</AppText>
            <TransactionSection cardTransaction={expenseDetailInfo?.expenseCardTransactions?.[0]?.cardTransaction} />
          </View>
        ) : null}
        <LocationInfoSection expenseDetail={expenseDetailInfo} />
        {FEATURE_EXPENSE_PAYMENT_INFO && !expenseDetailInfo?.expenseCardTransactions?.[0] && renderPaymentSection()}
        {renderAttachmentsSection()}
        {Boolean(supportingDocuments?.length) && (
          <Section
            style={{ marginTop: 0 }}
            title={
              supportingDocuments?.length
                ? `${t("supporting_documents")} (${supportingDocuments.length})`
                : t("supporting_documents")
            }
          >
            <UploadFileView
              maxFile={6}
              title={
                supportingDocuments?.length
                  ? `${t("supporting_documents")} (${supportingDocuments.length})`
                  : t("supporting_documents")
              }
              files={supportingDocuments}
            />
          </Section>
        )}
      </ScrollView>
      {renderFooterButton()}
    </View>
  );
};

const styles = StyleSheet.create({
  bankCard: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    marginTop: 16,
    padding: 8,
    borderRadius: 8,
    backgroundColor: Colors.grayscale05,
  },
  bankCardImage: {
    width: 70,
    height: 70,
    borderRadius: 8,
    backgroundColor: Colors.white,
  },
  bankCardContent: {
    flex: 1,
    gap: 2,
  },
  expenseStatusContainer: {
    marginBottom: 2,
  },
  statusBadge: {
    marginBottom: 8,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
  },
  textCenter: { textAlign: "center" },
  container: {
    flex: 1,
    backgroundColor: Colors.grayscale05,
    flexDirection: "column",
  },
  header: { backgroundColor: Colors.while },
  titleSection: {
    paddingBottom: 16,
  },
  section: {
    backgroundColor: Colors.while,
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
  emptyText: {
    ...Fonts.BodyMedium,
    textAlign: "center",
  },
  contentContainer: { gap: 8 },
  footerRow: { flexDirection: "row", justifyContent: "space-between", gap: 8 },
  flex: { flex: 1 },
  dropDownIcon: { backgroundColor: Colors.primary0, borderRadius: 4 },
  rightComponentStyle: {
    marginTop: 5,
  },
});

export default ExpenseDetailScreen;
