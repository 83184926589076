import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpenseReportSearchQuery, MobileExpenseReportSearchQueryVariables } from "types";

const QUERY_REPORTS = gql`
  query MobileExpenseReportSearch(
    $where: ExpMobileExpenseReportCondition!
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
    $sortOption: ExpMobileExpenseReportsSortOptions
  ) {
    expMobileExpenseReports(where: $where, limit: $limit, offset: $offset, sortOption: $sortOption) {
      total
      reports {
        allocatedStatus
        expenseReportId
        title
        docSequence
        expenses {
          expenseId
          totalAmountWithVat
        }
        companyEmployeeId
        companyEmployee {
          fullname
        }
        createdAt
        requestedAt
        paidAt
        rejectedAt
        approvedAt
        status
        expenseApprovalRules {
          status
          expenseApprovalRuleEmployees {
            employee {
              employeeId
              user {
                avatarUrl
                userId
                defaultAvatarUrl
                fullname
              }
            }
          }
        }
      }
    }
  }
`;

const useExpenseReportSearchQuery = (variables: MobileExpenseReportSearchQueryVariables) => {
  return useLazyQuery<MobileExpenseReportSearchQuery, MobileExpenseReportSearchQueryVariables>(QUERY_REPORTS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpenseReportSearchQuery;
