// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { View, StyleSheet, Linking, TouchableWithoutFeedback, useWindowDimensions } from "react-native";
import { Button, Text } from "react-native-paper";
import BizziIcon from "assets/images/svg/icons/BizziIcon";
import AppleDownload from "assets/images/svg/icons/AppleDownload";
import GoogleDownload from "assets/images/svg/icons/GoogleDownload";
import AntDesign from "react-native-vector-icons/AntDesign";
import Colors from "constants/Colors";
import { CountdownCircleTimer } from "react-native-countdown-circle-timer";

import { useTranslation } from "react-i18next";

const RegisterInformScreen = ({ route }) => {
  const { width: WWindow } = useWindowDimensions();
  const { t } = useTranslation("screens/RegisterOnboarding/RegisterInformScreen");
  const { isAdmin, companyId } = route?.params ?? {};
  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 60 }}>
        <BizziIcon />
      </View>
      {!isAdmin && (
        <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 147 }}>
          <View>
            <AntDesign name="checkcircle" size={100} color={Colors.success50} />
          </View>
        </View>
      )}
      <Text style={[styles.textInform, { fontSize: WWindow < 768 ? 24 : 30 }]}>{t("register_success")}</Text>
      {isAdmin && (
        <View>
          <View style={{ flexDirection: "row", justifyContent: "center", marginVertical: 58 }}>
            <CountdownCircleTimer
              size={130}
              isPlaying
              duration={7}
              colors={["#1F71F4"]}
              colorsTime={[5]}
              onComplete={() => {
                window.location.href = `https://spend.bizzi.vn/companies/${companyId}/overview`;
              }}
            >
              {({ remainingTime }) => <Text style={styles.time}>{remainingTime}</Text>}
            </CountdownCircleTimer>
          </View>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <Text style={{ color: "#475569", fontSize: 16, lineHeight: 24, fontWeight: "500", textAlign: "center" }}>
              {t("setting")}
            </Text>
          </View>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <Button
              uppercase={false}
              onPress={() => {
                window.location.href = `https://spend.bizzi.vn/companies/${companyId}/overview`;
              }}
              labelStyle={{
                fontSize: 16,
                lineHeight: 24,
                fontWeight: "600",
              }}
            >
              {t("go_home")}
            </Button>
          </View>
        </View>
      )}
      <View
        style={[
          styles.wrapperDownload,
          { flexDirection: WWindow < 768 ? "column" : "row", flexWrap: WWindow < 768 ? "wrap" : "nowrap" },
        ]}
      >
        <View style={[{ marginRight: WWindow < 768 ? 0 : 25 }]}>
          <TouchableWithoutFeedback
            onPress={() => {
              window?.open("https://apps.apple.com/us/app/bizzi-expense/id1552135499");
            }}
          >
            <AppleDownload />
          </TouchableWithoutFeedback>
        </View>
        <View style={WWindow < 768 && { marginTop: 15 }}>
          <TouchableWithoutFeedback
            onPress={() => {
              window?.open("https://play.google.com/store/apps/details?id=vn.bizzi.expense");
            }}
          >
            <GoogleDownload />
          </TouchableWithoutFeedback>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  textInform: {
    fontWeight: "600",

    lineHeight: 38,
    textAlign: "center",
    color: "#000",
    marginTop: 60,
  },
  wrapperDownload: {
    alignSelf: "center",

    justifyContent: "center",
    alignItems: "center",
    marginTop: 60,
  },
  time: {
    fontSize: 30,
    lineHeight: 38,
    fontWeight: "600",
    color: "#000",
  },
});

export default RegisterInformScreen;
