import {
  AppText,
  BottomSheetFlatListModalCustom,
  BottomSheetModalCustomMethods,
  EmptyData,
  IconCustom,
  Line,
  SearchInput,
  SelectCustom,
  useDebounce,
  useSetRelativePosition,
} from "components";
import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useRef, useState, RefObject } from "react";
import { Platform, StyleSheet, TextInput, TouchableOpacity, View, ViewStyle } from "react-native";
import { Colors, Fonts } from "theme";
import { useTranslation } from "react-i18next";
import { ALLOWANCE_TYPE, BOOKING_TYPE, CONSTANTS } from "constants/constants";
import { AirlineLocationEmptyIcon, SearchPageIcon } from "assets/images/svg/icons";
import useExpSearchLocations from "screens/ExpenseRequest/hooks/useExpSearchLocations";
import { MobileExpSearchLocationsQuery } from "types";
import { Control, FieldError, FieldErrorsImpl, Merge, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { ExpenseRequestFormValues, Location } from "screens/ExpenseRequest/types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { countNumberOfDays, getTitleFromLocation, startLayoutAnimation } from "utils";
import useExpensePoliciesPerdiemByLocation from "screens/ExpenseRequest/hooks/useExpensePoliciesPerdiemByLocation";
import { useAuth } from "contexts/AuthContext";
import { v4 } from "uuid";
import useExpEmployeeBookingLocations from "screens/ExpenseRequest/hooks/useExpEmployeeBookingLocations";
import i18n from "i18next";
import LanguageStatus from "constants/LanguageStatus";
import useExpensePoliciesBookingAirports from "screens/ExpenseRequest/hooks/useExpensePoliciesBookingAirports";

const PAGE_SIZE = 20;
type LocationItem = MobileExpSearchLocationsQuery["expSearchLocations"]["locations"][0];
interface LocationInputProps {
  label?: string;
  style?: ViewStyle;

  name: string;
  control?: Control<ExpenseRequestFormValues>;
  rules?: Record<string, unknown>;
  error?: Merge<FieldError, FieldErrorsImpl<ExpenseRequestFormValues>>;
  setValue?: UseFormSetValue<ExpenseRequestFormValues>;
  leftIcon?: ReactElement;
  value?: { label?: string; metadata?: Location };
  getValues?: UseFormGetValues<ExpenseRequestFormValues>;
  searchDescription?: string;
  recentLocationKey?: string;
  warningMessage?: string;
  bookingType?: BOOKING_TYPE;
  isSearchAllWithoutPolicy?: boolean;
  handleAutoAddAllowances?: (type: BOOKING_TYPE) => void;
  notFoundDescription?: string;
  setPosition?: (y: number) => void;
  containerRef?: RefObject<any>;
}
const LocationInput = forwardRef<any, LocationInputProps>((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleAutoGenerateAllowancePerdiem: (location) => {
      handleAutoGenerateAllowancePerdiem(location);
    },
  }));
  const {
    name,
    control,
    style,
    label,
    setValue,
    error,
    rules,
    leftIcon,
    searchDescription,
    warningMessage,
    recentLocationKey,
    getValues,
    bookingType,
    isSearchAllWithoutPolicy,
    handleAutoAddAllowances,
    notFoundDescription,
    setPosition,
    containerRef,
  } = props;
  const childRef = useRef();
  useSetRelativePosition({ containerRef, childRef, setPosition });
  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const {
    user: { employee_id },
  } = useAuth();
  const bottomSheetRef = useRef<BottomSheetModalCustomMethods>(null);
  const [keyword, setKeyword] = useState("");
  const debounceSearchValue = useDebounce<string>(keyword);
  const [onFetchLocations, { loading, data }] = useExpSearchLocations();
  const [onFetchBookingLocations, { loading: bookingLocationLoading, data: bookingLocation }] =
    useExpEmployeeBookingLocations();
  const [recentLocations, setRecentLocations] = useState<LocationItem[]>([]);
  const [onFetchAirports] = useExpensePoliciesBookingAirports();
  const textSearchRef = useRef<TextInput>();
  const [onFetchPerdiemByLocation] = useExpensePoliciesPerdiemByLocation();

  useEffect(() => {
    handleLoadRecentLocationsFromStorage();
  }, []);
  /*-- auto add allowances perdiem travel when select location to --*/
  /*useEffect(() => {
    if (name === "travel.toCity" && value?.metadata?.code) {
      handleAutoGenerateAllowancePerdiem(value?.metadata);
    }
    if (value?.metadata?.code) {
      handleAutoFillLocationBooking(value?.metadata, name === "travel.toCity");
    }
  }, [value?.metadata?.code]);*/
  /*-- end --*/

  useEffect(() => {
    if (debounceSearchValue?.length >= 2) {
      if (isSearchAllWithoutPolicy) {
        const where: ExpSearchLocationsWhereInput = {
          limit: PAGE_SIZE,
          keyword: debounceSearchValue,
          preferCountryCode: "VN",
        };
        onFetchLocations({
          variables: {
            where,
          },
        });
      } else {
        const input: ExpEmployeeBookingLocationsInput = {
          employeeId: employee_id,
          bookingType,
          limit: PAGE_SIZE,
          keyword: debounceSearchValue,
          preferCountryCode: "VN",
        };
        onFetchBookingLocations({ variables: { input } });
      }
    }
  }, [debounceSearchValue]);
  const handleLoadRecentLocationsFromStorage = async () => {
    const data = await AsyncStorage.getItem(recentLocationKey);
    if (data) {
      setRecentLocations(JSON.parse(data) as LocationItem[]);
    }
  };
  const handleClearSearch = () => {
    setKeyword("");
    textSearchRef?.current?.setNativeProps({ text: "" });
    textSearchRef?.current?.clear();
  };
  const onChangeSearch = (text) => {
    setKeyword(text);
  };
  const handleClear = () => {
    setValue(name === "travel.fromCity" ? "flightBooking.from" : "flightBooking.to", null);
  };
  const handleSetRecentLocation = async (item: LocationItem) => {
    const recents = await AsyncStorage.getItem(recentLocationKey);
    let locations = [item];
    if (recents) {
      locations = locations.concat(JSON.parse(recents)?.filter((i) => i?.locationId !== item?.locationId));
    }
    AsyncStorage.setItem(recentLocationKey, JSON.stringify(locations.slice(0, 5)));
  };
  const handleAutoGenerateAllowancePerdiem = async (item) => {
    const numOfDays = countNumberOfDays(getValues("travelDate.fromDate"), getValues("travelDate.toDate"));
    const rs = await onFetchPerdiemByLocation({
      variables: { input: { employeeId: employee_id, locationCode: item?.code } },
    });
    if (
      rs?.data?.expExpensePoliciesPerdiemByLocation?.perdiemByLocations?.length &&
      !rs?.data?.expExpensePoliciesPerdiemByLocation?.perdiemByLocations[0]?.expenseCategory
    ) {
      setValue("travel.perDiem", rs?.data?.expExpensePoliciesPerdiemByLocation?.perdiemByLocations[0]?.amount);
      return;
    } else if (!rs?.data?.expExpensePoliciesPerdiemByLocation?.perdiemByLocations?.length) {
      setValue("travel.perDiem", 0);
    }
    const newAllowances = rs?.data?.expExpensePoliciesPerdiemByLocation?.perdiemByLocations
      ?.map((item) => ({
        id: v4(),
        amount: item?.amount * numOfDays,
        expenseCategoryId: item?.expenseCategory?.expenseCategoryId,
        type: ALLOWANCE_TYPE.PERDIEM,
        expenseCategory: {
          title: item?.expenseCategory?.title,
          titleEn: item?.expenseCategory?.titleEn,
        },
      }))
      .sort((a, b) => {
        if (i18n.language === LanguageStatus.VN) {
          return a.expenseCategory.title.localeCompare(b.expenseCategory.title);
        }
        return a.expenseCategory.titleEn.localeCompare(b.expenseCategory.titleEn);
      });
    const allowances = getValues("allowances")?.filter((item) => item?.type !== ALLOWANCE_TYPE.PERDIEM);
    setValue("allowances", [...newAllowances, ...allowances]);
    startLayoutAnimation();
  };
  const handleAutoFillLocationBooking = async (item, isChooseLocationTo) => {
    /*-- auto fill bus location when choose location of VN --*/
    if (getValues("transportationBooking.isEnabled") && item?.countryCode === "VN") {
      const busTicketLocationItem = {
        label: getTitleFromLocation(item),
        metadata: { name: item?.name, nameEn: item?.nameEn, code: item?.code },
      };
      setValue(
        name === "travel.fromCity" ? "transportationBooking.from" : "transportationBooking.to",
        busTicketLocationItem,
        {
          shouldValidate: true,
        }
      );
      if (getValues("transportationBooking.isOn")) {
        handleAutoAddAllowances?.(BOOKING_TYPE.TRANSPORTATION);
      }
    }
    /*-- end --*/

    /*-- auto fill hotel booking when choose location --*/
    if (getValues("hotelBooking.isEnabled") && isChooseLocationTo) {
      const hotelLocationItem = {
        label: getTitleFromLocation(item),
        metadata: { name: item?.name, nameEn: item?.nameEn, code: item?.code },
      };
      setValue("hotelBooking.location", hotelLocationItem, {
        shouldValidate: true,
      });
      if (getValues("hotelBooking.isOn")) {
        handleAutoAddAllowances?.(BOOKING_TYPE.HOTEL);
      }
    }
    /*-- end --*/

    /*-- auto fill airport when choose location to --*/
    if (getValues("flightBooking.isEnabled")) {
      // disabled auto fill location flight booking in the case duplicate location
      if (
        (name === "travel.fromCity" && item?.code === getValues("flightBooking.to.location.code")) ||
        (name === "travel.toCity" && item?.code === getValues("flightBooking.from.location.code"))
      ) {
        return;
      }
      const result = await onFetchAirports({
        variables: {
          input: { employeeId: employee_id, limit: 2, locationCodes: [item?.code] },
        },
      });
      if (result?.data?.expEmployeeBookingAirports?.airports?.length === 1) {
        const airportDetail = result.data.expEmployeeBookingAirports.airports[0];
        const airportItem = {
          code: airportDetail?.code,
          name: airportDetail?.name,
          nameEn: airportDetail?.nameEn,
          location: {
            code: airportDetail?.locationCode,
            name: airportDetail?.location?.name,
            nameEn: airportDetail?.location?.nameEn,
          },
        };
        setValue(name === "travel.fromCity" ? "flightBooking.from" : "flightBooking.to", airportItem, {
          shouldValidate: true,
        });
      } else {
        setValue(name === "travel.fromCity" ? "flightBooking.from" : "flightBooking.to", null, {
          shouldValidate: true,
        });
      }
      if (getValues("flightBooking.isOn")) {
        handleAutoAddAllowances?.(BOOKING_TYPE.FLIGHT);
      }
    }
    /*-- end --*/
  };
  const handleChooseLocation = (item) => async () => {
    bottomSheetRef.current?.close();
    setValue(
      name as keyof ExpenseRequestFormValues,
      {
        label: getTitleFromLocation(item),
        metadata: { locationId: item?.locationId, name: item?.name, nameEn: item?.nameEn, code: item?.code },
      },
      { shouldValidate: true }
    );
    if (name === "travel.toCity") {
      handleAutoGenerateAllowancePerdiem(item);
    }
    if (name === "travel.fromCity" || name === "travel.toCity") {
      handleAutoFillLocationBooking(item, name === "travel.toCity");
    }
    handleSetRecentLocation(item);
  };
  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity onPress={handleChooseLocation(item)}>
        <View style={styles.locationItem}>
          <IconCustom name="location-on-outline" fill={Colors.grayscale60} />
          <AppText style={[Fonts.BodyMedium, styles.text]}>
            {getTitleFromLocation({ name: item?.name, nameEn: item?.nameEn, parent: item?.parent })}
          </AppText>
        </View>
      </TouchableOpacity>
    );
  };
  const handleOpenModal = () => {
    handleLoadRecentLocationsFromStorage();
    bottomSheetRef?.current?.present();
    // auto focus when open modal
    if (Platform.OS !== "web") {
      setTimeout(() => {
        textSearchRef?.current?.focus();
      }, 100);
    }
  };

  const renderSearch = () => (
    <SearchInput
      ref={textSearchRef}
      style={styles.searchInput}
      placeholder={t("search_city_location")}
      onChangeText={onChangeSearch}
      autoCapitalize="none"
      enablesReturnKeyAutomatically
      returnKeyType="search"
      right={
        keyword ? (
          <TouchableOpacity onPress={handleClearSearch}>
            <IconCustom name="cancel" />
          </TouchableOpacity>
        ) : null
      }
    />
  );
  const renderHeaderComponent = () => {
    if (debounceSearchValue || !recentLocations?.length) {
      return null;
    }
    return (
      <View style={styles.headerComponent}>
        <AppText style={Fonts.H300}>{t("recent")}</AppText>
      </View>
    );
  };
  return (
    <View style={style} ref={childRef}>
      <SelectCustom
        name={name}
        control={control}
        label={label}
        onPress={handleOpenModal}
        rightIcon={<View />}
        leftIcon={
          leftIcon ? (
            leftIcon
          ) : name === "travel.fromCity" ? (
            <IconCustom name="adjust" />
          ) : (
            <IconCustom name="location-on" />
          )
        }
        error={error}
        rules={rules}
        warningMessage={warningMessage}
      />
      <BottomSheetFlatListModalCustom
        onDismiss={() => setKeyword("")}
        loading={loading || bookingLocationLoading}
        renderSearch={renderSearch}
        ref={bottomSheetRef}
        snapPoints={[CONSTANTS.COMMON.BOTTOM_SHEET_MAX_HEIGHT]}
        title={label}
        listProps={{
          data: debounceSearchValue
            ? (isSearchAllWithoutPolicy
                ? data?.expSearchLocations?.locations
                : bookingLocation?.expEmployeeBookingLocations?.locations) || []
            : recentLocations,
          renderItem,
          keyExtractor: (item) => item?.locationId,
          ListEmptyComponent: (
            <EmptyData
              title={debounceSearchValue ? t("search_location_not_found") : t("search")}
              description={
                debounceSearchValue
                  ? notFoundDescription || t("search_location_not_found_description")
                  : searchDescription
              }
              icon={debounceSearchValue ? <AirlineLocationEmptyIcon /> : <SearchPageIcon />}
            />
          ),
          ListHeaderComponent: renderHeaderComponent,
          ItemSeparatorComponent: () => <Line backgroundColor={Colors.grayscale0} />,
        }}
      />
    </View>
  );
});
export default LocationInput;

const styles = StyleSheet.create({
  locationItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    paddingVertical: 12,
    gap: 6,
  },
  text: {
    flex: 1,
  },
  searchInput: { marginHorizontal: 20, marginBottom: 5 },
  headerComponent: { paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING, marginTop: 6 },
});
