import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import {
  MobileExpExpensePoliciesBookingByLocationQuery,
  MobileExpExpensePoliciesBookingByLocationQueryVariables,
} from "types";

const QUERY = gql`
  query MobileExpExpensePoliciesBookingByLocation($input: ExpExpensePoliciesBookingByLocationInput!) {
    expExpensePoliciesBookingByLocation(input: $input) {
      amount
      expenseCategory {
        expenseCategoryId
        title
        titleEn
      }
    }
  }
`;

const useExpensePoliciesBookingByLocation = (variables?: MobileExpExpensePoliciesBookingByLocationQueryVariables) => {
  return useLazyQuery<
    MobileExpExpensePoliciesBookingByLocationQuery,
    MobileExpExpensePoliciesBookingByLocationQueryVariables
  >(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
};

export default useExpensePoliciesBookingByLocation;
