import { Component } from "react";
import * as React from "react";

import { StyleSheet, View, TouchableOpacity, StyleProp, ViewStyle, TextStyle } from "react-native";
import Colors from "app/constants/Colors";

import getIconType from "./helper";

interface IIconProps {
  type?: string;
  name: string;
  size: number;
  color: string;
  iconStyle: StyleProp<ViewStyle>;
  containerStyle: StyleProp<ViewStyle>;
  onPress: Function;
}

class Icon extends Component<IIconProps, {}> {
  static defaultProps = {
    containerStyle: null,
    onPress: null,
    iconStyle: null,
    size: 24,
    color: Colors.blackText,
    isAnimated: false,
    type: "simple-line-icon",
  };

  shouldComponentUpdate(nextProps: IIconProps) {
    const { type, name, size, color } = this.props;

    if (nextProps.type !== type || nextProps.name !== name || nextProps.size !== size || nextProps.color !== color) {
      return true;
    }

    return false;
  }

  render() {
    const { type, name, size, color, iconStyle, containerStyle, onPress } = this.props;

    const Component: React.ElementType = onPress ? TouchableOpacity : View;
    const IconComponent = getIconType(type);

    return (
      <Component
        style={StyleSheet.flatten([styles.container, containerStyle])}
        disabled={!onPress}
        onPress={onPress}
        activeOpacity={1}
      >
        <IconComponent
          style={StyleSheet.flatten([styles.iconStyle, iconStyle])}
          size={size}
          name={name}
          color={color}
        />
      </Component>
    );
  }
}

const styles = StyleSheet.create({
  container: { alignItems: "center", justifyContent: "center" },
  iconStyle: {},
});

export default Icon;
