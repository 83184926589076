import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { MobileExpenseReportQuery, MobileExpenseReportQueryVariables } from "types";

const QUERY_REPORTS = gql`
  query MobileExpenseReport(
    $where: ExpMobileExpenseReportCondition!
    $sortOption: ExpMobileExpenseReportsSortOptions
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
  ) {
    expExpenseReports: expMobileExpenseReports(where: $where, sortOption: $sortOption, limit: $limit, offset: $offset) {
      total
      reports {
        allocatedStatus
        expenseReportId
        title
        docSequence
        expenses {
          expenseId
          totalAmountWithVat
        }
        companyEmployeeId
        user {
          fullname
          email
          userId
        }
        createdAt
        requestedAt
        paidAt
        rejectedAt
        approvedAt
        status
        expenseApprovalRules {
          status
          expenseApprovalRuleEmployees {
            employee {
              employeeId
              user {
                avatarUrl
                userId
                defaultAvatarUrl
                fullname
              }
            }
          }
        }
      }
    }
  }
`;

const useExpenseReportQuery = (variables: MobileExpenseReportQueryVariables) => {
  return useQuery<MobileExpenseReportQuery, MobileExpenseReportQueryVariables>(QUERY_REPORTS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    skip: !variables.where.companyId || !variables.where.companyEmployeeId,
  });
};

export default useExpenseReportQuery;
