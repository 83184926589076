// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createContext, useMemo, useCallback, useEffect, Dispatch, ReactElement } from "react";

import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Asyncstorage from "@react-native-async-storage/async-storage";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/vi";

const updateLocale = require("dayjs/plugin/updateLocale");
const isBetween = require("dayjs/plugin/isBetween");
const weekday = require("dayjs/plugin/weekday");

dayjs.extend(isBetween);

dayjs.extend(updateLocale);

dayjs.extend(weekday);

dayjs.locale("en");

// dayjs.updateLocale("en", {
//   months: [
//     "Tháng 1",
//     "Tháng 2",
//     "Tháng 3",
//     "Tháng 4",
//     "Tháng 5",
//     "Tháng 6",
//     "Tháng 7",
//     "Tháng 8",
//     "Tháng 9",
//     "Tháng 10",
//     "Tháng 11",
//     "Tháng 12",
//   ],
// });

dayjs.updateLocale("vi", {
  weekdaysMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  weekdaysShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
});

export const LocalizationContext = createContext<{
  t: (scope: any, options?: any) => string;
  locale: any;
  setLocale: Dispatch<any>;
  dayjs?: typeof dayjs;
}>({
  t: () => "",
  locale: "vi",
  setLocale: () => {},
});

const KEYS = "locale";

const { Provider, Consumer } = LocalizationContext;

const LocalizationProvider = ({ children }: { children: ReactElement }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const loadingLanguage = async () => {
      const locateStore = await Asyncstorage.getItem(KEYS);

      if (locateStore) {
        i18n.changeLanguage(locateStore);
      }
    };
    loadingLanguage();
  }, [i18n]);

  const localizationContext = useMemo(
    () => ({
      dayjs,
    }),
    []
  );

  return <Provider value={localizationContext}>{children}</Provider>;
};

LocalizationProvider.Consumer = Consumer;

export default LocalizationProvider;
