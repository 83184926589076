import { useMutation, useQuery } from "@apollo/client";
import AttachmentType from "constants/AttachmentType";
import { APPROVAL_EMPLOYEE_STATUS } from "constants/constants";
import RequestAttachmentType from "constants/RequestAttachmentType";
import { useAuth } from "contexts/AuthContext";
import gql from "graphql-tag";
import useRequestAttachment from "hooks/request/useRequestAttachments";
import useUploadFileSignedURL from "hooks/useUploadFileSignedURL";
import { useCallback, useMemo } from "react";
import {
  ExpenseRequestCreateForm,
  ExpenseRequestListTabs,
  ExpenseRequestUpdateForm,
} from "screens/ExpenseRequest/types";
import {
  MobileCreateExpenseRequestMutation,
  MobileCreateExpenseRequestMutationVariables,
  MobileExpenseRequestListLegacyQuery,
  MobileExpenseRequestListLegacyQueryVariables,
  MobileExpenseRequestListQuery,
  MobileExpenseRequestListQueryVariables,
  MobileSubmitExpenseRequestMutation,
  MobileSubmitExpenseRequestMutationVariables,
  MobileUpdateExpenseRequestMutation,
  MobileUpdateExpenseRequestMutationVariables,
} from "types";

const NUMBER_ROWS_PER_PAGE = 10;

const QUERY_REQUEST_LEGACY = gql`
  query MobileExpenseRequestListLegacy($employee_id: uuid!, $limit: Int!, $offset: Int!) {
    expense_requests(
      where: { employee_id: { _eq: $employee_id }, deleted_timestamp: { _eq: 0 } }
      order_by: { created_at: desc_nulls_last }
      limit: $limit
      offset: $offset
    ) {
      expense_request_id
      expense_request_type
      doc_sequence
      title
      status
      is_closed
      closed_at
      updated_at
      total_amount
    }
    expense_requests_aggregate(where: { employee_id: { _eq: $employee_id }, deleted_timestamp: { _eq: 0 } }) {
      aggregate {
        count
      }
    }
  }
`;
/**
 * @deprecated
 */
export const useLegacyExpenseRequests = () => {
  const { user } = useAuth();

  const defaultVariables = useMemo(
    () => ({ employee_id: user.employee_id, limit: NUMBER_ROWS_PER_PAGE, offset: 0 }),
    [user]
  );

  const results = useQuery<MobileExpenseRequestListLegacyQuery, MobileExpenseRequestListLegacyQueryVariables>(
    QUERY_REQUEST_LEGACY,
    {
      variables: defaultVariables,
      notifyOnNetworkStatusChange: true,
      skip: !user.employee_id,
    }
  );

  return { ...results, defaultVariables, QUERY_REQUEST: QUERY_REQUEST_LEGACY };
};

const QUERY_REQUEST = gql`
  fragment ExpenseRequestListItem on expense_requests {
    expense_request_id
    expense_request_type
    doc_sequence
    title
    status
    is_closed
    closed_at
    updated_at
    total_amount
    employee_id
    company_employee {
      employee_id
      fullname
    }
    expense_approval_rules(
      where: { expense_approval_rule_employees: { employee_id: { _eq: $employeeId }, status: { _in: [1, 2] } } }
      order_by: { updated_at: desc }
    ) {
      expense_approval_rule_id
      status
      expense_approval_rule_employees {
        expense_approval_rules_employees_id
        employee_id
      }
    }
  }
  query MobileExpenseRequestList(
    $limit: Int!
    $offset: Int!
    $filters: [expense_requests_bool_exp!]
    $employeeId: uuid!
  ) {
    expense_requests(
      where: { deleted_timestamp: { _eq: 0 }, _and: $filters }
      order_by: { created_at: desc_nulls_last }
      limit: $limit
      offset: $offset
    ) {
      ...ExpenseRequestListItem
    }
    expense_requests_aggregate(where: { deleted_timestamp: { _eq: 0 }, _and: $filters }) {
      aggregate {
        count
      }
    }
  }
`;

export const useExpenseRequests = (view: ExpenseRequestListTabs, additionalFilters: ExpenseRequestsBoolExp[] = []) => {
  const { user } = useAuth();

  const defaultVariables = useMemo<MobileExpenseRequestListQueryVariables>(
    () => ({
      limit: NUMBER_ROWS_PER_PAGE,
      offset: 0,
      filters: [
        ...(view === ExpenseRequestListTabs.MINE
          ? [
              {
                employee_id: { _eq: user.employee_id },
              },
            ]
          : []),
        ...(view === ExpenseRequestListTabs.WAITING_FOR_APPROVAL
          ? [
              {
                status: {
                  _in: ["submitted"],
                },
                expense_approval_rules: {
                  status: {
                    _eq: 0,
                  },
                  expense_approval_rule_employees: {
                    employee_id: {
                      _eq: user.employee_id,
                    },
                    status: {
                      _eq: 0,
                    },
                  },
                },
              },
            ]
          : []),
        ...(view === ExpenseRequestListTabs.HISTORY
          ? [
              {
                expense_approval_rules: {
                  expense_approval_rule_employees: {
                    status: {
                      _in: [APPROVAL_EMPLOYEE_STATUS.APPROVED, APPROVAL_EMPLOYEE_STATUS.REJECTED],
                    },
                    employee_id: {
                      _eq: user.employee_id,
                    },
                  },
                },
              },
            ]
          : []),
        ...additionalFilters,
      ],
      employeeId: user.employee_id,
    }),
    [additionalFilters, user.employee_id, view]
  );

  return useQuery<MobileExpenseRequestListQuery, MobileExpenseRequestListQueryVariables>(QUERY_REQUEST, {
    variables: defaultVariables,
    notifyOnNetworkStatusChange: true,
  });
};

const MUTATION_CREATE_EXPENSE_REQUEST = gql`
  mutation MobileCreateExpenseRequest($request: ExpenseRequestCreateInput!) {
    expenseRequest: expCreateExpenseRequest(request: $request) {
      expenseRequestId
      message
      approvalType
    }
  }
`;

const MUTATION_SUBMIT_EXPENSE_REQUEST = gql`
  mutation MobileSubmitExpenseRequest($requestId: UUID!, $approverEmployeeId: UUID) {
    expenseRequest: expSubmitExpenseRequest(expenseRequestId: $requestId, approverEmployeeId: $approverEmployeeId) {
      success
      expenseRequestId
      message
      approvalType
    }
  }
`;

const MUTATION_UPDATE_EXPENSE_REQUEST = gql`
  mutation MobileUpdateExpenseRequest($requestId: UUID!, $payload: ExpenseRequestUpdateInput!) {
    expenseRequest: expUpdateExpenseRequest(expenseRequestId: $requestId, request: $payload) {
      expenseRequestId
      message
      approvalType
    }
  }
`;

export const useCreateExpenseRequest = () => {
  const [mutate, options] = useMutation<
    MobileCreateExpenseRequestMutation,
    MobileCreateExpenseRequestMutationVariables
  >(MUTATION_CREATE_EXPENSE_REQUEST);

  const { uploadImage } = useUploadFileSignedURL();
  const { addRequestAttachments } = useRequestAttachment();

  const createExpenseRequest = useCallback(
    async ({ attachments, ...request }: ExpenseRequestCreateForm) => {
      const result = await mutate({ variables: { request } });
      console.log(result);
      if (result.data?.expenseRequest?.expenseRequestId && (attachments?.length ?? 0) > 0) {
        const expenseRequestId = result.data.expenseRequest.expenseRequestId;
        console.log("uploading");
        const newAttachments = (
          await uploadImage({
            objectId: expenseRequestId,
            companyId: request.companyId,
            images: attachments,
            type: 2,
            subAttachmentType: AttachmentType.TYPE_REQUEST,
          })
        ).filter(Boolean);
        console.log("uploaded");
        await addRequestAttachments({
          variables: {
            attachments: newAttachments.map((a: any) => ({
              ...a,
              expense_request_id: expenseRequestId,
              employee_id: request.employeeId,
            })),
          },
        });
      }

      return result;
    },
    [addRequestAttachments, mutate, uploadImage]
  );

  return {
    createExpenseRequest,
    ...options,
  };
};

export const useSubmitExpenseRequest = () => {
  const [mutate, options] = useMutation<
    MobileSubmitExpenseRequestMutation,
    MobileSubmitExpenseRequestMutationVariables
  >(MUTATION_SUBMIT_EXPENSE_REQUEST);

  const submitExpenseRequest = useCallback(
    (requestId: string, approverEmployeeId?: string) => {
      return mutate({ variables: { requestId, approverEmployeeId: approverEmployeeId || null } });
    },
    [mutate]
  );

  return {
    submitExpenseRequest,
    ...options,
  };
};

export const useUpdateExpenseRequest = () => {
  const [mutate, options] = useMutation<
    MobileUpdateExpenseRequestMutation,
    MobileUpdateExpenseRequestMutationVariables
  >(MUTATION_UPDATE_EXPENSE_REQUEST);

  const { uploadImage } = useUploadFileSignedURL();
  const { addRequestAttachments, deleteRequestAttachments } = useRequestAttachment();

  const updateExpenseRequest = useCallback(
    async (
      requestId: string,
      employeeId: string,
      { newAttachments, removeAttachments, attachments: _attachments, ...payload }: ExpenseRequestUpdateForm
    ) => {
      const result = await mutate({ variables: { requestId, payload } });

      const { companyId } = payload;

      if (newAttachments?.length > 0) {
        const uploadedAttachments = (
          await uploadImage({
            objectId: requestId,
            companyId,
            images: newAttachments,
            type: RequestAttachmentType.OTHER_PAPER,
            subAttachmentType: AttachmentType.TYPE_REQUEST,
          })
        ).filter(Boolean);

        if (uploadedAttachments.length > 0) {
          await addRequestAttachments({
            variables: {
              attachments: uploadedAttachments.map((attachment: any) => ({
                ...attachment,
                expense_request_id: requestId,
                employee_id: employeeId,
              })),
            },
          });
        }
      }

      if (removeAttachments?.length > 0) {
        const promiseRemoveAttachments = removeAttachments.map((attachment) =>
          deleteRequestAttachments({
            variables: {
              expense_request_attachment_id: attachment.id,
              attachment: { deleted_timestamp: 1 },
            },
          })
        );

        await Promise.all(promiseRemoveAttachments);
      }

      return result;
    },
    [mutate]
  );

  return {
    updateExpenseRequest,
    ...options,
  };
};
