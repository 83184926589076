import { formatMoney } from "utils";
import i18n from "i18next";
import { REQUEST_TYPE } from "constants/constants";
import IconCustom from "components/IconCustom";
import { Colors } from "theme";

export const generateAllowancePurchaseDescription = ({
  productName,
  unitPrice,
  uom,
  quantity,
}: {
  productName?: string;
  uom?: string;
  quantity: number;
  unitPrice?: number;
}) => {
  const results = [];
  if (productName) {
    results.push(`${i18n.t("common:product_name")}: ${productName}`);
  }
  if (uom) {
    results.push(`${i18n.t("common:unit_product")}: ${uom}`);
  }
  if (quantity) {
    results.push(`${i18n.t("common:quantity")}: ${quantity}`);
  }
  if (unitPrice) {
    results.push(`${i18n.t("common:unit_price")}: ${formatMoney(unitPrice)}₫`);
  }
  return results.join(" - ");
};

export const getRequestIconByType = (requestType: REQUEST_TYPE) => {
  switch (requestType) {
    case REQUEST_TYPE.travel:
      return <IconCustom name="luggage" />;
    case REQUEST_TYPE.other:
      return <IconCustom name="paid-outline" fill={Colors.grayscale100} />;
    case REQUEST_TYPE.purchase:
      return <IconCustom name="shopping-cart" />;
    case REQUEST_TYPE.price_comparison:
      return <IconCustom name="sell-outline" />;
    default:
      return null;
  }
};
