import home from "./home";
import home_outline from "./home-outline";
import receipt from "./receipt";
import receipt_outline from "./receipt-outline";
import paid from "./paid";
import paid_outline from "./paid-outline";
import contract from "./contract";
import contract_outline from "./contract-outline";
import credit_card from "./credit-card";
import credit_card_outline from "./credit-card-outline";
import add_circle from "./add-circle";
import add_circle_outline from "./add-circle-outline";
import check_circle from "./check-circle";
import description from "./description";
import add_a_photo from "./add-a-photo";
import add_a_photo_outline from "./add-a-photo-outline";
import notifications_outline from "./notifications-outline";
import west from "./west";
import info from "./info";
import arrow_back_ios_new from "./arrow-back-ios-new";
import arrow_forward_ios from "./arrow-forward-ios";
import arrow_back from "./arrow-back";
import arrow_right from "./arrow-right";
import arrow_right_alt from "./arrow-right-alt";
import arrow_drop_down from "./arrow-drop-down";
import arrow_forward from "./arrow-forward";
import arrow_upward from "./arrow-upward";
import arrow_upward_alt from "./arrow-upward-alt";
import arrow_downward from "./arrow-downward";
import arrow_downward_alt from "./arrow-downward-alt";
import visibility from "./visibility";
import visibility_off from "./visibility-off";
import finance from "./finance";
import mail from "./mail";
import schedule from "./schedule";
import schedule_filled from "./schedule-filled";
import calendar_month from "./calendar-month";
import compare_arrows from "./compare-arrows";
import expand_more from "./expand-more";
import adjust from "./adjust";
import location_on from "./location-on";
import location_on_outline from "./location-on-outline";
import flight_land from "./flight-land";
import flight_takeoff from "./flight-takeoff";
import share from "./share";
import autorenew from "./autorenew";
import content_copy from "./content-copy";
import sell from "./sell";
import sell_outline from "./sell-outline";
import hotel from "./hotel";
import minus from "./minus";
import cancel from "./cancel";
import photo_library from "./photo-library";
import note from "./note";
import warning from "./warning";
import refresh from "./refresh";
import qr_code from "./qr-code";
import policy from "./policy";
import cached from "./cached";
import g_translate from "./g-translate";
import shield_person from "./shield-person";
import live_help from "./live-help";
import dictionary from "./dictionary";
import info_outline from "./info-outline";
import call from "./call";
import star from "./star";
import star_outline from "./star-outline";
import logout from "./logout";
import deleteIcon from "./delete";
import demography from "./demography";
import gpp_maybe from "./gpp-maybe";
import verify_user from "./verify-user";
import lock from "./lock";
import lock_open_right from "./lock-open-right";
import chat from "./chat";
import search from "./search";
import check from "./check";
import filter_alt from "./filter-alt";
import edit_square from "./edit-square";
import request_page from "./request-page";
import request_page_outline from "./request-page-outline";
import qr_code_scanner from "./qr-code-scanner";
import flash_on from "./flash-on";
import flash_off from "./flash-off";
import navigate_next from "./navigate-next";
import sort from "./sort";
import play_arrow from "./play-arrow";
import kidStar from "./kid-star";
import vector_right from "./vector-right";
import travel_luggage_and_bags from "./travel-luggage-and-bags";
import chair_alt from "./chair-alt";
import help from "./help";
import feedback from "./feedback";
import timeline_check_circle from "./timeline-check-circle";
import timeline_times from "./timeline-times";
import timeline_scheduled_check from "./timeline-scheduled-check";
import timeline_circle from "./timeline-circle";
import check_circle_outline from "./check-circle-outline";
import star_custom from "./star-custom";
import swap_vert from "./swap-vert";
import more_vert from "./more-vert";
import data_check from "./data-check";
import money_send from "./money-send";
import receipt_custom from "./receipt-custom";
import selected_category from "./selected-category";
import expand_less from "./expand-less";
import menu_book from "./menu-book";
import keyboard_arrow_right from "./keyboard-arrow-right";
import smart_display from "./smart-display";
import inventory_2 from "./inventory-2";
import shopping_cart from "./shopping-cart";
import luggage from "./luggage";
import more_horiz from "./more-horiz";
import attach_money from "./attach-money";

const BizziIcon = {
  home,
  "home-outline": home_outline,
  receipt,
  "receipt-outline": receipt_outline,
  paid,
  "paid-outline": paid_outline,
  contract,
  "contract-outline": contract_outline,
  "credit-card": credit_card,
  "credit-card-outline": credit_card_outline,
  "add-circle": add_circle,
  "add-circle-outline": add_circle_outline,
  "add-a-photo": add_a_photo,
  "add-a-photo-outline": add_a_photo_outline,
  description,
  "check-circle": check_circle,
  "notifications-outline": notifications_outline,
  west,
  info,
  "arrow-drop-down": arrow_drop_down,
  "arrow-right": arrow_right,
  "arrow-right-alt": arrow_right_alt,
  "arrow-back": arrow_back,
  "arrow-forward": arrow_forward,
  "arrow-back-ios-new": arrow_back_ios_new,
  "arrow-forward-ios": arrow_forward_ios,
  "arrow-upward": arrow_upward,
  "arrow-upward-alt": arrow_upward_alt,
  "arrow-downward": arrow_downward,
  "arrow-downward-alt": arrow_downward_alt,
  visibility,
  "visibility-off": visibility_off,
  finance,
  mail,
  schedule,
  "schedule-filled": schedule_filled,
  "calendar-month": calendar_month,
  "compare-arrows": compare_arrows,
  "expand-more": expand_more,
  adjust,
  "location-on": location_on,
  "location-on-outline": location_on_outline,
  "flight-land": flight_land,
  "flight-takeoff": flight_takeoff,
  share,
  autorenew,
  "content-copy": content_copy,
  sell,
  "sell-outline": sell_outline,
  hotel,
  minus,
  cancel,
  "photo-library": photo_library,
  note,
  warning,
  refresh,
  "qr-code": qr_code,
  policy,
  "g-translate": g_translate,
  cached,
  "shield-person": shield_person,
  "live-help": live_help,
  dictionary,
  "info-outline": info_outline,
  call,
  star,
  "star-outline": star_outline,
  logout,
  delete: deleteIcon,
  demography,
  "gpp-maybe": gpp_maybe,
  "verify-user": verify_user,
  lock,
  "lock-open-right": lock_open_right,
  chat,
  search,
  check,
  "filter-alt": filter_alt,
  "edit-square": edit_square,
  "request-page": request_page,
  "request-page-outline": request_page_outline,
  "qr-code-scanner": qr_code_scanner,
  "flash-on": flash_on,
  "flash-off": flash_off,
  "navigate-next": navigate_next,
  sort,
  "vector-right": vector_right,
  "travel-luggage-and-bags": travel_luggage_and_bags,
  "play-arrow": play_arrow,
  "kid-star": kidStar,
  "chair-alt": chair_alt,
  help,
  feedback,
  "timeline-circle": timeline_circle,
  "timeline-scheduled-check": timeline_scheduled_check,
  "timeline-check-circle": timeline_check_circle,
  "timeline-times": timeline_times,
  "check-circle-outline": check_circle_outline,
  "star-custom": star_custom,
  "swap-vert": swap_vert,
  "more-vert": more_vert,
  "data-check": data_check,
  "money-send": money_send,
  "receipt-custom": receipt_custom,
  "selected-category": selected_category,
  "expand-less": expand_less,
  "menu-book": menu_book,
  "keyboard-arrow-right": keyboard_arrow_right,
  "smart-display": smart_display,
  "inventory-2": inventory_2,
  "shopping-cart": shopping_cart,
  luggage,
  "more-horiz": more_horiz,
  "attach-money": attach_money,
};
export default BizziIcon;
export type IconName = keyof typeof BizziIcon;
