import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

const GET_WAITING_APPROVAL_OBJECTS = gql`
  query MobileExpWaitingApprovalObject(
    $condition: ExpWaitingApprovalObjectsCondition!
    $sortOption: ExpWaitingApprovalObjectsSortOptions
    $limit: NonNegativeInt!
    $offset: NonNegativeInt!
  ) {
    expWaitingApprovalObjects(condition: $condition, sortOption: $sortOption, limit: $limit, offset: $offset) {
      report {
        expenseReportId
        docSequence
        title
        status
        createdAt
        expenses {
          expenseId
          totalAmountWithVat
        }
        companyEmployee {
          fullname
        }
      }
      request {
        expenseRequestId
        docSequence
        status
        title
        totalAmount
        requestedAt
        companyEmployee {
          fullname
        }
        createdAt
        closedAt
        closedBy
        expenseRequestType
        isClosed
        updatedAt
      }
    }
  }
`;

const useWaitingApprovalObjects = ({ condition, sortOption, limit, offset }) => {
  const [fetchData, result] = useLazyQuery(GET_WAITING_APPROVAL_OBJECTS, {
    variables: {
      condition,
      sortOption,
      limit,
      offset,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  return {
    ...result,
    data: result.data?.expWaitingApprovalObjects ?? [],
    fetchData,
  };
};

export default useWaitingApprovalObjects;
