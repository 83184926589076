import React, { FC } from "react";
import { GestureResponderEvent, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { Control, Controller, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

import { AppText } from "components";
import { CONSTANTS } from "constants/constants";
import { Colors, Fonts } from "theme";
interface ButtonInputProps {
  name?: string;
  control?: Control<any>;
  rules?: Record<string, unknown>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  style?: StyleProp<ViewStyle>;
  onPress?: (event: GestureResponderEvent) => void;
  label?: string;
  placeholder?: string;
}
const ButtonInput: FC<ButtonInputProps> = ({ name, control, rules, error, style, onPress, label, placeholder }) => {
  return (
    <Controller
      name={name as never}
      control={control}
      rules={rules}
      render={({ field: { value } }) => (
        <View style={style}>
          <TouchableOpacity onPress={onPress} style={[styles.input, Boolean(error) && { borderColor: Colors.alert50 }]}>
            {Boolean(value) && <AppText style={styles.label}>{label}</AppText>}
            <AppText
              style={styles.categoryValue}
              color={
                error ? Colors.alert50 : value ? CONSTANTS.COMMON.TEXT_INPUT_COLOR : CONSTANTS.COMMON.PLACEHOLDER_COLOR
              }
              numberOfLines={1}
            >
              {value ? value : placeholder}
            </AppText>
          </TouchableOpacity>
          {Boolean(error?.message) && (
            <AppText style={[Fonts.BodySmall, styles.errorText]} color={Colors.alert50}>
              {error?.message}
            </AppText>
          )}
        </View>
      )}
    />
  );
};
export default ButtonInput;

const styles = StyleSheet.create({
  input: {
    height: 48,
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 8,
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 15,
    flexDirection: "row",
  },
  errorText: { marginLeft: 0, marginTop: 4 },
  label: {
    ...Fonts.Caption,
    position: "absolute",
    top: -10,
    left: 16,
    backgroundColor: Colors.white,
    color: CONSTANTS.COMMON.PLACEHOLDER_COLOR,
  },
  categoryValue: { ...Fonts.BodyLarge, flex: 1, marginRight: 10 },
});
