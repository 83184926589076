import { forwardRef, ReactElement, ReactNode, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Keyboard, StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";

import {
  AppText,
  BottomSheetModalCustom,
  BottomSheetModalCustomMethods,
  BottomSheetScrollViewModalCustom,
  Button,
} from "components";
import { Colors, Fonts } from "theme";
import {
  BizziBotInfo,
  BizziBotWarning,
  ErrorIcon,
  SendIcon,
  SpinnerLoadingIcon,
  SuccessIcon,
} from "assets/images/svg/icons";
import { isIphoneX } from "constants/Layout";

export interface AlertNotificationHandle {
  info: (params: InfoParams) => Promise<"onConfirm">;
  confirm: (params: ConfirmParams) => Promise<"onConfirm" | "onCancel">;
  warning: (params: WarningParams) => Promise<"onConfirm" | "onCancel">;
  loading: (title?: string) => void;
  success: (params: SuccessParams) => void;
  error: (params: ErrorParams) => void;
  close: () => void;
  open: () => void;
}

enum AlertType {
  INFO = "info",
  LOADING = "loading",
  CONFIRM = "confirm",
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
}

interface InfoParams {
  icon?: ReactElement | null;
  title?: string;
  description?: ReactNode;
  confirmText?: string;
  onConfirm?: () => void;
  disableClose?: boolean;
}

interface ConfirmParams {
  icon?: ReactElement | null;
  title?: string;
  description?: ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  disableClose?: boolean;
}

interface WarningParams {
  icon?: ReactElement | null;
  title?: string;
  description?: ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  countDownConfirm?: number;
  onCancel?: () => void;
  disableClose?: boolean;
}

interface SuccessParams {
  icon?: ReactElement | null;
  title?: string;
  description?: string;
  disableClose?: boolean;
  confirmText?: string;
  onConfirm?: () => void;
}

interface ErrorParams {
  icon?: ReactElement | null;
  title?: string;
  description?: ReactNode | any;
  cancelText?: string;
  onTry?: () => void;
  onCancel?: () => void;
}

interface AlertNotificationProps {
  autoHeight?: boolean;
  goBackIsDismiss?: boolean;
  onDismiss?: () => void;
}

const promisify = <TP, TCBKey extends keyof TP>(callbackNames: TCBKey[], fn: (params: TP) => void) => {
  return (params: TP) =>
    new Promise<TCBKey>((resolve) => {
      for (const callbackName of callbackNames) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const oldCallback = params[callbackName] as any;
        params[callbackName] = ((...args) => {
          if (oldCallback && !oldCallback.__promised) {
            oldCallback.__promised = true;
            oldCallback(...args);
          }
          resolve(callbackName);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any;
      }
      fn(params);
    });
};

const MODAL_HEIGHT = isIphoneX() ? 395 : 380;
const AlertNotification = forwardRef<AlertNotificationHandle, AlertNotificationProps>(
  ({ autoHeight = true, goBackIsDismiss = false, onDismiss }, ref) => {
    const { t } = useTranslation("app/components/AlertNotification/AlertNotification");
    useImperativeHandle(ref, () => ({
      confirm: promisify(
        ["onConfirm", "onCancel"],
        ({
          icon = <SendIcon />,
          onConfirm,
          onCancel,
          title = "",
          description = "",
          confirmText = t("confirm"),
          cancelText = t("close"),
          disableClose = false,
        }: ConfirmParams) => {
          bottomSheetRef?.current?.present();
          setCurrentType(AlertType.CONFIRM);
          setIcon(icon);
          setTitle(title);
          setDescription(description);
          setConfirmText(confirmText);
          setCancelText(cancelText);
          confirmFunc.current = onConfirm;
          cancelFunc.current = onCancel;
          setDisableClose(disableClose);
        }
      ),
      info: promisify(
        ["onConfirm"],
        ({
          icon = <BizziBotInfo />,
          onConfirm,
          title = "",
          description = "",
          confirmText = t("confirm"),
          disableClose = false,
        }: ConfirmParams) => {
          bottomSheetRef?.current?.present();
          setCurrentType(AlertType.INFO);
          setIcon(icon);
          setTitle(title);
          setDescription(description);
          setConfirmText(confirmText);
          confirmFunc.current = onConfirm;
          setDisableClose(disableClose);
        }
      ),
      warning: promisify(
        ["onConfirm", "onCancel"],
        ({
          icon = <BizziBotWarning />,
          onConfirm,
          onCancel,
          title = "",
          description = "",
          confirmText = "",
          cancelText = "",
          countDownConfirm = 0,
          disableClose = false,
        }: WarningParams) => {
          Keyboard.dismiss();
          bottomSheetRef?.current?.present();
          setCurrentType(AlertType.WARNING);
          setIcon(icon);
          setTitle(title);
          setDescription(description);
          setConfirmText(confirmText);
          setCancelText(cancelText);
          setCountDownConfirm(countDownConfirm);
          if (countDownConfirm) {
            countDownConfirmButton();
          }
          confirmFunc.current = onConfirm;
          cancelFunc.current = onCancel;
          setDisableClose(disableClose);
        }
      ),
      loading: (title = t("processing")) => {
        bottomSheetRef?.current?.present();
        setCurrentType(AlertType.LOADING);
        setDisableClose(true);
        setTitle(title);
      },
      success: ({
        icon = <SuccessIcon />,
        disableClose,
        onConfirm,
        title = "",
        description = "",
        confirmText = t("finish"),
      }: SuccessParams) => {
        bottomSheetRef?.current?.present();
        setCurrentType(AlertType.SUCCESS);
        setIcon(icon);
        setTitle(title);
        setConfirmText(confirmText);
        setCancelText("");
        setDescription(description);
        confirmFunc.current = onConfirm;
        setDisableClose(Boolean(disableClose));
      },
      error: ({
        icon = <ErrorIcon />,
        onCancel,
        onTry = () => bottomSheetRef?.current?.close(),
        title = "",
        description = "",
        cancelText = t("later"),
      }: ErrorParams) => {
        setCurrentType(AlertType.ERROR);
        setIcon(icon);
        setTitle(title);
        setConfirmText(t("tryAgain"));
        setCancelText(cancelText);
        setDescription(description);
        confirmFunc.current = onTry;
        cancelFunc.current = onCancel;
      },
      close: () => {
        bottomSheetRef?.current?.close();
      },
      open: () => {
        bottomSheetRef?.current?.present();
      },
    }));

    const [icon, setIcon] = useState<ReactElement | null>();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState<ReactNode>("");
    const [confirmText, setConfirmText] = useState("");
    const [cancelText, setCancelText] = useState("");
    const [currentType, setCurrentType] = useState<AlertType>(AlertType.CONFIRM);
    const [disableClose, setDisableClose] = useState<boolean>(false);
    const [countDownConfirm, setCountDownConfirm] = useState(0);
    const bottomSheetRef = useRef<BottomSheetModalCustomMethods>();
    const confirmFunc = useRef<() => void>();
    const cancelFunc = useRef<() => void>();
    const intervalRef = useRef<any>();

    useEffect(() => {
      if (intervalRef.current && !countDownConfirm) {
        clearInterval(intervalRef.current);
      }
    }, [countDownConfirm]);
    useEffect(() => {
      return () => {
        clearInterval(intervalRef.current);
      };
    }, []);
    const countDownConfirmButton = () => {
      intervalRef.current = setInterval(() => {
        setCountDownConfirm((prevState) => prevState - 1);
      }, 1000);
    };
    const handleCancel = () => {
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }
      bottomSheetRef?.current?.close();
      cancelFunc?.current?.();
    };

    const handleConfirm = () => {
      if (currentType === AlertType.SUCCESS) {
        // in the case success --> close modal
        bottomSheetRef?.current?.close();
      }
      confirmFunc?.current?.();
    };
    const handleDismiss = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      onDismiss?.();
    };

    const renderFooter = () => {
      switch (currentType) {
        case AlertType.SUCCESS: {
          return (
            <View style={[styles.buttonContainer, autoHeight ? styles.buttonContainerAutoHeight : {}]}>
              <Button style={[styles.button, styles.fullWidth]} onPress={handleConfirm}>
                {confirmText}
              </Button>
            </View>
          );
        }
        case AlertType.CONFIRM:
        case AlertType.WARNING: {
          return (
            <View style={[styles.buttonContainer, autoHeight ? styles.buttonContainerAutoHeight : {}]}>
              <View style={styles.buttonLeft}>
                <Button type="ghost" onPress={handleCancel}>
                  {cancelText}
                </Button>
              </View>
              <View style={styles.buttonRight}>
                <Button
                  disabled={Boolean(countDownConfirm)}
                  type={currentType === AlertType.WARNING ? "danger" : "primary"}
                  onPress={handleConfirm}
                >
                  {countDownConfirm ? `${confirmText} (${countDownConfirm})` : confirmText}
                </Button>
              </View>
            </View>
          );
        }
        case AlertType.ERROR: {
          return (
            <View style={[styles.buttonContainer, autoHeight ? styles.buttonContainerAutoHeight : {}]}>
              <View style={styles.buttonLeft}>
                <Button type="secondary" onPress={handleCancel}>
                  {cancelText}
                </Button>
              </View>
              <View style={styles.buttonRight}>
                <Button onPress={handleConfirm}>{confirmText}</Button>
              </View>
            </View>
          );
        }
        case AlertType.INFO: {
          return (
            <View
              style={[
                styles.buttonContainer,
                styles.modalContentInfo,
                autoHeight ? styles.buttonContainerAutoHeight : {},
              ]}
            >
              <View style={styles.buttonConfirm}>
                <Button type="primary" style={styles.button} onPress={handleConfirm}>
                  {confirmText}
                </Button>
              </View>
            </View>
          );
        }
        default:
          return null;
      }
    };

    const renderContent = () => {
      switch (currentType) {
        case AlertType.LOADING: {
          return (
            <View style={styles.modalContent}>
              <View style={styles.loadingIcon}>
                <SpinnerLoadingIcon />
              </View>
              <View style={styles.titleContainer}>
                <AppText style={[Fonts.H400, styles.title]}>{title}</AppText>
              </View>
            </View>
          );
        }
        case AlertType.INFO: {
          return (
            <View style={[styles.modalContent, styles.modalContentInfo]}>
              <View style={styles.icon}>{icon}</View>
              <View style={styles.titleContainer}>
                <AppText style={[Fonts.H400, styles.title]}>{title}</AppText>
              </View>
              {Boolean(description) &&
                (typeof description === "string" ? (
                  <AppText style={[Fonts.BodyMedium, styles.description]} color={Colors.grayscale80}>
                    {description}
                  </AppText>
                ) : (
                  description
                ))}
            </View>
          );
        }
        default:
          return (
            <View style={[styles.modalContent, currentType === AlertType.WARNING && styles.modalContentWarning]}>
              <View style={styles.icon}>{icon}</View>
              <View style={styles.titleContainer}>
                <AppText style={[Fonts.H400, styles.title]}>{title}</AppText>
              </View>
              {Boolean(description) &&
                (typeof description === "string" ? (
                  <AppText style={[Fonts.BodyMedium, styles.description]} color={Colors.grayscale80} numberOfLines={6}>
                    {description}
                  </AppText>
                ) : (
                  description
                ))}
            </View>
          );
      }
    };

    return autoHeight ? (
      <BottomSheetScrollViewModalCustom
        minHeight={isIphoneX() ? 364 : 341}
        handleComponent={() => null}
        ref={bottomSheetRef}
        wrapperByScrollView={false}
        onDismiss={handleDismiss}
        enableTouchOutsideToClose={!disableClose}
        enableContentPanningGesture={!disableClose}
      >
        {renderContent()}
        {renderFooter()}
      </BottomSheetScrollViewModalCustom>
    ) : (
      <BottomSheetModalCustom
        goBackIsDismiss={goBackIsDismiss}
        handleComponent={() => null}
        enableTouchOutsideToClose={!disableClose}
        enableContentPanningGesture={!disableClose}
        ref={bottomSheetRef}
        snapPoints={[MODAL_HEIGHT]}
        footerComponent={renderFooter}
        onDismiss={handleDismiss}
      >
        {renderContent()}
      </BottomSheetModalCustom>
    );
  }
);

export default AlertNotification;

const styles = StyleSheet.create({
  modalContent: {
    flex: 1,
    paddingTop: 35,
    paddingHorizontal: 32,
  },
  modalContentWarning: { paddingTop: 20 },
  modalContentInfo: { paddingTop: 20 },
  titleContainer: { alignItems: "center", justifyContent: "center", marginTop: 25, marginBottom: 5 },
  title: { textAlign: "center" },
  description: { marginTop: 8, textAlign: "center", fontSize: 13 },
  buttonContainerAutoHeight: {
    marginTop: 20,
    paddingBottom: 0,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    paddingBottom: isIphoneX() ? 34 : 20,
  },
  button: {},
  buttonRight: { paddingLeft: 10, flex: 1 },
  buttonLeft: { paddingRight: 10, flex: 1 },
  buttonConfirm: { flex: 1 },
  icon: { alignItems: "center" },
  loadingIcon: { alignItems: "center", marginTop: 40 },
  fullWidth: { width: "100%" },
});
