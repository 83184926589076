// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Flex, WhiteSpace } from "@ant-design/react-native";
import { useNavigation, useScrollToTop } from "@react-navigation/native";
import { BOTTOM_BAR_HEIGHT, HEIGHT_WINDOW, SPACING_DEFAULT } from "constants/Layout";
import { useAuth } from "contexts/AuthContext";
import {
  AppText,
  EmptyData,
  FlatListCustom,
  Icon,
  IconCustom,
  Line,
  SearchInput,
  SkeletonListLoading,
  TouchableOpacityCustom,
} from "components";
import { parseOneAddress } from "email-addresses";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { ActivityIndicator, FlatList, RefreshControl, StyleSheet, TouchableOpacity, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import FileIcon from "assets/images/svg/icons/FileIcon";
import PdfIcon from "assets/images/svg/icons/PdfIcon";
import PngIcon from "assets/images/svg/icons/PngIcon";
import XslIcon from "assets/images/svg/icons/XIcon";
import XmlIcon from "assets/images/svg/icons/XmlIcon";
import { EmptyInvoice, SearchNotFoundIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import LanguageStatus from "constants/LanguageStatus";
import dayjs from "dayjs";
import useEmailsQuery from "hooks/emails/useEmailsQuery";
import SCREEN_NAME from "navigation/ScreenName";
import { useTranslation } from "react-i18next";
import { Colors, Fonts } from "theme";
import { EVENT } from "constants/Tracking";
import InvoiceStatus from "../../components/InvoiceStatus";
import { useCopilot } from "react-native-copilot";

const NUMBER_ROWS_PER_PAGE = 10;
const DummyEmailWalkthroughData = require("./DummyEmailWalkthroughData.json");

const SearchEmpty = memo(({ onPress }) => {
  const { t } = useTranslation("app/screens/Inbox/InboxScreen");
  return (
    <>
      <EmptyData icon={<SearchNotFoundIcon />} title={t("search_empty_inbox")} />
      <TouchableOpacity onPress={onPress}>
        <AppText style={[styles.emptyText, { color: Colors.primary50 }]}>{t("remove_search")}</AppText>
      </TouchableOpacity>
    </>
  );
});

const LIST_ICON = {
  xml: XmlIcon,
  pdf: PdfIcon,
  png: PngIcon,
  xsl: XslIcon,
  xslt: XslIcon,
};

const EmailTab = () => {
  const navigation = useNavigation();
  const { bottom } = useSafeAreaInsets();
  const debounceSearchTimeoutId = useRef(null);
  const {
    user,
    company: { company_id: companyId },
  } = useAuth();
  const [keyword, setKeyword] = useState(null);
  const { t, i18n } = useTranslation("app/screens/Inbox/InboxScreen/InboxScreen");
  const flatListRef = useRef<FlatList>(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const defaultVariables = useRef({
    userId: user.id,
    companyId,
    offset: 0,
    limit: NUMBER_ROWS_PER_PAGE,
    keyword: `%%`,
  });
  const { visible: visibleWalkthrough } = useCopilot();

  const { data, loading, refetch, called, networkStatus, fetchMore } = useEmailsQuery({
    variables: defaultVariables.current,
  });

  useScrollToTop(flatListRef);

  const emails = (visibleWalkthrough ? DummyEmailWalkthroughData : data?.emails) ?? [];

  const refetchData = useCallback(() => {
    if (!called) {
      return;
    }
    if (refetch) {
      refetch();
    }
  }, [refetch, called]);

  useEffect(() => {
    if (i18n.language === LanguageStatus.VN) {
      dayjs.locale("vi");
    } else {
      dayjs.locale("en");
    }
  }, [i18n.language]);

  const isCurrentFetchMore = useRef(false);

  const loadMore = () => {
    if (
      loading ||
      isCurrentFetchMore.current ||
      emails?.length <= 0 ||
      emails?.length >= data?.emails_aggregate?.aggregate?.count ||
      emails?.length < NUMBER_ROWS_PER_PAGE
    ) {
      return;
    }
    isCurrentFetchMore.current = true;
    fetchMore?.({
      variables: { offset: emails?.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        setTimeout(() => {
          isCurrentFetchMore.current = false;
        }, 1000);
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          emails: [...prev.emails, ...fetchMoreResult.emails],
          emails_aggregate: fetchMoreResult.emails_aggregate,
        };
      },
    });
  };

  const scrollToTop = useCallback(() => {
    if (flatListRef.current?.scrollToOffset) {
      flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
    }
  }, []);

  const renderItem = ({ item, index }) => {
    const { subject, text, created_at, email_attachments, from, sender } = item;

    const attachment = email_attachments && email_attachments[0];
    const attachment2 = email_attachments && email_attachments[1];
    const ext = attachment?.file_name.substr(attachment.file_name.lastIndexOf(".") + 1) ?? null;
    const ext2 = attachment2?.file_name?.substr(attachment2?.file_name.lastIndexOf(".") + 1) ?? null;
    const IconCustom1 = ext && LIST_ICON[ext] ? LIST_ICON[ext] : FileIcon;
    const IconCustom2 = ext2 && LIST_ICON[ext2] ? LIST_ICON[ext2] : FileIcon;

    return (
      <TouchableOpacityCustom
        eventName={EVENT.EMAIL.TAP_VIEW}
        style={styles.itemContainer}
        onPress={() => navigation.navigate(SCREEN_NAME.EmailDetailScreen, item)}
      >
        <View style={styles.flex}>
          <WhiteSpace size="sm" />
          <WhiteSpace size="sm" />
          <Flex style={styles.flex}>
            <Flex style={styles.flex}>
              <InvoiceStatus styles={styles} status={item.status} isDuplicated={item.is_duplicated} t={t} />
            </Flex>
            <AppText style={styles.date}>{dayjs(created_at).format(CONSTANTS.FORMAT_DATE_TIME)}</AppText>
          </Flex>
          <WhiteSpace size="sm" />
          <WhiteSpace size="sm" />
          <Flex justify="between" align="center">
            <Flex style={{ flex: 2 }}>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale80} numberOfLines={1}>
                {sender ? parseOneAddress(sender)?.name : from}
              </AppText>
            </Flex>
          </Flex>
          <WhiteSpace size="sm" />

          <AppText style={Fonts.H300} numberOfLines={1} bold>
            {subject || `(${t("no_subject")})`}
          </AppText>

          <WhiteSpace size="sm" />
          {!!text?.trim() && (
            <>
              <AppText style={[Fonts.BodyMedium, styles.content]} color={Colors.grayscale80} numberOfLines={1}>
                {text}
              </AppText>
            </>
          )}

          <WhiteSpace size="sm" />
          <WhiteSpace size="sm" />
          {email_attachments && email_attachments.length > 0 && (
            <Flex>
              <Flex style={styles.attachment}>
                {IconCustom1 && <IconCustom1 size={14} />}
                <AppText style={[Fonts.BodySmall, styles.smallMarginLeft]}>
                  {attachment.file_name?.slice(0, 10)}...
                </AppText>
              </Flex>

              {!!attachment2 && (
                <Flex style={[styles.attachment, styles.smallMarginLeft]}>
                  {IconCustom2 && <IconCustom2 size={14} />}
                  <AppText style={[Fonts.BodySmall, styles.smallMarginLeft]}>
                    {attachment2.file_name?.slice(0, 10)}...
                  </AppText>
                </Flex>
              )}
              {email_attachments?.length > 2 && (
                <AppText style={[Fonts.BodySmall, styles.emailAttachmentStyle]}>
                  +{email_attachments?.length - 2}
                </AppText>
              )}
            </Flex>
          )}
          <WhiteSpace size="sm" />
          {index !== emails.length - 1 && <Line containerStyle={{ marginTop: 8 }} />}
        </View>
      </TouchableOpacityCustom>
    );
  };

  const search = useCallback(async () => {
    if (keyword) {
      defaultVariables.current = {
        ...defaultVariables.current,
        keyword: `%${keyword || ""}%`,
      };
      refetch(defaultVariables.current);
    }
  }, [refetch, keyword]);

  const ListFooterComponent = () => {
    if (!loading || emails?.length === 0) {
      return null;
    }
    return <ActivityIndicator style={styles.loadingMore} size="small" color={Colors.primary50} />;
  };

  const currentPositionRef = useRef(0);

  const onScroll = useCallback(
    (e) => {
      const newPosition = e.nativeEvent.contentOffset.y;

      if (newPosition > HEIGHT_WINDOW) {
        if (!showScrollTop) {
          setShowScrollTop(true);
        }
      } else if (showScrollTop) {
        setShowScrollTop(false);
      }
      currentPositionRef.current = newPosition;
    },
    [showScrollTop]
  );

  const onResetSearch = useCallback(() => {
    setKeyword(null);
    defaultVariables.current = {
      ...defaultVariables.current,
      keyword: `%%`,
    };
    refetch(defaultVariables.current);
  }, [refetch]);

  const ListEmptyComponent = () => {
    if (loading) {
      return null;
    }
    return called && keyword && !loading ? (
      <SearchEmpty onPress={onResetSearch} t={t} />
    ) : (
      <EmptyData icon={<EmptyInvoice />} title={t("empty_email_title")} description={t("empty_email_description")} />
    );
  };

  const keyExtractor = (item) => item.email_id.toString();

  const onChangeSearch = (text) => {
    setKeyword(text);
    if (debounceSearchTimeoutId.current) {
      clearTimeout(debounceSearchTimeoutId.current);
    }
    debounceSearchTimeoutId.current = setTimeout(() => {
      defaultVariables.current = {
        ...defaultVariables.current,
        keyword: `%${text || ""}%`,
      };
      refetch(defaultVariables.current);
    }, 500);
  };

  return (
    <View style={styles.container}>
      <View style={styles.container}>
        <View style={styles.wrapperSearchInput}>
          <SearchInput
            placeholder={t("search")}
            onChangeText={onChangeSearch}
            autoCapitalize="none"
            returnKeyLabel={t("next")}
            onSubmitEditing={search}
            enablesReturnKeyAutomatically
            returnKeyType="search"
            value={keyword || ""}
            right={
              keyword ? (
                <TouchableOpacity onPress={onResetSearch}>
                  <IconCustom name="cancel" />
                </TouchableOpacity>
              ) : null
            }
          />
        </View>

        <View style={styles.flex}>
          {loading && emails?.length === 0 ? (
            <SkeletonListLoading />
          ) : (
            <FlatListCustom
              estimatedItemSize={140}
              extraData={[t, dayjs]}
              ref={flatListRef}
              data={emails}
              renderItem={renderItem}
              keyExtractor={keyExtractor}
              showsVerticalScrollIndicator={false}
              ListEmptyComponent={ListEmptyComponent}
              refreshControl={
                <RefreshControl tintColor={Colors.primary50} refreshing={false} onRefresh={refetchData} />
              }
              refreshing={networkStatus === 4}
              onEndReached={loadMore}
              onEndReachedThreshold={0.15}
              onScroll={onScroll}
              contentContainerStyle={{ paddingBottom: BOTTOM_BAR_HEIGHT + bottom + SPACING_DEFAULT }}
              ListFooterComponent={ListFooterComponent}
              keyboardShouldPersistTaps="always"
              keyboardDismissMode="on-drag"
            />
          )}
        </View>

        {showScrollTop && (
          <TouchableOpacity style={styles.floatButton} onPress={scrollToTop}>
            <Icon type="Antdesign" name="up" size={15} color={Colors.blue22} />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    flex: 1,
  },

  itemContainer: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: Colors.white,
    shadowColor: Colors.blackColor200,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 0.5,
  },

  date: {
    marginLeft: "auto",
    fontSize: 12,
    lineHeight: 16,
    color: Colors.grey6,
  },
  emptyText: {
    alignSelf: "center",
  },
  attachment: {
    borderColor: Colors.grayscale10,
    borderWidth: 1,
    borderRadius: 20,

    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  floatButton: {
    borderWidth: 1,
    borderColor: Colors.blackColor200,
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    position: "absolute",
    bottom: 20 + BOTTOM_BAR_HEIGHT,
    right: 20,
    height: 40,
    backgroundColor: Colors.white,
    borderRadius: 100,
  },
  smallMarginLeft: {
    marginLeft: 5,
    color: Colors.textColor2,
  },

  wrapperSearchInput: {
    paddingHorizontal: 20,
    paddingVertical: 8,
    backgroundColor: Colors.white,
  },
  emailAttachmentStyle: { marginLeft: 5 },
  loadingMore: { marginTop: 10 },
});

export default EmailTab;
