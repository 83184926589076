import { AppText, CurrencyText, IconCustom } from "components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { Colors, Fonts } from "theme";
import { ExpenseRequestFormValues } from "screens/ExpenseRequest/types";
import { ALLOWANCE_TYPE } from "constants/constants";
import { countNumberOfDays, formatMoney } from "utils";
import { UseFormWatch } from "react-hook-form/dist/types/form";
import { UseFormGetValues } from "react-hook-form";
import dayjs from "dayjs";
import { generateAllowancePurchaseDescription } from "screens/ExpenseRequest/helper";

type AllowanceItemProps = {
  value: ExpenseRequestFormValues["allowances"][0];
  style?: StyleProp<ViewStyle>;
  onDelete: () => void;
  onEdit: () => void;
  travelDate?: ExpenseRequestFormValues["travelDate"];

  watch?: UseFormWatch<ExpenseRequestFormValues>;
  getValues?: UseFormGetValues<ExpenseRequestFormValues>;
};
const AllowanceItem = ({ getValues, value, style, onDelete, onEdit, travelDate, watch }: AllowanceItemProps) => {
  const { t } = useTranslation("app/screens/ExpenseRequest/components/ExpenseRequestForm");
  const numOfDays = countNumberOfDays(travelDate?.fromDate, travelDate?.toDate);
  const isUserRequestAllowance = value?.type === ALLOWANCE_TYPE.USER_REQUEST;
  const fromDateString = dayjs(travelDate?.fromDate)?.toISOString?.();
  const toDateString = dayjs(travelDate?.toDate)?.toISOString?.();
  const hotelBookingFromDate = watch("hotelBookingDate.fromDate")?.toISOString?.();
  const hotelBookingToDate = watch("hotelBookingDate.toDate")?.toISOString?.();

  const description = useMemo(() => {
    if (!value?.type || value?.type === ALLOWANCE_TYPE.USER_REQUEST) {
      return value?.description || t("expense_no_description");
    }
    if (value?.type === ALLOWANCE_TYPE.PERDIEM) {
      return numOfDays > 1
        ? t("per_diem_amounts", { amount: formatMoney(value?.amount / numOfDays), day: numOfDays })
        : t("per_diem_amount", { amount: formatMoney(value?.amount / numOfDays), day: numOfDays });
    }
    if (value?.type === ALLOWANCE_TYPE.BOOKING_FLIGHT) {
      return getValues("flightBooking.isRoundTrip")
        ? `${t("flight_ticket")} ${t("round_trip_label")}`
        : t("flight_ticket");
    }
    if (value?.type === ALLOWANCE_TYPE.BOOKING_BUS) {
      return getValues("transportationBooking.isRoundTrip")
        ? `${t("bus_ticket")} ${t("round_trip_label")}`
        : t("bus_ticket");
    }
    if (value?.type === ALLOWANCE_TYPE.BOOKING_HOTEL) {
      const numOfDays = countNumberOfDays(hotelBookingFromDate, hotelBookingToDate);
      const numOfNights = numOfDays > 0 ? numOfDays - 1 : 0;
      return numOfNights > 1 ? t("hotel_per_nights", { num: numOfNights }) : t("hotel_per_night", { num: numOfNights });
    }
  }, [value, fromDateString, toDateString, hotelBookingFromDate, hotelBookingToDate]);
  return (
    <TouchableOpacity disabled={!isUserRequestAllowance} onPress={onEdit}>
      <View style={[styles.container, style]}>
        <View style={styles.expenseIcon}>
          <IconCustom name="sell" />
        </View>
        <View style={styles.detail}>
          {value?.type === ALLOWANCE_TYPE.PERDIEM ? (
            <AppText style={Fonts.BodySmall} color={Colors.grayscale60} numberOfLines={2}>
              {t("perdiem")}
            </AppText>
          ) : [ALLOWANCE_TYPE.BOOKING_FLIGHT, ALLOWANCE_TYPE.BOOKING_BUS, ALLOWANCE_TYPE.BOOKING_HOTEL].includes(
              value?.type
            ) ? (
            <AppText style={Fonts.BodySmall} color={Colors.grayscale60} numberOfLines={2}>
              {t("limit")}
            </AppText>
          ) : null}
          <View style={styles.detailLine}>
            <View style={styles.flex}>
              <AppText style={Fonts.BodyMedium} numberOfLines={2}>
                {description}
              </AppText>
              <AppText style={Fonts.BodyMedium} numberOfLines={3}>
                {generateAllowancePurchaseDescription(value as any)}
              </AppText>
            </View>

            <View style={styles.info}>
              <CurrencyText style={Fonts.NumericN200}>{value?.amount}</CurrencyText>
              {isUserRequestAllowance && (
                <TouchableOpacity style={styles.removeIcon} onPress={onDelete}>
                  <IconCustom name="minus" />
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default AllowanceItem;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  detail: {
    flex: 1,
    paddingLeft: 8,
  },
  detailLine: {
    flexDirection: "row",
    gap: 8,
  },
  info: {
    alignItems: "flex-end",
  },
  removeIcon: {
    marginTop: 4,
  },
  flex: { flex: 1 },
  expenseIcon: {
    width: 48,
    height: 48,
    borderRadius: 48 / 2,
    backgroundColor: Colors.primary0,
    justifyContent: "center",
    alignItems: "center",
  },
});
