import { StyleSheet, TouchableOpacity, View } from "react-native";
import {
  AppText,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
  Button,
  IconCustom,
} from "components";
import React, { FC, useRef, useState } from "react";
import { Colors, Fonts } from "theme";
import { ArrowRightIcon } from "assets/images/svg/icons";
import { CONSTANTS } from "constants/constants";
import { isIphoneX } from "constants/Layout";
import FilterInput from "./FilterInput";
import dayjs from "dayjs";
import i18n from "i18next";
import LanguageStatus from "constants/LanguageStatus";
import { DatePickerModal } from "react-native-paper-dates";
import { CalendarDate } from "react-native-paper-dates/lib/typescript/Date/Calendar";
import { useTranslation } from "react-i18next";
import { RangeChange } from "react-native-paper-dates/src/Date/Calendar";
import RadioButton from "components/RadioButton";

interface SelectDateProps {
  title?: string;
  setValue?: (label: string, formDate: string, toDate: string) => void;
  labelDateValue?: string;
}
const SelectDate: FC<SelectDateProps> = ({ labelDateValue, title, setValue }) => {
  const bottomSheetRef = useRef<BottomSheetScrollViewModalCustomMethods>();
  const { t } = useTranslation("app/components/AdvanceSearch");
  const [currentType, setCurrentType] = useState("");
  const [visibleModalSelectDay, setVisibleModalSelectDay] = useState(false);
  const [startDateCustom, setStartDateCustom] = useState<CalendarDate>();
  const [endDateCustom, setEndDateCustom] = useState<CalendarDate>();
  const TIME_OPTIONS = [
    { title: t("last_7_days"), value: "last_7_days" },
    { title: t("last_30_days"), value: "last_30_days" },
    { title: t("last_6_months"), value: "last_6_months" },
    { title: t("last_9_months"), value: "last_9_months" },
    { title: t("last_1_year"), value: "last_1_year" },
  ];
  const handleSelectType = (type) => () => {
    setCurrentType(type);
  };
  const handleClearFilter = () => {
    bottomSheetRef?.current?.close();
    if (currentType) {
      setCurrentType("");
      setValue("", null, null);
      setEndDateCustom(null);
      setStartDateCustom(null);
    }
  };
  const handleApplyFilter = () => {
    bottomSheetRef?.current?.close();
    let fromDate, toDate;
    switch (currentType) {
      case "last_7_days": {
        fromDate = dayjs().subtract(7, "day").startOf("day");
        toDate = dayjs().endOf("day");
        break;
      }
      case "last_30_days": {
        fromDate = dayjs().subtract(30, "day").startOf("day");
        toDate = dayjs().endOf("day");
        break;
      }
      case "last_6_months": {
        fromDate = dayjs().subtract(5, "month").startOf("month");
        toDate = dayjs().endOf("month");
        break;
      }
      case "last_9_months": {
        fromDate = dayjs().subtract(8, "month").startOf("month");
        toDate = dayjs().endOf("month");
        break;
      }
      case "last_1_year": {
        fromDate = dayjs().startOf("year");
        toDate = dayjs().endOf("year");
        break;
      }
      default: {
        // in the case customize
        fromDate = startDateCustom ? dayjs(startDateCustom).startOf("day") : undefined;
        toDate = endDateCustom ? dayjs(endDateCustom).endOf("day") : undefined;
      }
    }
    const currentTimeOption = TIME_OPTIONS?.find((i) => i.value === currentType);
    let label = currentTimeOption
      ? currentTimeOption.title
      : `${dayjs(fromDate).format(CONSTANTS.FORMAT_DAY)} - ${dayjs(toDate).format(CONSTANTS.FORMAT_DAY)}`;
    if (fromDate.isSame(toDate, "day")) {
      label = dayjs(fromDate).format(CONSTANTS.FORMAT_DAY);
    }
    setValue(label, dayjs(fromDate).toISOString(), dayjs(toDate).toISOString());
  };
  const onConfirmSelectDate: RangeChange = (date) => {
    const { startDate, endDate } = date;
    setCurrentType("customize");
    setStartDateCustom(startDate || new Date());
    setEndDateCustom(endDate || new Date());
    setVisibleModalSelectDay(false);
  };
  return (
    <>
      <FilterInput
        title={title}
        value={labelDateValue}
        onPress={() => bottomSheetRef?.current?.present()}
        onClear={handleClearFilter}
      />
      <BottomSheetScrollViewModalCustom wrapperByScrollView={false} ref={bottomSheetRef} title={title}>
        {TIME_OPTIONS?.map((item) => {
          const isChecked = currentType === item?.value; ///currentValue?.find((i) => i?.value === item?.value);
          return (
            <View key={item?.value}>
              <TouchableOpacity style={styles.selectItem} onPress={handleSelectType(item.value)}>
                <AppText style={[Fonts.BodyMedium, styles.flex]}>{item?.title}</AppText>
                <RadioButton checked={isChecked} />
              </TouchableOpacity>
              <View style={styles.divider} />
            </View>
          );
        })}
        <TouchableOpacity style={styles.customizeSelectItem} onPress={() => setVisibleModalSelectDay(true)}>
          <AppText style={Fonts.BodyMedium} color={Colors.primary50}>
            {t("customize")}
          </AppText>
          {currentType === "customize" && startDateCustom && (
            <View style={styles.dateCustom}>
              <AppText style={Fonts.BodySmall} color={Colors.grayscale60}>{`${dayjs(startDateCustom).format(
                CONSTANTS.FORMAT_DAY
              )} - ${dayjs(endDateCustom).format(CONSTANTS.FORMAT_DAY)}`}</AppText>
              <View style={{ marginTop: 1 }}>
                <IconCustom name="arrow-forward-ios" width={14} height={14} />
              </View>
            </View>
          )}
        </TouchableOpacity>
        <View style={styles.footer}>
          <Button style={styles.flex} type="secondary" onPress={handleClearFilter}>
            {t("clear_filter")}
          </Button>
          <Button style={styles.flex} onPress={handleApplyFilter} disabled={!currentType}>
            {t("apply")}
          </Button>
        </View>
      </BottomSheetScrollViewModalCustom>
      <DatePickerModal
        locale={i18n.language === LanguageStatus.VN ? "vi" : "en"}
        mode="range"
        visible={visibleModalSelectDay}
        onDismiss={() => setVisibleModalSelectDay(false)}
        startDate={startDateCustom ?? new Date()}
        endDate={endDateCustom ?? new Date()}
        onConfirm={onConfirmSelectDate}
        validRange={{
          endDate: new Date(),
        }}
        saveLabel={t("save")}
        label={t("selected_range")}
        startLabel={t("from_date")}
        endLabel={t("to_date")}
      />
    </>
  );
};
export default SelectDate;
const styles = StyleSheet.create({
  selectItem: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
  },
  flex: { flex: 1 },
  divider: {
    height: 1,
    backgroundColor: Colors.grayscale05,
    marginLeft: CONSTANTS.COMMON.CONTAINER_PADDING,
  },
  footer: {
    flexDirection: "row",
    gap: 8,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    marginTop: 20,
  },
  dateCustom: { flexDirection: "row", gap: 10 },
  customizeSelectItem: {
    paddingVertical: 16,
    paddingHorizontal: CONSTANTS.COMMON.CONTAINER_PADDING,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
