export type HotelReservationData = {
  location: {
    code: string;
    name: string;
    nameEn?: string;
  };
  checkInDate: string;
  checkOutDate: string;
  note?: string;
};

export type TransportData = {
  departure: {
    location: {
      code: string;
      name: string;
      nameEn?: string;
    };
    station?: {
      code: string;
      name: string;
      nameEn?: string;
    };
  };
  arrival: {
    location: {
      code: string;
      name: string;
      nameEn?: string;
    };
    station?: {
      code: string;
      name: string;
      nameEn?: string;
    };
  };
  isRoundTrip: boolean;
  departureDate: string;
  departureNote: string;
  returnDate?: string;
  returnNote?: string;
};

export type BookingPolicy = {
  locationPolicies?: {
    amount: number;
    location: { code: string; name?: string; nameEn?: string };
  }[];
  maxAdvanceBookingDays: number;
  maxSeatLevel: number;
  minAdvanceBookingDays: number;
  travelDay: string;
  type: string;
};

export const getTransformedData = (
  expenseBooking
): {
  bookingSettings: HotelReservationData | TransportData;
  bookingPolicies: BookingPolicy;
} => {
  const { bookingSettings, bookingPolicies } = expenseBooking;

  switch (bookingSettings.__typename) {
    case "ExpFlightBooking": {
      const { departureAirport, arrivalAirport, ...others } = bookingSettings;

      const { location: fromLocation, ...fromAirport } = departureAirport;
      const { location: toLocation, ...toAirport } = arrivalAirport;
      return {
        bookingPolicies: bookingPolicies ? { ...bookingPolicies, travelDay: bookingSettings.departureDate } : null,
        bookingSettings: {
          departure: {
            location: fromLocation,
            station: fromAirport,
          },
          arrival: {
            location: toLocation,
            station: toAirport,
          },
          ...others,
        },
      };
    }
    case "ExpTransportationBooking": {
      const { pickupLocation, dropOffLocation, ...others } = bookingSettings;
      return {
        bookingPolicies: bookingPolicies ? { ...bookingPolicies, travelDay: bookingSettings.departureDate } : null,
        bookingSettings: {
          departure: {
            location: pickupLocation,
          },
          arrival: {
            location: dropOffLocation,
          },
          ...others,
        },
      };
    }
    case "ExpHotelBooking": {
      const { location, ...others } = bookingSettings;
      return {
        bookingPolicies: bookingPolicies ? { ...bookingPolicies, travelDay: bookingSettings.checkInDate } : null,
        bookingSettings: {
          location,
          ...others,
        },
      };
    }
    default:
      return null;
  }
};
