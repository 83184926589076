//screen
import OnboardingScreen from "./screens/Onboarding/OnboardingScreen.lang.json";
import OnboardingStep1 from "./screens/Onboarding/Step1.lang.json";
import OnboardingStep2 from "./screens/Onboarding/Step2.lang.json";
import OnboardingStep3 from "./screens/Onboarding/Step3.lang.json";
import OnboardingStep4 from "./screens/Onboarding/Step4.lang.json";
import AccountDeletedScreen from "./screens/Auth/AccountDeletedScreen.lang.json";

//register-onboarding
import RegisterOnboardingScreen from "./screens/RegisterOnboarding/RegisterOnboardingScreen.lang.json";
import RegisterStatus from "./screens/RegisterOnboarding/RegisterStatus.lang.json";
import RegisterOTPScreen from "./screens/RegisterOnboarding/RegisterOTPScreen.lang.json";
import RegisterPasswordScreen from "./screens/RegisterOnboarding/RegisterPasswordScreen.lang.json";
import RegisterCompanyScreen from "./screens/RegisterOnboarding/RegisterCompanyScreen.lang.json";
import RegisterInviteColleagueScreen from "./screens/RegisterOnboarding/RegisterInviteColleagueScreen.lang.json";
import RegisterSetupCompanyScreen from "./screens/RegisterOnboarding/RegisterSetupCompanyScreen.lang.json";
import RegisterWarningEnabledExpense from "./screens/RegisterOnboarding/RegisterWarningEnabledExpense.lang.json";

import RegisterInformScreen from "./screens/RegisterOnboarding/RegisterInformScreen.lang.json";

import LoginLang from "./screens/Auth/Login.lang.json";
import LoginScreen from "./screens/Auth/LoginScreen.lang.json";
import LoginOTPScreen from "./screens/Auth/LoginOTPScreen.lang.json";
import NewCompanyScreen from "./screens/Auth/NewCompanyScreen.lang.json";

import ForgotPasswordScreen from "./screens/Auth/ForgotPasswordScreen.lang.json";
import OTPScreen from "./screens/Auth/OTPScreen.lang.json";
import VerifyCompanyScreen from "./screens/Auth/VerifyCompanyScreen.lang.json";
import ExpenseSearchScreen from "./screens/InvoicesTab/ExpenseSearchScreen.lang.json";
import ReceivedInvoicesTab from "./screens/InvoicesTab/ReceivedInvoicesTab.lang.json";

import NewPasswordScreen from "./screens/Auth/NewPasswordScreen.lang.json";
import RegisterScreen from "./screens/Auth/RegisterScreen.lang.json";
import InputNewEmail from "./screens/Auth/InputNewEmail.lang.json";
import NewEmployeeInfo from "./screens/Auth/NewEmployeeInfo.lang.json";

import HomeScreen from "./screens/HomeScreen.lang.json";
import ExpensesScreen from "./screens/InvoicesTab/ExpensesScreen.lang.json";
import WaitForInvoiceExpenseTab from "./screens/InvoicesTab/WaitForInvoiceExpenesTab.lang.json";
import ReadyExpensesTab from "./screens/InvoicesTab/ReadyExpensesTab.lang.json";
import FilterExpenses from "./screens/InvoicesTab/FilterExpenses.lang.json";
import NewExpenseScreen from "./screens/InvoicesTab/NewExpenseScreen.lang.json";
import EditExpenseScreen from "./screens/InvoicesTab/EditExpenseScreen.lang.json";
import ExpenseDetailScreen from "./screens/InvoicesTab/ExpenseDetailScreen.lang.json";
import FilterInvoices from "./screens/InvoicesTab/FilterInvoices.lang.json";

import ActivityReportScreen from "./screens/ReportTab/ActivityReportScreen.lang.json";
import ApprovalReportScreen from "./screens/ReportTab/ApprovalReportScreen.lang.json";
import ApprovedTab from "./screens/ReportTab/ApprovedTab.lang.json";
import EditReportScreen from "./screens/ReportTab/EditReportScreen.lang.json";
import ReportsTab from "./screens/ReportTab/ReportsTab.lang.json";
import RequestChooser from "./screens/ReportTab/RequestChooser.lang.json";
import ReportsScreen from "./screens/ReportTab/ReportsScreen.lang.json";
import ReportItem from "./screens/ReportTab/ReportItem.lang.json";
import ReportDetailScreen from "./screens/ReportTab/ReportDetailScreen.lang.json";
import ListExpenseComponent from "./screens/ReportTab/ListExpenseComponent.lang.json";
import ItemRequestChooser from "./screens/ReportTab/ItemRequestChooser.lang.json";
import ItemExpenseChooser from "./screens/ReportTab/ItemExpenseChooser.lang.json";
import GroupExpenseItemInList from "./screens/ReportTab/GroupExpenseItemInList.lang.json";
import FilterReport from "./screens/ReportTab/FilterReport.lang.json";
import ExpenseChooserGroupBy from "./screens/ReportTab/ExpenseChooserGroupBy.lang.json";
import ExpenseChooser from "./screens/ReportTab/ExpenseChooser.lang.json";
import NewReportScreen from "./screens/ReportTab/NewReportScreen.lang.json";
import ReportForm from "./screens/ReportTab/ReportForm/ReportForm.lang.json";
import ReportHeader from "./screens/ReportTab/ReportForm/ReportHeader.lang.json";
import RejectedSection from "./screens/ReportTab/ReportForm/RejectedSection.lang.json";
import ReportList from "./screens/ReportTab/ReportForm/ReportList.lang.json";
import ReportFooter from "./screens/ReportTab/ReportForm/ReportFooter.lang.json";
import ItemListReport from "./screens/ReportTab/ReportForm/ItemListReport.lang.json";
import HistoryReportScreen from "./screens/ReportTab/HistoryReportScreen.lang.json";

// TLN SCreen
import TLNClaimedExpenses from "./screens/TLN/InvoicesTab/ClaimedExpenses.lang.json";
import TLNUnClaimedExpense from "./screens/TLN/InvoicesTab/UnClaimedExpense.lang.json";
import TLNExpenseScreen from "./screens/TLN/InvoicesTab/ExpenseScreen.lang.json";
import TLNSendClaimedSuccess from "./screens/TLN/InvoicesTab/SendClaimedSuccess.lang.json";

//
import InboxItemDetailScreen from "./screens/ProfileTab/InboxItemDetailScreen.lang.json";
import InboxScreen from "./screens/ProfileTab/InboxScreen.lang.json";
import LocalAuthenticationScreen from "./screens/ProfileTab/LocalAuthenticationScreen.lang.json";
import ProfileScreen from "./screens/ProfileTab/ProfileScreen.lang.json";
import UpdateProfileScreen from "./screens/ProfileTab/UpdateProfileScreen.lang.json";
import MDLZUpdateProfileScreen from "./screens/MDLZ/ProfileTab/UpdateProfileScreen.lang.json";
import AboutScreen from "./screens/ProfileTab/AboutScreen.lang.json";
import NotificationScreen from "./screens/ProfileTab/NotificationScreen.lang.json";
import SelectLanguageScreen from "./screens/ProfileTab/SelectLanguageScreen.lang.json";
import ChangePasswordScreen from "./screens/ProfileTab/ChangePasswordScreen.lang.json";

import CompanyDetailScreen from "./screens/CompanyTab/CompanyDetailScreen.lang.json";
import CompanyFormScreen from "./screens/CompanyTab/CompanyFormScreen.lang.json";
import CompanyScreen from "./screens/CompanyTab/CompanyScreen.lang.json";
import CompanyFormModal from "./screens/CompanyTab/CompanyFormModal.lang.json";

import InboxTab from "./screens/InboxTab/InboxTab.lang.json";
import ImageBrowserScreen from "./screens/ImageBrowserScreen.lang.json";

import CameraScreen from "./screens/CameraScreen/CameraScreen.lang.json";
import ReviewImage from "./screens/CameraScreen/ReviewImage.lang.json";

// request
import OtherRequestForm from "./screens/Customization/DeprecatedUI/Request/RequestForm/OtherRequestForm.lang.json";
import TravelRequestForm from "./screens/Request/RequestForm/TravelRequestForm.lang.json";
import RequestAttachments from "./screens/Request/RequestForm/Attachments.lang.json";
import RequestFooterButton from "./screens/Request/RequestForm/FooterButton.lang.json";
import RequestCategoryItems from "./screens/Request/RequestForm/RequestCategoryItems.lang.json";
import ExpenseAllowanceItem from "./screens/Request/RequestForm/ExpenseAllowanceItem.lang.json";
import RequestForm from "./screens/Request/RequestForm/RequestForm.lang.json";
import AddExpenseToRequestScreen from "./screens/Request/AddExpenseToRequestScreen.lang.json";
import DetailRequestScreen from "./screens/Request/DetailRequestScreen.lang.json";
import EditRequestScreen from "./screens/Request/EditRequestScreen.lang.json";
import FilterRequest from "./screens/Request/FilterRequest.lang.json";
import ListRequestApprovalScreen from "./screens/Request/ListRequestApprovalScreen.lang.json";
import ListRequestHistoryScreen from "./screens/Request/ListRequestHistoryScreen.lang.json";
import ListRequestScreen from "./screens/Request/ListRequestScreen.lang.json";
import ModalExpenseChooserRequest from "./screens/Request/ModalExpenseChooser.lang.json";
import ModalTypeOfRequest from "./screens/Request/ModalTypeOfRequest.lang.json";
import NewRequestScreen from "./screens/Request/NewRequestScreen.lang.json";
import RequestItem from "./screens/Request/RequestItem.lang.json";
import RequestTab from "./screens/Request/RequestTab.lang.json";
import CategoryRequestFilter from "./screens/Request/RequestForm/CategoryRequestFilter.lang.json";
import PortalCancelExpenseRequest from "./screens/Request/PortalCancelExpenseRequest.lang.json";
import PortalCancelExpenseReport from "./screens/ReportTab/PortalCancelExpenseReport.lang.json";
import ExpenseRequestStatusIndicator from "./screens/Request/ExpenseRequestStatusIndicator.lang.json";

// address
import SuggestionAddressScreen from "./screens/SuggestionAddress/SuggestionAddressScreen.lang.json";
import NearByAddressScreen from "./screens/SuggestionAddress/NearByAddressScreen.lang.json";
import SearchLocationHistory from "./screens/SuggestionAddress/History.lang.json";

// layouts
import TeamIdChooser from "./layouts/TeamIdChooser.lang.json";
import PasswordInputFieldLayout from "./layouts/PasswordInputField.lang.json";
import EmptyInvoiceList from "./layouts/EmptyInvoiceList.lang.json";
import ExpenseItem from "./layouts/ExpenseItem.lang.json";
import RangeDate from "./layouts/RangeDate.lang.json";
import ExpenseForm from "./layouts/ExpenseForm/ExpenseForm.lang.json";
import InvoiceTypeChooser from "./layouts/InvoiceTypeChooser.lang.json";
import InputRequestCategory from "./layouts/InputRequestCategory.lang.json";
import ExpenseCategoryChooser from "./layouts/ExpenseCategoryChooser.lang.json";
import AdditionalData from "./layouts/ExpenseForm/AdditionalData.lang.json";
import InvoiceChooser from "./layouts/ExpenseForm/InvoiceChooser.lang.json";
import InvoiceItem from "./layouts/ExpenseForm/InvoiceItem.lang.json";
import DatetimePickerField from "./layouts/DatetimePickerField.lang.json";
import InputExpenseCategory from "./layouts/InputExpenseCategory.lang.json";
import InputExpenseRequestCategory from "./layouts/InputExpenseRequestCategory.lang.json";
import InputLocation from "./layouts/InputLocation.lang.json";
import InputSelectRangeDate from "./layouts/InputSelectRangeDate.lang.json";
import InvoiceItemInList from "./layouts/InvoiceItemInList.lang.json";
import ExpenseRequestItem from "./layouts/ExpenseForm/ExpenseRequestItem.lang.json";
import ImageBrowser from "./layouts/ImageBrower/ImageBrowser.lang.json";
import ImageUploadField from "./layouts/ImageUploadField/ImageUploadField.lang.json";
import ItemTravel from "./layouts/request/ItemTravel.lang.json";
import RequestInfo from "./layouts/request/RequestInfo.lang.json";
import PasswordRules from "./layouts/PasswordRules.lang.json";
import SelectedApprovalModal from "./layouts/SelectedApprovalModal.lang.json";
import InputApprovalList from "./layouts/InputApprovalList.lang.json";
import ApprovalTimeLine from "./layouts/ApprovalTimeLine.lang.json";
import DateTypeChooser from "./layouts/DateTypeChooser.lang.json";
import InputVATRate from "./layouts/InputVATRate.lang.json";

// layouts - dashboard
import DashboardLayout from "./layouts/Dashboard/Dashboard.lang.json";
import CategoryChartDashboardLayout from "./layouts/Dashboard/CategoryChart.lang.json";
import DepartmentChartDashboardLayout from "./layouts/Dashboard/DepartmentChart.lang.json";
import ProviderChartDashboardLayout from "./layouts/Dashboard/ProviderChart.lang.json";
import TrendChartDashboardLayout from "./layouts/Dashboard/TrendChart.lang.json";

// navigation
import AppStackNavigation from "./navigation/AppStack.lang.json";
import HomeBottomTabNavigator from "./navigation/HomeBottomTabNavigator.lang.json";

// components
import FullPageErrorFallback from "./components/Lib/FullPageErrorFallback.lang.json";

// contexts
import UnprovidedInvoiceContext from "./contexts/UnprovidedInvoiceContext.lang.json";

// hooks
import useApprovalPerson from "./hooks/useApprovalPerson.lang.json";
import useEnrolledLocalAuth from "./hooks/useEnrolledLocalAuth.lang.json";

// LSP
import ReportHeaderLSP from "./screens/Customization/LSP/Request/components/ReportForm/ReportHeader.lang.json";
import RequestItemLSP from "./screens/Customization/LSP/Request/components/RequestItem.lang.json";
import TypeofPaymentChooserLSP from "./screens/Customization/LSP/Request/components/ReportForm/TypeofPaymentChooser.lang.json";
import TypeofRequestChooserLSP from "./screens/Customization/LSP/Request/components/ReportForm/TypeofRequestChooser.json";
import FormContainerLSP from "./screens/Customization/LSP/Request/components/FormContainer.lang.json";
import NewRequestLSP from "./screens/Customization/LSP/Request/NewRequest.lang.json";
import EditRequestLSP from "./screens/Customization/LSP/Request/EditRequest.lang.json";

import AdvanceDetailLSP from "./screens/Customization/LSP/Request/components/RequestDetail/AdvanceDetail.lang.json";
import ReimburseDetailLSP from "./screens/Customization/LSP/Request/components/RequestDetail/ReimburseDetail.lang.json";
import ReimburseApprovalDetailLSP from "./screens/Customization/LSP/Request/components/ApprovalRequestScreen/ReimburseApprovalDetail.lang.json";
import AdvanceApprovalDetailLSP from "./screens/Customization/LSP/Request/components/ApprovalRequestScreen/AdvanceApprovalDetail.lang.json";
import AdvanceItemLSP from "./screens/Customization/LSP/Request/components/ClearingForm/AdvanceItem.lang.json";
import ClearingListRequestLSP from "./screens/Customization/LSP/Request/components/ClearingForm/ClearingListRequest.lang.json";
import AdvanceChooserModalLSP from "./screens/Customization/LSP/Request/components/AdvanceChooserModal/AdvanceChooserModal.lang.json";

import useInvoiceValidationResult from "./hooks/useInvoiceValidationResult.lang.json";
import ModalTransferInvoiceEmployee from "./screens/InvoicesTab/ModalTransferInvoiceEmployee.lang.json";
import ModalTransferInvoiceWarning from "./screens/InvoicesTab/ModalTransferInvoiceWarning.lang.json";
import ModalDeleteInvoice from "./screens/InvoicesTab/ModalDeleteInvoice.lang.json";

const vi = {
  "contexts/UnprovidedInvoiceContext": UnprovidedInvoiceContext,
  "hooks/useApprovalPerson": useApprovalPerson,
  "hooks/useEnrolledLocalAuth": useEnrolledLocalAuth,

  "components/Lib/FullPageErrorFallback": FullPageErrorFallback,

  "screens/Onboarding/OnboardingScreen": OnboardingScreen,
  "screens/Onboarding/Step1": OnboardingStep1,
  "screens/Onboarding/Step2": OnboardingStep2,
  "screens/Onboarding/Step3": OnboardingStep3,
  "screens/Onboarding/Step4": OnboardingStep4,

  "screens/RegisterOnboarding/RegisterOnboardingScreen": RegisterOnboardingScreen,
  "screens/RegisterOnboarding/RegisterStatus": RegisterStatus,
  "screens/RegisterOnboarding/RegisterOTPScreen": RegisterOTPScreen,
  "screens/RegisterOnboarding/RegisterPasswordScreen": RegisterPasswordScreen,
  "screens/RegisterOnboarding/RegisterCompanyScreen": RegisterCompanyScreen,
  "screens/RegisterOnboarding/RegisterInviteColleagueScreen": RegisterInviteColleagueScreen,
  "screens/RegisterOnboarding/RegisterSetupCompanyScreen": RegisterSetupCompanyScreen,
  "screens/RegisterOnboarding/RegisterInformScreen": RegisterInformScreen,

  "screens/Auth/Login": LoginLang,
  "screens/Auth/LoginScreen": LoginScreen,
  "screens/Auth/LoginOTPScreen": LoginOTPScreen,
  "screens/Auth/ForgotPasswordScreen": ForgotPasswordScreen,
  "screens/Auth/NewPasswordScreen": NewPasswordScreen,
  "screens/Auth/OTPScreen": OTPScreen,
  "screens/Auth/RegisterScreen": RegisterScreen,
  "screens/Auth/InputNewEmail": InputNewEmail,
  "screens/Auth/NewCompanyScreen": NewCompanyScreen,
  "screens/Auth/VerifyCompanyScreen": VerifyCompanyScreen,
  "screens/Auth/NewEmployeeInfo": NewEmployeeInfo,
  "screens/Auth/AccountDeletedScreen": AccountDeletedScreen,

  "screens/HomeScreen": HomeScreen,
  "screens/InvoicesTab/ExpensesScreen": ExpensesScreen,
  "screens/InvoicesTab/WaitForInvoiceExpenesTab": WaitForInvoiceExpenseTab,
  "screens/InvoicesTab/ReadyExpensesTab": ReadyExpensesTab,
  "screens/InvoicesTab/FilterExpenses": FilterExpenses,
  "screens/InvoicesTab/NewExpenseScreen": NewExpenseScreen,
  "screens/InvoicesTab/EditExpenseScreen": EditExpenseScreen,
  "screens/InvoicesTab/ExpenseDetailScreen": ExpenseDetailScreen,
  "screens/InvoicesTab/ExpenseSearchScreen": ExpenseSearchScreen,
  "screens/InvoicesTab/FilterInvoices": FilterInvoices,
  "screens/InvoicesTab/ReceivedInvoicesTab": ReceivedInvoicesTab,

  "screens/ReportTab/ActivityReportScreen": ActivityReportScreen,
  "screens/ReportTab/ApprovalReportScreen": ApprovalReportScreen,
  "screens/ReportTab/ApprovedTab": ApprovedTab,
  "screens/ReportTab/EditReportScreen": EditReportScreen,
  "screens/ReportTab/ReportsTab": ReportsTab,
  "screens/ReportTab/RequestChooser": RequestChooser,
  "screens/ReportTab/ReportsScreen": ReportsScreen,
  "screens/ReportTab/ReportItem": ReportItem,
  "screens/ReportTab/ReportDetailScreen": ReportDetailScreen,
  "screens/ReportTab/ListExpenseComponent": ListExpenseComponent,
  "screens/ReportTab/ItemRequestChooser": ItemRequestChooser,
  "screens/ReportTab/ItemExpenseChooser": ItemExpenseChooser,
  "screens/ReportTab/GroupExpenseItemInList": GroupExpenseItemInList,
  "screens/ReportTab/FilterReport": FilterReport,
  "screens/ReportTab/ExpenseChooserGroupBy": ExpenseChooserGroupBy,
  "screens/ReportTab/ExpenseChooser": ExpenseChooser,
  "screens/ReportTab/NewReportScreen": NewReportScreen,
  "screens/ReportTab/ReportForm/ReportForm": ReportForm,
  "screens/ReportTab/ReportForm/ReportHeader": ReportHeader,
  "screens/ReportTab/ReportForm/RejectedSection": RejectedSection,
  "screens/ReportTab/ReportForm/ReportList": ReportList,
  "screens/ReportTab/ReportForm/ReportFooter": ReportFooter,
  "screens/ReportTab/ReportForm/ItemListReport": ItemListReport,
  "screens/ReportTab/HistoryReportScreen": HistoryReportScreen,

  "screens/ProfileTab/InboxItemDetailScreen": InboxItemDetailScreen,
  "screens/ProfileTab/InboxScreen": InboxScreen,
  "screens/ProfileTab/LocalAuthenticationScreen": LocalAuthenticationScreen,
  "screens/ProfileTab/ProfileScreen": ProfileScreen,
  "screens/ProfileTab/UpdateProfileScreen": UpdateProfileScreen,
  "screens/MDLZ/ProfileTab/UpdateProfileScreen": MDLZUpdateProfileScreen,
  "screens/ProfileTab/AboutScreen": AboutScreen,
  "screens/ProfileTab/NotificationScreen": NotificationScreen,
  "screens/ProfileTab/SelectLanguageScreen": SelectLanguageScreen,
  "screens/ProfileTab/ChangePasswordScreen": ChangePasswordScreen,

  "screens/CompanyTab/CompanyDetailScreen": CompanyDetailScreen,
  "screens/CompanyTab/CompanyFormScreen": CompanyFormScreen,
  "screens/CompanyTab/CompanyScreen": CompanyScreen,
  "screens/CompanyTab/CompanyFormModal": CompanyFormModal,

  "screens/InboxTab/InboxTab": InboxTab,

  "screens/Request/RequestForm/TravelRequestForm": TravelRequestForm,
  "screens/Request/RequestForm/Attachments": RequestAttachments,
  "screens/Request/RequestForm/FooterButton": RequestFooterButton,
  "screens/Request/RequestForm/RequestCategoryItems": RequestCategoryItems,
  "screens/Request/RequestForm/ExpenseAllowanceItem": ExpenseAllowanceItem,
  "screens/Request/RequestForm/RequestForm": RequestForm,
  "screens/Request/AddExpenseToRequestScreen": AddExpenseToRequestScreen,
  "screens/Request/DetailRequestScreen": DetailRequestScreen,
  "screens/Request/EditRequestScreen": EditRequestScreen,
  "screens/Request/FilterRequest": FilterRequest,
  "screens/Request/ListRequestApprovalScreen": ListRequestApprovalScreen,
  "screens/Request/ListRequestHistoryScreen": ListRequestHistoryScreen,
  "screens/Request/ListRequestScreen": ListRequestScreen,
  "screens/Request/ModalExpenseChooser": ModalExpenseChooserRequest,
  "screens/Request/ModalTypeOfRequest": ModalTypeOfRequest,
  "screens/Request/NewRequestScreen": NewRequestScreen,
  "screens/Request/RequestItem": RequestItem,
  "screens/Request/RequestTab": RequestTab,
  "screens/Request/RequestForm/CategoryRequestFilter": CategoryRequestFilter,
  "screens/Request/PortalCancelExpenseRequest": PortalCancelExpenseRequest,
  "screens/ReportTab/PortalCancelExpenseReport": PortalCancelExpenseReport,
  "screens/Request/ExpenseRequestStatusIndicator": ExpenseRequestStatusIndicator,

  "screens/ImageBrowserScreen": ImageBrowserScreen,
  "screens/CameraScreen/CameraScreen": CameraScreen,
  "screens/CameraScreen/ReviewImage": ReviewImage,

  "screens/SuggestionAddress/SuggestionAddressScreen": SuggestionAddressScreen,
  "screens/SuggestionAddress/NearByAddressScreen": NearByAddressScreen,
  "screens/SuggestionAddress/History": SearchLocationHistory,

  "screens/TLN/InvoicesTab/ClaimedExpenses": TLNClaimedExpenses,
  "screens/TLN/InvoicesTab/ExpenseScreen": TLNExpenseScreen,
  "screens/TLN/InvoicesTab/UnClaimedExpense": TLNUnClaimedExpense,
  "screens/TLN/InvoicesTab/SendClaimedSuccess": TLNSendClaimedSuccess,
  "screens/RegisterOnboarding/RegisterWarningEnabledExpense": RegisterWarningEnabledExpense,

  "layouts/request/RequestInfo": RequestInfo,
  "layouts/EmptyInvoiceList": EmptyInvoiceList,
  "layouts/PasswordInputField": PasswordInputFieldLayout,
  "layouts/ExpenseItem": ExpenseItem,
  "layouts/RangeDate": RangeDate,
  "layouts/ExpenseForm/ExpenseForm": ExpenseForm,
  "layouts/InvoiceTypeChooser": InvoiceTypeChooser,
  "layouts/InputRequestCategory": InputRequestCategory,
  "layouts/ExpenseCategoryChooser": ExpenseCategoryChooser,
  "layouts/ExpenseForm/AdditionalData": AdditionalData,
  "layouts/ExpenseForm/InvoiceChooser": InvoiceChooser,
  "layouts/ExpenseForm/ExpenseRequestItem": ExpenseRequestItem,
  "layouts/PasswordRules": PasswordRules,

  "layouts/ExpenseForm/InvoiceItem": InvoiceItem,
  "layouts/DatetimePickerField": DatetimePickerField,
  "layouts/InputExpenseCategory": InputExpenseCategory,
  "layouts/InputExpenseRequestCategory": InputExpenseRequestCategory,
  "layouts/InputLocation": InputLocation,
  "layouts/InputSelectRangeDate": InputSelectRangeDate,
  "layouts/InvoiceItemInList": InvoiceItemInList,
  "layouts/request/ItemTravel": ItemTravel,

  "layouts/ImageBrower/ImageBrowser": ImageBrowser,
  "layouts/ImageUploadField/ImageUploadField": ImageUploadField,

  "layouts/SelectedApprovalModal": SelectedApprovalModal,
  "layouts/InputApprovalList": InputApprovalList,
  "layouts/ApprovalTimeLine": ApprovalTimeLine,
  "layouts/DateTypeChooser": DateTypeChooser,
  "layouts/InputVATRate": InputVATRate,
  "layouts/TeamIdChooser": TeamIdChooser,

  "layouts/Dashboard": DashboardLayout,
  "layouts/Dashboard/CategoryChart": CategoryChartDashboardLayout,
  "layouts/Dashboard/DepartmentChart": DepartmentChartDashboardLayout,
  "layouts/Dashboard/ProviderChart": ProviderChartDashboardLayout,
  "layouts/Dashboard/TrendChart": TrendChartDashboardLayout,

  "navigation/AppStack": AppStackNavigation,
  "navigation/HomeBottomTabNavigator": HomeBottomTabNavigator,

  "screens/Customization/DeprecatedUI/Request/RequestForm/OtherRequestForm": OtherRequestForm,
  "screens/Customization/LSP/Request/components/RequestItem": RequestItemLSP,
  "screens/Customization/LSP/Request/components/ReportForm/ReportHeader": ReportHeaderLSP,
  "screens/Customization/LSP/Request/components/ReportForm/TypeofPaymentChooser": TypeofPaymentChooserLSP,
  "screens/Customization/LSP/Request/components/ReportForm/TypeofRequestChooser": TypeofRequestChooserLSP,
  "screens/Customization/LSP/Request/components/FormContainer": FormContainerLSP,
  "screens/Customization/LSP/Request/NewRequest": NewRequestLSP,
  "screens/Customization/LSP/Request/EditRequest": EditRequestLSP,
  "screens/Customization/LSP/Request/components/RequestDetail/AdvanceDetail": AdvanceDetailLSP,
  "screens/Customization/LSP/Request/components/RequestDetail/ReimburseDetail": ReimburseDetailLSP,
  "screens/Customization/LSP/Request/components/ApprovalRequestScreen/ReimburseApprovalDetail":
    ReimburseApprovalDetailLSP,
  "screens/Customization/LSP/Request/components/ApprovalRequestScreen/AdvanceApprovalDetail": AdvanceApprovalDetailLSP,

  "screens/Customization/LSP/Request/components/ClearingForm/AdvanceItem": AdvanceItemLSP,
  "screens/Customization/LSP/Request/components/ClearingForm/ClearingListRequest": ClearingListRequestLSP,
  "screens/Customization/LSP/Request/components/AdvanceChooserModal/AdvanceChooserModal": AdvanceChooserModalLSP,

  "hooks/useInvoiceValidationResult": useInvoiceValidationResult,
  "screens/InvoicesTab/ModalTransferInvoiceEmployee": ModalTransferInvoiceEmployee,
  "screens/InvoicesTab/ModalTransferInvoiceWarning": ModalTransferInvoiceWarning,
  "screens/InvoicesTab/ModalDeleteInvoice": ModalDeleteInvoice,
  common: {
    internal_error: "Đã có lỗi phát sinh trong quá trình xử lý.",
    continue: "Tiếp tục",
    understood: "Đã hiểu",
    next: "Tiếp tục",
    back: "Quay lại",
    finish: "Kết thúc",
    confirm: "Xác nhận",
    cancel: "Hủy",
    error: "Lỗi",
    million: "Tr",
    million_full: " triệu",
    billion: "T",
    billion_full: " tỷ",
    hundred_full: " nghìn",
    hundred: "N",
    employee_no_title: "(No position)",
    no_unit: "(No unit)",
    not_update: "(chưa cập nhật)",
    no_value: "(Không có {{label}})",
    employee_code: "mã nhân viên",
    email: "email",
    phone: "số điện thoại",
    over_travel_remain: "Vượt hạn mức số ngày công tác",
    over_travel_remain_month: "{{day}} ngày (tháng {{month}})",
    over_travel_remain_quarter: "{{day}} ngày (quý {{quarter}})",
    view_detail: "Xem chi tiết",
    no_name: "(Không có tên)",
    closed: "Đã đóng",
    order_by: "Sắp xếp theo",
    invalid: "{{name}} không hợp lệ",
    product_name: "Hàng hóa/Dịch vụ",
    unit_price: "Đơn giá",
    unit_product: "ĐVT",
    quantity: "SL",
    empty_value: "(Không có {{label}})",
    request_payment_status_paid: "Đã thanh toán",
    request_payment_status_pending: "Chờ thanh toán",
    switch_company: "Đổi sang {{companyName}}",
    verifying: "Đang xác thực",
  },
};

export default vi;
