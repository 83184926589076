// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";
//@ts-ignore
import { InsertExpenseAttachmentMutation, InsertExpenseAttachmentMutationVariables } from "types";

const INSERT_EXPENSE_ATTACHMENT = gql`
  mutation MobileInsertExpenseAttachment($attachments: [expense_attachments_insert_input!]!) {
    expenseAttachments: insert_expense_attachments(objects: $attachments) {
      affectedRows: affected_rows
      returning {
        expenseAttachmentId: expense_attachment_id
      }
    }
  }
`;

export const useExpInsertExpenseAttachments = (
  options?: MutationHookOptions<InsertExpenseAttachmentMutation, InsertExpenseAttachmentMutationVariables>
) => {
  return useMutation(INSERT_EXPENSE_ATTACHMENT, { ...options });
};
