import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { Linking, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import i18n from "i18next";
import { Colors, Fonts } from "theme";
import {
  AppText,
  AvatarUser,
  BottomSheetScrollViewModalCustom,
  BottomSheetScrollViewModalCustomMethods,
} from "components/index";
import {
  CallIcon,
  CallPhoneNumberIcon,
  CloseIcon,
  MailIcon,
  SendEmailIcon,
  UserContactIcon,
} from "assets/images/svg/icons";
import useGetEmployeeInfo from "hooks/useGetEmployeeInfo";
import { useAuth } from "contexts/AuthContext";
import { Toast } from "@ant-design/react-native";
import { analyticService } from "services/AnalyticsService";
import { EVENT } from "constants/Tracking";

export interface EmployeeModalViewMethods {
  present: ({ employeeId, avatar }: { employeeId: string; avatar?: string }) => void;
  close?: () => void;
}
const EmployeeModalView = forwardRef<EmployeeModalViewMethods>((_, ref) => {
  useImperativeHandle(
    ref,
    () => ({
      present: async ({ employeeId, avatar }) => {
        Toast.loading("");
        analyticService.logEvent({ name: EVENT.OTHER.VIEW_EMPLOYEE_INFO });
        setCurrentAvatar(avatar);
        await onFetchData({
          variables: {
            employeeId,
            companyId: company_id,
          },
        });
        Toast.removeAll();
        bottomSheetModalRef?.current?.present();
      },
      close: () => {
        bottomSheetModalRef?.current?.close();
      },
    }),
    []
  );
  const bottomSheetModalRef = useRef<BottomSheetScrollViewModalCustomMethods>();
  const {
    company: { company_id },
  } = useAuth();
  const [currentAvatar, setCurrentAvatar] = useState("");
  const [onFetchData, { data, loading }] = useGetEmployeeInfo();
  const employee = data?.orgEmployeeInfo;
  const openMailClient = (email) => () => {
    analyticService.logEvent({ name: EVENT.OTHER.SEND_MAIL_EMPLOYEE });
    Linking.canOpenURL(`mailto:${email}`)
      .then((supported) => {
        if (!supported) {
          console.log("Cant handle url");
        } else {
          return Linking.openURL(`mailto:${email}`).catch((error) => {
            console.log(error);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const callSupportPhoneNumber = (phone) => () => {
    analyticService.logEvent({ name: EVENT.OTHER.CALL_PHONE_EMPLOYEE });
    let phoneNumber;
    if (Platform.OS === "ios") {
      phoneNumber = `telprompt:${phone}`;
    } else {
      phoneNumber = `tel:${phone}`;
    }
    if (Platform.OS === "web") {
      window.open(phoneNumber, "_blank", "noopener noreferrer");
      return;
    }
    Linking.canOpenURL(phoneNumber)
      .then((supported) => {
        if (supported) {
          return Linking.openURL(phoneNumber);
        }
      })
      .catch((err) => console.log(err));
  };
  const renderAvatar = useCallback(() => {
    return (
      <>
        <AvatarUser
          fullname={employee?.fullname}
          borderWidth={2}
          style={styles.avatarFull}
          textStyle={Fonts.H400}
          size={84}
          url={currentAvatar}
        />
        <TouchableOpacity style={styles.closeIcon} onPress={() => bottomSheetModalRef?.current?.close()}>
          <CloseIcon />
        </TouchableOpacity>
      </>
    );
  }, [currentAvatar, employee]);
  const renderAvatarFromApi = useCallback(() => {
    return (
      <>
        <AvatarUser
          borderWidth={2}
          style={styles.avatarFull}
          textStyle={Fonts.H400}
          size={84}
          url={employee?.avatarUrl || employee?.defaultAvatarUrl}
          fullname={employee?.fullname}
        />
        <TouchableOpacity style={styles.closeIcon} onPress={() => bottomSheetModalRef?.current?.close()}>
          <CloseIcon />
        </TouchableOpacity>
      </>
    );
  }, [employee?.avatarUrl, employee?.defaultAvatarUrl]);
  return (
    <BottomSheetScrollViewModalCustom
      handleComponent={currentAvatar ? renderAvatar : renderAvatarFromApi}
      ref={bottomSheetModalRef}
      //minHeight={338}
    >
      <View style={styles.body}>
        <View style={styles.center}>
          <AppText style={Fonts.H300}>{employee?.fullname}</AppText>
          {Boolean(employee?.title) && (
            <AppText style={Fonts.BodyMedium} color={employee?.title ? Colors.grayscale100 : Colors.grayscale60}>
              {employee?.title}
            </AppText>
          )}
          {/*employee?.teams?.length > 0 && (
            <AppText style={[Fonts.BodyMedium, styles.textCenter]}>
              {employee?.teams
                ?.map((item) => getTitleFromCompanyTeam({ name: item?.name, nameEn: item?.nameEn, code: item?.code }))
                .join("\n")}
            </AppText>
          )*/}
          <View style={styles.actionRow}>
            <TouchableOpacity disabled={!employee?.email} onPress={openMailClient(employee?.email)}>
              <SendEmailIcon />
            </TouchableOpacity>
            <TouchableOpacity disabled={!employee?.phone} onPress={callSupportPhoneNumber(employee?.phone)}>
              <CallPhoneNumberIcon />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.infoContainer}>
          <View style={styles.infoRow}>
            <MailIcon />
            <AppText style={styles.infoValue} selectable={true}>
              {employee?.email || i18n.t("common:no_value", { label: i18n.t("common:email") })}
            </AppText>
          </View>
          <View style={styles.divider} />
          <View style={styles.infoRow}>
            <UserContactIcon />
            <AppText style={styles.infoValue} selectable={true}>
              {employee?.employeeCode || i18n.t("common:no_value", { label: i18n.t("common:employee_code") })}
            </AppText>
          </View>
          <View style={styles.divider} />
          <View style={styles.infoRow}>
            <CallIcon />
            <AppText style={styles.infoValue} selectable={true}>
              {employee?.phone || i18n.t("common:no_value", { label: i18n.t("common:phone") })}
            </AppText>
          </View>
        </View>
      </View>
    </BottomSheetScrollViewModalCustom>
  );
});

const styles = StyleSheet.create({
  body: { marginTop: 70, paddingHorizontal: 20, borderRadius: 16 },
  avatarFull: { position: "absolute", top: -30, alignSelf: "center" },
  actionRow: { flexDirection: "row", marginTop: 16, gap: 24 },
  infoRow: { flexDirection: "row", gap: 24, alignItems: "center" },
  divider: { height: 1, backgroundColor: Colors.grayscale05, marginVertical: 6 },
  infoValue: { ...Fonts.BodyRegular, flex: 1 },
  closeIcon: { position: "absolute", right: 15, top: 10 },
  textCenter: { textAlign: "center" },
  center: { alignItems: "center" },
  infoContainer: {
    marginTop: 16,
    paddingHorizontal: 20,
    paddingVertical: 12,
    backgroundColor: Colors.grayscale0,
    borderRadius: 12,
  },
});

export default EmployeeModalView;
