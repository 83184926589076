// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Fragment, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Pressable, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Flex } from "@ant-design/react-native";
import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Divider, Text } from "react-native-paper";
import dayjs from "dayjs";
import { SPACING_DEFAULT } from "constants/Layout";
import {
  AlertNotification,
  AlertNotificationHandle,
  AppText,
  BackHeader,
  BottomSheetModalCustomMethods,
  Button,
  CopilotStepCustom,
  EmptyData,
  FloatFooter,
  IconCustom,
  Line,
  LoadingView,
  ToastManager,
} from "components";
import { ArrowDownFilled, ArrowUpFilled, ErrorInvoiceIcon, WarningCircleIcon } from "assets/images/svg/icons";

import SCREEN_NAME from "navigation/ScreenName";
import { FeatureFlagContext } from "contexts/FeatureFlagContext";
import useInvoiceValidationResult from "hooks/useInvoiceValidationResult";
import isEmpty from "lodash/isEmpty";
import ModalTransferInvoiceEmployee from "./components/ModalTransferInvoiceEmployee";
import useTransferExpenseInvoice from "hooks/invoices/useTransferInvoice";
import { useAuth } from "contexts/AuthContext";
import useDeleteExpenseInvoice from "hooks/invoices/useDeleteInvoice";
import { Colors, Fonts } from "theme";
import { useIsFeaturesEnabled } from "contexts/FeatureManagementContext";
import { FEATURE_FLAGS } from "constants/FeatureFlags";
import { COMPANIES } from "constants/Companies";
import InvoiceValidationTTC from "./components/InvoiceValidationTTC";
import useInvoiceDetailQuery from "./hooks/useInvoiceDetailQuery";
import { formatMoney } from "utils";
import { goBack } from "navigation/RootNavigation";
import { EVENT } from "constants/Tracking";
import { analyticService } from "services/AnalyticsService";
import { CONSTANTS } from "constants/constants";
import { BizziBotSticker01 } from "assets/images";
import { useCopilot } from "react-native-copilot";
import { useWalkthroughGuide } from "contexts/WalkthroughGuideContext/WalkthroughGuideContext";
import { executeRouteFunction } from "utils/route";

const DummyWalkthroughData = require("./DummyWalkthroughData.json");
type STATUS_VERIFY = "success" | "error";
const status_verify = {
  success: "success",
  error: "error",
};

const ICON_STATUS = {
  error: <IconCustom name="cancel" width={16} height={16} fill={Colors.alert50} />,
  success: <IconCustom name="check-circle" />,
};

const useValidationAggregation = (data) => {
  const [validation, setValidation] = useState({});

  useEffect(() => {
    if (data?.[0]?.validationAggregation?.results) {
      const validations = data?.[0]?.validationAggregation?.results?.reduce(
        // eslint-disable-next-line @typescript-eslint/ban-types
        (nextvalidation: {}, currentValidation: { key: string; status: string }) => {
          nextvalidation[currentValidation.key] = currentValidation.status;

          return nextvalidation;
        },
        {}
      );

      setValidation(validations);
    }
  }, [data]);

  return validation;
};

const InvoiceDetailScreen = ({ navigation, route }) => {
  const { invoiceId, shouldShowWalkthrough = false } = route?.params ?? {};
  const forceHideButton = route?.params?.forceHideButton;
  const modalTransferInvoiceEmployeeRef = useRef<BottomSheetModalCustomMethods>();
  const alertNotificationRef = useRef<AlertNotificationHandle>(null);
  const [hasMore, setHasMore] = useState(false);
  const { t } = useTranslation("app/screens/Inbox/InvoiceDetailScreen");
  const { t: tInvoiceValidationResult } = useTranslation("hooks/useInvoiceValidationResult");
  const [footerHeight, setFooterHeight] = useState(0);

  const { user, company } = useAuth();
  const [onFetchData, { data, loading, called, refetch }] = useInvoiceDetailQuery({
    invoiceId,
    companyId: user.company.company_id,
  });
  const dataInvoice = shouldShowWalkthrough ? DummyWalkthroughData : data?.expInvoiceDetail;

  useEffect(() => {
    if (!shouldShowWalkthrough) {
      onFetchData?.();
    }
  }, []);

  /*-- start walkthrough flow --*/
  const { handleFinishGuideAction } = useWalkthroughGuide();
  const { start, steps, stop } = useCopilot();
  const stepNameToStartWalkthrough = Object.keys(steps).filter(
    (key) =>
      steps[key].walkthroughName === CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME &&
      steps[key].order === CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step7
  )?.[0];
  useEffect(() => {
    if (shouldShowWalkthrough && shouldShowWalkthrough !== "false" && stepNameToStartWalkthrough) {
      const step = steps[stepNameToStartWalkthrough];
      setTimeout(() => {
        start({
          step,
          walkthroughName: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME,
        });
      }, CONSTANTS.WALK_THROUGH.DELAY_TIME);
    }
  }, [stepNameToStartWalkthrough]);
  /*-- end --*/

  const isTLNExpenseCompany = useMemo(
    () =>
      [
        COMPANIES.TLN_EXPENSE_COMPANY_SCRUM,
        COMPANIES.TLN_EXPENSE_COMPANY_DEV,
        COMPANIES.TLN_EXPENSE_COMPANY_PRODUCTION,
      ].includes(company?.company_id),
    [company?.company_id]
  );

  const {
    // FEATURE_TURN_OFF_CREATE_EXPENSE_BUTTON_WHEN_INVOICE_ERROR,
    // FEATURE_SHOW_FULL_VERIFIED_INVOICE
  } = useContext(FeatureFlagContext);

  const [FEATURE_TRANSFER_EXPENSE_INVOICE, FEATURE_DELETE_EXPENSE_INVOICE] = useIsFeaturesEnabled([
    FEATURE_FLAGS.FEATURE_TRANSFER_EXPENSE_INVOICE,
    FEATURE_FLAGS.FEATURE_DELETE_EXPENSE_INVOICE,
  ]);
  const { transferExpenseInvoice } = useTransferExpenseInvoice();
  const { deleteExpenseInvoice } = useDeleteExpenseInvoice();

  const {
    totalAmountWithVat = 0,
    totalAmountWithoutVat = 0,
    totalVatAmount = 0,
    sellerLegalName = "",
    sellerTaxCode = 0,
    sellerAddressLine = "",
    buyerLegalName = "",
    buyerTaxCode = 0,
    buyerAddressLine = "",
    invoiceItems = [],
    discountAmount = 0,
    buyerDisplayName,
    expense,
    invoiceValidations,
    invoiceIo,
    invoiceSeries,
    templateCode = "",
    issuedDate,
    receivedAt,
    invoiceNumber,
  } = dataInvoice ?? {};

  const validations: Partial<{
    buyer_address_line: STATUS_VERIFY;
    buyer_legal_name: STATUS_VERIFY;
    buyer_tax_code: STATUS_VERIFY;
    certificate: STATUS_VERIFY;
    digest: STATUS_VERIFY;
  }> = useValidationAggregation(invoiceValidations);
  const [getInvoiceValidationResult] = useInvoiceValidationResult();

  const validationsWeb = useMemo(() => {
    if (dataInvoice) {
      return getInvoiceValidationResult(dataInvoice);
    }
  }, [getInvoiceValidationResult, dataInvoice]);
  const evidenceScreenshots = useMemo(() => {
    return invoiceValidations?.[0]?.evidences?.screenshots ?? [];
  }, [invoiceValidations]);

  const tcnntScreenshotData = useMemo(() => {
    return evidenceScreenshots.find((screenshot) => screenshot.type === "tcnnt");
  }, [evidenceScreenshots]);

  const tchdScreenshotData = useMemo(() => {
    return (
      evidenceScreenshots.find((screenshot) => screenshot.type === "tchd") || {
        url: validationsWeb?.taxVerificationData?.tchdScreenshot,
        created_at: validationsWeb?.taxVerificationData?.screenshotAt,
      }
    );
  }, [evidenceScreenshots, validationsWeb]);

  const disabledButtonCreateExpense = useMemo(() => {
    return (
      (validations?.buyer_address_line === "error" ||
        validations?.buyer_legal_name === "error" ||
        validations?.buyer_tax_code === "error") &&
      isTLNExpenseCompany
    );
  }, [
    isTLNExpenseCompany,
    validations?.buyer_address_line,
    validations?.buyer_legal_name,
    validations?.buyer_tax_code,
  ]);

  const onPress = useCallback(() => {
    if (expense?.expenseId) {
      navigation.replace(SCREEN_NAME.EditExpenseScreen, {
        expenseId: expense?.expenseId,
        isForceFullInfo: true,
        invoiceId,
        autofill: true,
      });
    } else {
      navigation.replace(SCREEN_NAME.NewExpenseScreen, {
        invoiceId,
        autofill: true,
        onRefreshData: route?.params?.onRefreshData,
      });
    }
  }, [expense?.expenseId, navigation, invoiceId]);

  const invoiceSeries2 = useMemo(() => {
    if (templateCode.length <= 2) {
      return {
        hasNewInvoiceSeries: true,
        invoiceSeries: `${templateCode}${invoiceSeries}`,
      };
    }
    return {
      hasNewInvoiceSeries: false,
      invoiceSeries: invoiceSeries,
    };
  }, [invoiceSeries, templateCode]);

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <BackHeader headerTitle={t("invoice_detail")} />,
    } as unknown);
  }, []);

  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    label: string;
  }>({
    id: "",
    label: "",
  });

  const onLayoutFooter = (event) => {
    const { height } = event.nativeEvent.layout;
    //alert(height);
    setFooterHeight(height);
  };
  const onTransfer = useCallback(() => {
    modalTransferInvoiceEmployeeRef?.current?.close();
    alertNotificationRef?.current?.confirm({
      icon: <WarningCircleIcon />,
      title: t("warning"),
      description: (
        <View>
          <AppText style={Fonts.BodyMedium} color={Colors.grayscale80}>
            {t("content")}
          </AppText>
          <AppText style={[Fonts.BodyMedium, { marginTop: 8 }]} color={Colors.grayscale80}>
            {t("content1")}
            <AppText style={Fonts.H200}>{` ${selectedItem?.label}`}</AppText>
          </AppText>
        </View>
      ),
      onConfirm: async () => {
        try {
          alertNotificationRef?.current?.loading();
          const destinationEmployeeIds = isEmpty(selectedItem.id) ? [] : [selectedItem.id];
          const object = {
            employeeId: user?.employee_id,
            invoiceId,
            destinationEmployeeIds,
          };
          analyticService.logEvent({ name: EVENT.INVOICE.TRANSFERRED_INVOICE });
          await transferExpenseInvoice({ object });
          analyticService.logEvent({ name: EVENT.INVOICE.TRANSFERRED_INVOICE_SUCCESS });
          alertNotificationRef?.current?.close();
          ToastManager.success(t("update_success"));
          goBack();
          executeRouteFunction(route, "onRefreshData");
        } catch (error) {
          if (error instanceof ApolloError) {
            alertNotificationRef.current?.error({
              title: t("invoice_transfer_failed"),
              description: error.message,
            });
          }
        }
      },
    });
  }, [transferExpenseInvoice, invoiceId, selectedItem, user, t, navigation]);
  const handleDeleteInvoice = () => {
    alertNotificationRef?.current?.warning({
      title: t("delete_invoice"),
      description: t("delete_invoice_confirm"),
      confirmText: t("confirm"),
      cancelText: t("close"),
      onConfirm: async () => {
        try {
          alertNotificationRef?.current?.loading();
          const object = {
            employeeId: user.employee_id,
            invoiceId,
          };
          await deleteExpenseInvoice({ object });
          analyticService.logEvent({ name: EVENT.INVOICE.DELETED_INVOICE });
          alertNotificationRef?.current?.close();
          ToastManager.success(t("delete_invoice_success"));
          goBack();
          executeRouteFunction(route, "onRefreshData");
        } catch (error) {
          if (error instanceof ApolloError) {
            alertNotificationRef.current?.error({
              title: t("delete_invoice_failed"),
              description: error.message,
            });
          }
        }
      },
    });
  };
  if ((loading || !called) && !shouldShowWalkthrough) {
    return <LoadingView style={styles.loadingView} />;
  }
  if (!dataInvoice) {
    return (
      <View style={styles.errorContainer}>
        <EmptyData
          icon={<ErrorInvoiceIcon />}
          title={t("invoice_not_found_title")}
          description={t("invoice_not_found_description")}
        />
      </View>
    );
  }

  const errorList =
    validationsWeb?.computedInvoiceValidations?.filter((item) => item.status === status_verify.error) ?? [];
  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ paddingBottom: footerHeight }}>
        <View style={styles.content}>
          {errorList?.length > 0 && (
            <CopilotStepCustom
              title={"walkthrough_email_title_step8"}
              text={"walkthrough_email_description_step8"}
              order={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step7}
              image={BizziBotSticker01}
              walkthroughName={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME}
              activeStep={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step7}
              totalStep={CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.TotalStep}
              offsetX={10}
              onConfirm={handleFinishGuideAction}
              onBack={() => {
                stop();
                goBack();
                setTimeout(() => {
                  start({
                    fromStep: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.Step6.toString(),
                    walkthroughName: CONSTANTS.WALK_THROUGH.EMAIL_RECEIVE_INVOICE.NAME,
                  });
                }, 500);
              }}
            >
              <View style={styles.errorWrapper}>
                <AppText style={[Fonts.BodySmall, styles.errorInvoiceTitle]} color={Colors.grayscale60}>
                  {t("invoice_checking_result")}
                </AppText>
                {errorList.map((item, idx) => (
                  <View key={idx} style={styles.errorRow}>
                    <View style={styles.statusIcon}>{ICON_STATUS.error}</View>
                    <AppText style={[Fonts.BodyMedium, styles.flex]} color={Colors.alert50}>
                      {item?.message}
                    </AppText>
                  </View>
                ))}
              </View>
            </CopilotStepCustom>
          )}
          <InvoiceValidationTTC invoice={dataInvoice} onRefreshDetail={refetch} />
          <View style={[styles.item, { marginTop: 1 }]}>
            <View style={styles.wrapperItemContent}>
              <AppText style={[Fonts.BodySmall, styles.headerTitleItemStyle]} color={Colors.grayscale60}>
                {t("seller_name")}
              </AppText>
              <AppText style={[Fonts.NumericN200, styles.headerTextItemStyle]} color={Colors.grayscale100}>
                {sellerLegalName || ""}
              </AppText>
              <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                {t("tax_code")}: {sellerTaxCode}
              </AppText>
              <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                {t("address")}: {sellerAddressLine}
              </AppText>
            </View>
          </View>

          <View style={styles.item}>
            <View style={styles.wrapperItemContent}>
              <AppText style={[Fonts.BodySmall, styles.headerTitleItemStyle]} color={Colors.grayscale60}>
                {t("buyer_name")}
              </AppText>
              <View style={styles.wrapperItemVerify}>
                <View style={styles.flex}>
                  <AppText style={[Fonts.NumericN200, styles.headerTextItemStyle]} color={Colors.grayscale100}>
                    {buyerLegalName || buyerDisplayName}{" "}
                  </AppText>
                </View>
                {!isTLNExpenseCompany && (
                  <View style={styles.marginLeft}>
                    {!!validations?.buyer_legal_name &&
                      !!status_verify[validations?.buyer_legal_name] &&
                      ICON_STATUS[validations.buyer_legal_name]}
                  </View>
                )}
              </View>

              <View style={styles.wrapperItemVerify}>
                <View style={styles.flex}>
                  <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                    {t("tax_code")}: {buyerTaxCode}
                  </AppText>
                </View>
                {!isTLNExpenseCompany && (
                  <View style={styles.marginLeft}>
                    {!!validations?.buyer_tax_code && ICON_STATUS[validations?.buyer_tax_code]}
                  </View>
                )}
              </View>

              <View style={styles.wrapperItemVerify}>
                <View style={styles.flex}>
                  <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                    {t("address")}: {buyerAddressLine}
                  </AppText>
                </View>
                {!isTLNExpenseCompany && (
                  <View style={styles.marginLeft}>
                    {!!validations?.buyer_address_line && ICON_STATUS[validations?.buyer_address_line]}
                  </View>
                )}
              </View>
            </View>
          </View>
          <View style={styles.item}>
            <View style={styles.wrapperItemContent}>
              <AppText style={[Fonts.BodySmall, styles.headerTitleItemStyle]} color={Colors.grayscale60}>
                {t("invoice_info")}
              </AppText>
              {!invoiceSeries2.hasNewInvoiceSeries && (
                <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                  {t("template_code")}: {templateCode}
                </AppText>
              )}
              <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                {t("invoice_series")}: {invoiceSeries2?.invoiceSeries}
              </AppText>
              <Flex>
                <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                  {t("invoice_number")}:
                </AppText>
                <AppText style={[styles.subTextItemStyle, { color: "red", fontWeight: "bold", marginLeft: 4 }]}>
                  {invoiceNumber}
                </AppText>
              </Flex>
              <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                {t("received_at")}: {dayjs(receivedAt).format("DD/MM/YYYY")}
              </AppText>
              <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                {t("issued_date")}: {dayjs(issuedDate).format("DD/MM/YYYY")}
              </AppText>
            </View>
          </View>
          {isTLNExpenseCompany && (
            <View style={styles.item}>
              <View style={styles.wrapperItemContent}>
                <AppText style={[Fonts.BodySmall, styles.headerTitleItemStyle]} color={Colors.grayscale60}>
                  {t("invoice_checking_result")}
                </AppText>
                {!!validations?.buyer_legal_name && (
                  <View style={styles.wrapperItemVerify}>
                    <View>{!!validations?.buyer_legal_name && ICON_STATUS[validations?.buyer_legal_name]}</View>
                    <Text style={[styles.subTextItemStyle, { marginLeft: 5 }]}>
                      {tInvoiceValidationResult(`buyer_legal_name_${validations?.buyer_legal_name}`)}
                    </Text>
                  </View>
                )}

                {!!validations?.buyer_tax_code && (
                  <View style={styles.wrapperItemVerify}>
                    <View>{!!validations?.buyer_tax_code && ICON_STATUS[validations?.buyer_tax_code]}</View>
                    <Text style={[styles.subTextItemStyle, { marginLeft: 5 }]}>
                      {tInvoiceValidationResult(`buyer_tax_code_${validations?.buyer_tax_code}`)}
                    </Text>
                  </View>
                )}

                {!!validations?.buyer_address_line && (
                  <View style={styles.wrapperItemVerify}>
                    <View>{!!validations?.buyer_address_line && ICON_STATUS[validations?.buyer_address_line]}</View>
                    <Text style={[styles.subTextItemStyle, { marginLeft: 5 }]}>
                      {tInvoiceValidationResult(`buyer_address_line_${validations?.buyer_address_line}`)}
                    </Text>
                  </View>
                )}

                {!!validations?.certificate && (
                  <View style={styles.wrapperItemVerify}>
                    <View>{!!validations?.certificate && ICON_STATUS[validations?.certificate]}</View>
                    <Text style={[styles.subTextItemStyle, { marginLeft: 5 }]}>
                      {tInvoiceValidationResult(`certificate_${validations?.certificate}`)}
                    </Text>
                  </View>
                )}

                {!!validations?.digest && (
                  <View style={styles.wrapperItemVerify}>
                    <View>{!!validations?.digest && ICON_STATUS[validations?.digest]}</View>
                    <Text style={[styles.subTextItemStyle, { marginLeft: 5 }]}>
                      {tInvoiceValidationResult(`digest_${validations?.digest}`)}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          {isTLNExpenseCompany && (
            <View style={styles.item}>
              <View style={[styles.wrapperItemContent, { paddingBottom: 0 }]}>
                <AppText style={[Fonts.BodySmall, styles.headerTitleItemStyle]} color={Colors.grayscale60}>
                  {t("verification_result_with_gdt_system")}
                </AppText>

                <Text style={[styles.subTextItemStyle, { color: Colors.blue22 }]}>
                  {t("see_result", {
                    date: dayjs(tcnntScreenshotData?.created_at || tchdScreenshotData?.created_at).format("DD-MM-YYYY"),
                  })}
                </Text>
                {validationsWeb?.sellerTaxValidations?.map((item) => {
                  const { status, key, message } = item;

                  return (
                    <View key={key} style={styles.wrapperItemVerify}>
                      <View>{!!validations?.buyer_address_line && ICON_STATUS[status]}</View>
                      <Text style={[styles.subTextItemStyle, { marginLeft: 5 }]}>{message}</Text>
                    </View>
                  );
                })}
              </View>

              <View style={[styles.wrapperItemContent, { paddingTop: 0 }]}>
                <Pressable
                  onPress={() => {
                    // FileViewer.open(tchdScreenshotData.url);
                  }}
                >
                  <Text style={[styles.subTextItemStyle, { color: Colors.blue22 }]}>
                    {t("see_result_invoice", {
                      date: dayjs(tcnntScreenshotData?.created_at || tchdScreenshotData?.created_at).format(
                        "DD-MM-YYYY"
                      ),
                    })}
                  </Text>
                </Pressable>

                {validationsWeb?.invoiceTaxValidations?.map((item) => {
                  const { status, key, message } = item;
                  return (
                    <View key={key} style={styles.wrapperItemVerify}>
                      <View>{!!validations?.buyer_address_line && ICON_STATUS[status]}</View>
                      <Text style={[styles.subTextItemStyle, { marginLeft: 5 }]}>{message}</Text>
                    </View>
                  );
                })}
              </View>
            </View>
          )}

          {invoiceItems?.length > 0 && (
            <View style={styles.item}>
              <View style={styles.wrapperItemContent}>
                <AppText style={[Fonts.BodySmall, styles.headerTitleItemStyle]} color={Colors.grayscale60}>
                  {t("title_list_item")}
                </AppText>
                {invoiceItems?.map((item, index) => {
                  return (
                    <View key={item.invoiceItemId} style={styles.row}>
                      <View style={styles.innerItemInvoiceStyle}>
                        <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                          {index + 1}. {item.itemName}
                        </AppText>
                      </View>

                      <View>
                        <AppText style={Fonts.NumericN100} color={Colors.grayscale100}>
                          {formatMoney(item.totalPriceBeforeVat)}
                        </AppText>
                      </View>
                    </View>
                  );
                })}

                <Line containerStyle={{ marginVertical: 16 }} />

                <View style={styles.row}>
                  <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                    {t("total_amount_without_vat")}:{" "}
                  </AppText>

                  <AppText style={Fonts.NumericN100} color={Colors.grayscale100}>
                    {formatMoney(totalAmountWithoutVat)}
                  </AppText>
                </View>

                <View style={styles.row}>
                  <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                    {t("discount_amount")}:{" "}
                  </AppText>
                  <AppText style={Fonts.NumericN100} color={Colors.grayscale100}>
                    {formatMoney(discountAmount)}
                  </AppText>
                </View>

                <View style={styles.row}>
                  <AppText style={[Fonts.BodySmall, styles.subTextItemStyle]} color={Colors.grayscale80}>
                    {t("total_vat_amount")}:{" "}
                  </AppText>
                  <AppText style={Fonts.NumericN100} color={Colors.grayscale100}>
                    {formatMoney(totalVatAmount)}
                  </AppText>
                </View>

                <View style={styles.row}>
                  <AppText style={Fonts.NumericN300} color={Colors.grayscale60}>
                    {t("total_amount_with_vat")}:{" "}
                  </AppText>
                  <AppText style={Fonts.NumericN300} color={Colors.grayscale100}>
                    {formatMoney(totalAmountWithVat)}
                  </AppText>
                </View>
              </View>
            </View>
          )}
        </View>
      </ScrollView>

      {!loading && !expense?.expenseId && !forceHideButton && (
        <FloatFooter onLayout={onLayoutFooter}>
          {(FEATURE_TRANSFER_EXPENSE_INVOICE || FEATURE_DELETE_EXPENSE_INVOICE) && (
            <Fragment>
              <TouchableOpacity onPress={() => setHasMore(!hasMore)} style={styles.moreButton} color={Colors.white}>
                <Flex justify="center">
                  {hasMore ? (
                    <>
                      <AppText style={Fonts.BodyLarge} color={Colors.grayscale100}>
                        {t("hide")}
                      </AppText>
                      <ArrowUpFilled />
                    </>
                  ) : (
                    <>
                      <AppText style={Fonts.BodyLarge} color={Colors.grayscale100}>
                        {t("more_options")}
                      </AppText>
                      <ArrowDownFilled />
                    </>
                  )}
                </Flex>
              </TouchableOpacity>
              {hasMore && <Divider />}
              {hasMore && (
                <Fragment>
                  {FEATURE_TRANSFER_EXPENSE_INVOICE && (
                    <Button
                      eventName={EVENT.INVOICE.TAP_TRANSFER_INVOICE}
                      style={[styles.mVertical12, styles.transferButton]}
                      onPress={() => {
                        setHasMore(false);
                        modalTransferInvoiceEmployeeRef?.current?.present();
                      }}
                      color={Colors.grayscale40}
                    >
                      <AppText style={Fonts.BodyLarge} color={Colors.grayscale100}>
                        {t("transfer_invoice")}
                      </AppText>
                    </Button>
                  )}
                  {FEATURE_DELETE_EXPENSE_INVOICE && (
                    <Button
                      eventName={EVENT.INVOICE.TAP_DELETE_INVOICE}
                      onPress={handleDeleteInvoice}
                      style={styles.deleteButton}
                    >
                      <AppText style={Fonts.BodyLarge} color={Colors.white}>
                        {t("delete")}
                      </AppText>
                    </Button>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
          <Button
            eventName={EVENT.EXPENSE.TAP_CREATE}
            onPress={onPress}
            disabled={disabledButtonCreateExpense}
            style={styles.mt12}
            color={Colors.primary50}
          >
            {t("create_expense")}
          </Button>
        </FloatFooter>
      )}
      {!loading && !!expense?.expenseId && !forceHideButton && invoiceIo !== 0 && (
        <FloatFooter onLayout={onLayoutFooter}>
          <Button
            onPress={() => {
              navigation.push(SCREEN_NAME.ExpenseDetailScreen, {
                expenseId: expense?.expenseId,
              });
            }}
            style={styles.mt12}
          >
            {t("view_expense")}
          </Button>
        </FloatFooter>
      )}
      <ModalTransferInvoiceEmployee
        ref={modalTransferInvoiceEmployeeRef}
        setSelectedEmployee={setSelectedItem}
        selectedEmployee={selectedItem}
        onTransfer={onTransfer}
      />
      <AlertNotification ref={alertNotificationRef} />
    </View>
  );
};

export default InvoiceDetailScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.grayscale05,
  },
  flex: {
    flex: 1,
  },
  marginLeft: {
    marginLeft: "auto",
    marginTop: 1,
  },
  wrapperItemVerify: { flexDirection: "row", flex: 1, alignItems: "flex-start" },
  content: {},
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  item: {
    marginBottom: 8,
  },
  headerTitleItemStyle: {
    paddingBottom: 10,
  },
  headerTextItemStyle: {
    marginBottom: 8,
  },
  subTextItemStyle: {
    marginBottom: 8,
  },
  wrapperItemContent: {
    paddingVertical: 10,
    paddingHorizontal: SPACING_DEFAULT,
    backgroundColor: Colors.white,
  },
  innerItemInvoiceStyle: {
    flex: 1,
    marginRight: 20,
  },
  mt12: {
    marginTop: 12,
  },
  mVertical12: {
    marginVertical: 12,
  },
  moreButton: {
    borderColor: Colors.white,
    backgroundColor: Colors.white,
    alignItems: "center",
    paddingBottom: 14,
  },
  transferButton: {
    borderColor: Colors.grayscale40,
    backgroundColor: Colors.white,
  },
  deleteButton: {
    borderColor: Colors.white,
    backgroundColor: Colors.alert50,
  },
  errorWrapper: {
    flexDirection: "column",
    paddingVertical: 8,
    paddingHorizontal: 16,
    backgroundColor: Colors.white,
    marginBottom: 8,
  },
  errorRow: {
    marginVertical: 2,
    color: Colors.alert50,
    flexDirection: "row",
    gap: 6,
  },
  errorInvoiceTitle: { marginBottom: 6 },
  statusIcon: { marginTop: 2 },
  loadingView: {
    backgroundColor: Colors.grayscale05,
  },
  errorContainer: { backgroundColor: Colors.white, flex: 1 },
});
