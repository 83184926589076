import { useEffect, useRef } from "react";
import { Platform, StyleSheet, View } from "react-native";
import LottieView from "lottie-react-native";

const sourceWhite = require("assets/lottie/loading-spin-white.json");
const sourceGrey = require("assets/lottie/loading-spin-grey.json");

const Loading = ({ size = 200, isWhite = true }) => {
  const source = isWhite ? sourceWhite : sourceGrey;
  const lottieViewRef = useRef<LottieView>(null);
  useEffect(() => {
    if (lottieViewRef.current) {
      lottieViewRef.current?.play();
    }
    return () => {
      lottieViewRef.current?.reset();
    };
  }, []);

  return (
    <View style={styles.animationContainer}>
      {Platform.OS !== "web" ? (
        <LottieView
          ref={lottieViewRef}
          style={{
            width: size,
            height: size,
          }}
          source={source}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  animationContainer: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
});

export default Loading;
