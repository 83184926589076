import { isIphoneX } from "constants/Layout";
import { Colors } from "theme";
import { Mode } from "react-hook-form";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  shadowDefault: {
    shadowColor: Colors.grayscale100,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 4,
  },
});
const CONSTANTS = {
  COMMON: {
    PAGE_SIZE: 15,
    CONTAINER_PADDING: 20,
    BOTTOM_TAB_HEIGHT: isIphoneX() ? 82 : 58,
    BOTTOM_SHEET_MAX_HEIGHT: "100%",
    MODAL_MAX_WIDTH: 500,
    PLACEHOLDER_COLOR: Colors.grayscale60,
    TEXT_INPUT_COLOR: Colors.grayscale80,
    VALIDATE_RULE: "all" as Mode,
    QUALITY_RESIZE_IMAGE: 0.5,
    UPLOADABLE_TYPES: ["allFiles"],
    MAXIMUM_BATCH_APPROVAL: 20,
    SHADOW_DEFAULT: styles.shadowDefault,
  },
  HOME: {
    HEADER_WELCOME_HEIGHT: 228,
  },
  WALK_THROUGH: {
    AddTitleKey: "@TourGuideStepAddTitle@", // join title (optional) and description by this key
    SINGLE_STEP: "SINGLE_STEP",
    DELAY_TIME: 500,
    FIRST_LAUNCH: {
      NAME: "FirstLaunch",
      Step1: 1,
    },
    OVERVIEW: {
      NAME: "OverviewOnboarding",
      Step1: 0,
      Step2: 1,
      Step3: 2,
      Step4: 3,
      Step5: 4,
      Step6: 5,
      Step7: 6,
      Step8: 7,
      Step9: 8,
    },
    CREATE_EXPENSE: {
      NAME: "CreateExpense",
      Step1: 0,
      Step2: 1,
      Step3: 2,
      Step4: 3,
      Step5: 4,
      Step6: 5,
      Step7: 6,
      Step8: 7,
      Step9: 8,
    },
    CREATE_REQUEST: {
      NAME: "CreateTravelRequest",
      Step1: 0,
      Step2: 1,
      Step3: 2,
      Step4: 3,
      Step5: 4,
      Step6: 5,
      Step7: 6,
      Step8: 7,
      Step9: 8,
    },
    CREATE_REPORT: {
      NAME: "CreateExpenseReport",
      Step1: 0,
      Step2: 1,
      Step3: 2,
      Step4: 3,
    },
    EMAIL_RECEIVE_INVOICE: {
      NAME: "EmailReceiveInvoice",
      TotalStep: 6,
      Step1: 0,
      Step2: 1,
      Step3: 2,
      Step4: 3,
      Step5: 4,
      Step6: 5,
      Step7: 6,
    },
    CATEGORY_POLICY: {
      NAME: "CategoryPolicy",
      Step1: 0,
      Step2: 1,
      Step3: 2,
      Step4: 3,
    },
    TRAVEL_POLICY: {
      NAME: "TravelPolicy",
      Step1: 0,
      Step2: 1,
      Step3: 2,
      Step4: 3,
    },
    CREATE_EXPENSE_REQUEST: {
      NAME: "CreateExpenseRequest",
      Step1: 0,
      Step2: 1,
      Step3: 2,
      Step4: 3,
    },
  },
  EXPENSE_LIST: {
    HEADER_STATUS_HEIGHT: 54,
    SORTING_CONTAINER_HEIGHT: 25,
  },
  FORMAT_DAY: "DD/MM/YYYY",
  FORMAT_DATE_TIME: "DD/MM/YYYY HH:mm:ss",
};

enum EXPENSE_STATUS {
  AWAITING_INVOICE = 1,
  NEED_DETAIL = 2,
  READY = 3,
  REQUESTED_PAYMENT = 4,
}
enum EXPENSE_REPORT_STATUS {
  DRAFT = 0,
  SUBMITTED = 1,
  AWAITING_MANAGER_APPROVAL = 1,
  REJECTED = 2,
  AWAITING_ACCOUNTANT_APPROVAL = 3,
  APPROVED = 4,
  CANCEL = 5,
}

enum APPROVAL_TYPE {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
}

enum APPROVAL_STATUS {
  AWAITING = 0,
  APPROVED = 1,
  REJECTED = 2,
  CANCELLED = 3,
  PENDING = 4,
}

enum APPROVAL_EMPLOYEE_STATUS {
  AWAITING = 0,
  APPROVED = 1,
  REJECTED = 2,
  CANCELLED = 3,
  SKIPPED = 4,
}

enum INVOICE_TYPE {
  E_INVOICE = 1, //Hóa đơn điện tử
  PAPER_INVOICE = 2, //Hóa đơn giấy
  NO_INVOICE = 3, //Không có hóa đơn
}
enum REQUEST_TYPE {
  travel = "travel",
  other = "other",
  purchase = "purchase",
  "price_comparison" = "price_comparison",
}

enum REQUEST_STATUS {
  DRAFT = "draft",
  READY = "ready",
  WAITING = "waiting",
  SUBMITTED = "submitted",
  REJECTED = "rejected",
  APPROVED = "approved",
  STORED = "stored",
  CANCELLED = "cancelled",
}

enum CARD_STATUS {
  ACTIVATED = "activated",
  BLOCKED = "blocked",
  CLOSED = "closed",
  EXPIRED = "expired",
  INACTIVE = "inactive",
}

enum TRANSACTION_CARD_STATUS {
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  PENDING = "pending",
}

enum EXPENSE_ATTACHMENT_TYPE {
  INVOICE = 1,
  DOCUMENT = 2,
}

enum ACCOUNT_TYPES {
  ENTERPRISE = "company",
  PERSONAL = "personal",
  NONE = "none",
}
enum DEEP_LINK_TYPE {
  REQUEST = "expense-requests",
  REPORT = "expense-reports",
}

enum INVOICE {
  INVOICE_32 = "1",
  INVOICE_78 = "2",
  PURCHASE_LIST = "4",
}
enum CARD_REGISTRATION_STATUS {
  UNREGISTERED = "UNREGISTERED",
  REGISTERED = "REGISTERED",
  BIZZI_REJECTED = "BIZZI_REJECTED",
  BANK_REJECTED = "BANK_REJECTED",
  BIZZI_VERIFIED = "BIZZI_VERIFIED",
  BANK_VERIFIED = "BANK_VERIFIED",
  CARD_ISSUED = "CARD_ISSUED",
}

enum KIND_OF_APPROVAL {
  AWAITING_MY_APPROVAL = "AWAITING_MY_APPROVAL",
  INCOMING_MY_APPROVAL = "INCOMING_MY_APPROVAL",
  PARTICIPATED = "PARTICIPATED",
}

enum RESTRICTION_BUDGET_TYPE {
  ACCEPT = "accept",
  WARNING = "warning",
  RESTRICT = "restrict",
}

enum ALLOCATED_STATUS {
  ALLOCATED = "ALLOCATED", // đã phân bổ
  IS_ALLOCATED = "IS_ALLOCATED", // được phân bổ từ..
  UNALLOCATED = "UNALLOCATED", // chưa phân bổ
}

enum ALLOCATION_LEVEL {
  ORIGINAL = "lv0",
  LEVEL1 = "lv1",
  LEVEL2 = "lv2",
}

const ALLOCATED_STATUS_ALLOCATION_LEVEL_CONSTRAINTS = {
  [ALLOCATED_STATUS.UNALLOCATED]: ALLOCATION_LEVEL.ORIGINAL,
  [ALLOCATED_STATUS.ALLOCATED]: ALLOCATION_LEVEL.LEVEL1,
  [ALLOCATED_STATUS.IS_ALLOCATED]: ALLOCATION_LEVEL.LEVEL2,
};

enum PAYMENT_METHOD {
  CASH = "cash",
  BANK_TRANSFER = "bank_transfer",
}
enum REPORT_STATUS_FILTER_REQUEST {
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
  NOT_YET = "NOT_YET",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
}
enum CHAT_TYPE {
  REPORT = "RP",
  REQUEST = "ER",
}
enum NTF_PLATFORM {
  ANDROID = "ANDROID",
  IOS = "IOS",
  WEB = "WEB",
}

enum NTF_TOKEN_PROVIDER {
  EXPO = "EXPO",
  APNS = "APNS",
  FCM = "FCM",
  ONESIGNAL = "ONESIGNAL",
}

enum BOOKING_TYPE {
  FLIGHT = "flight",
  HOTEL = "hotel",
  TRANSPORTATION = "transportation",
}

enum BOOKING_SETTING_TYPE {
  FLIGHT = "transportation.flight",
  HOTEL = "accommodation.hotel",
  TRANSPORTATION = "transportation.other",
}

enum ALLOWANCE_TYPE {
  PERDIEM = "perdiem",
  USER_REQUEST = "user_request",
  BOOKING = "booking",
  BOOKING_FLIGHT = "booking.transportation.flight",
  BOOKING_HOTEL = "booking.hotel",
  BOOKING_BUS = "booking.bus",
}

enum EXPENSE_POLICY_SETTING_KEYS {
  TRAVEL_LIMITATION = "travel.limitation",
  BOOKING_ACCOMMODATION_HOTEL = "booking.accommodation.hotel",
  BOOKING_TRANSPORTATION_FLIGHT = "booking.transportation.flight",
  BOOKING_TRANSPORTATION_OTHER = "booking.transportation.other",
}

enum EXPENSE_ALLOWANCE_TYPE {
  USER_REQUEST = "user_request",
  PER_DIEM = "perdiem",
  BOOKING = "booking",
}

enum EXP_CONVO_TYPE {
  REQUEST = "ER",
  REPORT = "RP",
}

enum EXPENSE_CLAIMED_STATUS {
  CLAIMED = "CLAIMED",
  UNCLAIMED = "UNCLAIMED",
}

enum TRACKING_OBJECT_TYPE {
  REQUEST = "request",
  EXPENSE = "expense",
  REPORT = "report",
}
enum TRACKING_ACTION_TYPE {
  APPROVED = "approved",
  REJECTED = "rejected",
}
enum BOOKING_FLIGHT_STATUS {
  ISSUED = "issued",
  HOLD = "hold",
  EXPIRED = "expired",
}
enum APPROVAL_METHOD {
  MANUAL = "MANUAL",
  AUTO = "AUTO_APPROVE",
}
enum APPROVAL_OBJECT_SORT_OPTIONS {
  AMOUNT_ASC = "amount_asc",
  AMOUNT_DESC = "amount_desc",
  CREATED_DATE_DESC = "created_date_desc",
  CREATED_DATE_ASC = "created_date_asc",
  SUBMISSION_DATE_ASC = "submission_date_asc",
  SUBMISSION_DATE_DESC = "submission_date_desc",
}
enum SETTING_TYPES {
  EXPENSE_FORM = "expense:expense_form",
  BATCH_APPROVAL = "expense:batch_approval",
  FOREIGN_CURRENCY = "general:foreign_currency",
}

enum COMPANY_ATTACHMENT_OBJECT_TYPES {
  EXPENSE = "expense",
  EXPENSE_REPORT = "expense_report",
  EXPENSE_REQUEST = "expense_request",
}

enum COMPANY_ATTACHMENT_TYPES {
  SUPPORT_DOCUMENT = "supporting_document",
  ALLOCATED_DOCUMENT = "allocated_document",
}

export {
  CONSTANTS,
  EXPENSE_STATUS,
  EXPENSE_REPORT_STATUS,
  APPROVAL_TYPE,
  APPROVAL_STATUS,
  APPROVAL_EMPLOYEE_STATUS,
  INVOICE_TYPE,
  REQUEST_TYPE,
  REQUEST_STATUS,
  CARD_STATUS,
  TRANSACTION_CARD_STATUS,
  EXPENSE_ATTACHMENT_TYPE,
  ACCOUNT_TYPES,
  DEEP_LINK_TYPE,
  INVOICE,
  CARD_REGISTRATION_STATUS,
  KIND_OF_APPROVAL,
  RESTRICTION_BUDGET_TYPE,
  ALLOCATED_STATUS,
  PAYMENT_METHOD,
  REPORT_STATUS_FILTER_REQUEST,
  CHAT_TYPE,
  NTF_PLATFORM,
  NTF_TOKEN_PROVIDER,
  BOOKING_TYPE,
  BOOKING_SETTING_TYPE,
  ALLOWANCE_TYPE,
  EXPENSE_POLICY_SETTING_KEYS,
  EXPENSE_ALLOWANCE_TYPE,
  EXP_CONVO_TYPE,
  ALLOCATION_LEVEL,
  ALLOCATED_STATUS_ALLOCATION_LEVEL_CONSTRAINTS,
  EXPENSE_CLAIMED_STATUS,
  TRACKING_OBJECT_TYPE,
  BOOKING_FLIGHT_STATUS,
  APPROVAL_METHOD,
  APPROVAL_OBJECT_SORT_OPTIONS,
  SETTING_TYPES,
  TRACKING_ACTION_TYPE,
  COMPANY_ATTACHMENT_OBJECT_TYPES,
  COMPANY_ATTACHMENT_TYPES,
};
